import React, { useState, createContext } from "react";

const GroupNotificationContext = createContext();

const GroupNotificationContextProvider = ({ children }) => {
    const [isGroupGettingMessages, setIsGroupGettingMessages] = useState(false);
    const [isPrivateGettingMessages, setIsPrivateGettingMessages] = useState(false);

    return (
        <GroupNotificationContext.Provider
            value={{ isGroupGettingMessages, setIsGroupGettingMessages, isPrivateGettingMessages, setIsPrivateGettingMessages }}
        >
            {children}
        </GroupNotificationContext.Provider>
    );
};

export { GroupNotificationContext, GroupNotificationContextProvider };
