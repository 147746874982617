import React from "react";
import ToolTip from "../ToolTips";
import { useTheme, useMediaQuery } from "@material-ui/core";

const hexToRgb = (hex) => {
  let r = 0, g = 0, b = 0;
  
  if (hex[0] === '#') {
    hex = hex.slice(1);
  }

  if (hex.length === 6) {
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  }
  return `rgb(${r}, ${g}, ${b})`;
};

const HeatmapComponent = ({ groupsFound }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const data = groupsFound?.heatmapMessageReport || [];

  const daysOfWeek = ["D", "S", "T", "Q", "Q", "S", "S"];
  const englishDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const hours = Array.from({ length: 24 }, (_, i) => i);
  const filteredHours = isMobile ? hours.filter((h) => h % 3 === 0) : hours;

  const heatmapMatrix = daysOfWeek.map((day, dayIndex) => {
    const englishDay = englishDays[dayIndex];
    return hours.map((hour) => {
      const entry = data.find((d) => d.dayOfWeek === englishDay && d.hour === hour);
      return entry ? entry.messageCount : 0;
    });
  });

  const maxCount = Math.max(...data.map((d) => d.messageCount), 0);

  const getColor = (count) => {
    if (count === 0) return "#eeeeee";
    const intensity = Math.ceil((count / maxCount) * 255);
    const opacity = (intensity / 255).toFixed(2);
    const baseColor = hexToRgb(theme.palette.primary.main.slice(1));
    return `${baseColor.replace('rgb', 'rgba').replace(')', `, ${opacity})`)}`;
  };

  return (
    <div style={{ width: "100%", height: "100%", background: theme.palette.primary.mainGradient, boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", borderRadius: "8px", padding: "1rem", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
      <h3 style={{
        fontFamily: "Poppins",
        fontSize: "1.17em",
        fontWeight: "400",
        marginBottom: "1rem",
        color: theme.palette.blackOrWhite
      }}>Heatmap de Mensagens</h3>
      <div
        style={{
          display: "grid",
          gridTemplateRows: `repeat(7, 20px) 30px`,
          gridTemplateColumns: `16px repeat(${filteredHours.length}, 1fr)`,
          gap: "2px",
          alignItems: "center",
          width: "100%",
        }}
      >
        {daysOfWeek.map((day, dayIndex) => (
          <React.Fragment key={day}>
            <div
              style={{
                textAlign: "right",
                paddingRight: "10px",
                fontWeight: "400",
                fontSize: "12px",
                color: theme.palette.blackOrWhite,
                fontFamily: "Poppins",
                whiteSpace: "nowrap",
              }}
            >
              {day}
            </div>
            {heatmapMatrix[dayIndex].filter((_, i) => filteredHours.includes(i)).map((count, hourIndex) => (
              <ToolTip title={count} key={`${day}-${hourIndex}`}>
                <div
                  style={{
                    width: "100%",
                    height: "18px",
                    backgroundColor: getColor(count),
                    textAlign: "center",
                    lineHeight: "18px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    cursor: "pointer",
                    color: theme.palette.primary.mainReverseByWhite,
                    transition: "background-color 0.3s ease",
                  }}
                  title={count > 0 ? `${count} mensagens` : ""}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#ffffff";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = getColor(count);
                  }}
                ></div>
              </ToolTip>
            ))}
          </React.Fragment>
        ))}

        <div style={{ gridColumn: "2 / -1", display: "grid", gridTemplateColumns: `repeat(${filteredHours.length}, 1fr)` }}>
          {filteredHours.map((hour) => (
            <div
              key={`hour-${hour}`}
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: theme.palette.primary.mainReverseByWhite,
                fontSize: "10px",
                fontFamily: "Poppins",
              }}
            >
              {hour}h
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HeatmapComponent;
