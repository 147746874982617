import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    borderTop: "none !important",
    borderRadius: "8px",
    background: theme.palette.primary.neutralContainerBackground,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  firstContainer: {
    border: `1px solid ${theme.palette.primary.neutralBorder} !important`,
    borderTop: "none !important",
    overflow: "hidden",
  },
  darkerIcons: {
    color: theme.palette.primary.third,
  },

  tabsHeader: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "8px 8px 0 0",
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.primary.neutralBorder}`,
    borderBottom: "none",
  },

  tabsInternal: {
    flex: "none",
    backgroundColor: theme.palette.tabHeaderBackground,
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },
  snackbar: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.darkGrayNeutralColor,
    color: "white",
    borderRadius: 4,
    left: "40%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1em",
    },
  },

  yesButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white" + " !important",
    padding: "4px 4px",
    fontSize: "1em",
    fontWeight: "400",
    textTransform: "capitalize !important",
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}70`,
      color: "#FFF",
    },
    borderRadius: 4,
  },
  noButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white" + " !important",
    padding: "4px 4px",
    fontSize: "1em",
    fontWeight: "400",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}70`,
      color: "#FFF",
    },
    borderRadius: 4,
  },
  tab: {
    fontSize: "12px",
    minWidth: "auto",
    padding: "0",
    fontFamily: "Poppins",
    color: theme.palette.primary.mainReverseByWhite,
  },

  groupTab: {
    fontSize: "12px",
    minWidth: "auto",
    padding: "0",
    fontWeight: "400",
    textTransform: "capitalize",
    fontFamily: "Poppins",
    background: theme.palette.primary.neutralIconBackground,
    border: `1px solid ${theme.palette.primary.neutralBorder}`,
    width: "calc(50% - 0.25rem) !important",
    color: "#9F9797",
    marginBottom: "0.5rem",
    borderRadius: "8px",
    minHeight: "40px",
  },

  groupTabActive: {
    fontSize: "12px",
    minWidth: "auto",
    padding: "0",
    fontWeight: "400",
    minHeight: "40px",
    textTransform: "capitalize",
    fontFamily: "Poppins",
    background: `${theme.palette.primary.main}60`,
    border: `1px solid ${theme.palette.primary.main}`,
    width: "calc(50% - 0.25rem) !important",
    color: `${theme.palette.primary.main} !important`,
    marginBottom: "0.5rem",
    borderRadius: "8px",
  },

  internalTab: {
    minWidth: 120,
    width: 120,
    padding: 5,
  },

  ticketOptionsBox: {
    display: "flex",
    padding: "1rem",
    background: "transparent",
    alignItems: "center",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderTop: "none",
    justifyContent: "space-between",
    borderBottom: "none",
  },

  ticketSearchLine: {
    padding: theme.spacing(1),
  },

  serachInputWrapper: {
    flex: 1,
    background: theme.palette.total,
    display: "flex",
    borderRadius: 40,
    margin: "1rem",
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    marginTop: "1rem",
    padding: "0 1rem",
    borderRadius: "4px",
    borderBottom: `1px solid ${theme.palette.primary.main}70`,
  },

  insiderTabPanel: {
    height: "100%",
    marginTop: "-72px",
    paddingTop: "72px",
  },

  insiderDoubleTabPanel: {
    display: "flex",
    flexDirection: "column",
    marginTop: "-72px",
    paddingTop: "72px",
    height: "100%",
  },

  labelContainer: {
    width: "auto",
    padding: 0,
  },
  iconLabelWrapper: {
    flexDirection: "row",
    "& > *:first-child": {
      marginBottom: "3px !important",
      marginRight: 16,
    },
  },
  insiderTabLabel: {
    [theme.breakpoints.down(1600)]: {
      display: "none",
    },
  },
  smallFormControl: {
    "& .MuiOutlinedInput-input": {
      padding: "12px 10px",
    },
    "& .MuiInputLabel-outlined": {
      marginTop: "-6px",
    },
  },
  statusStyles: {
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "capitalize",
    color: theme.palette.primary.neutralTextColor,
    marginRight: "0.3rem",
  },
  filterModalStyles: {
    position: "absolute",
    top: 0,
    left: 0,
    maxWidth: "427px",
    width: "100%",
    height: "464px",
    backgroundColor: "#FFFFFF",
    border: `1px solid ${theme.palette.primary.neutralBorder}`,
    borderRadius: "8px",
  },
  maxFilter: {
    maxHeight: "200px",
    overflow: "auto",
    ...theme.scrollbarStyles,
  },
  neutralBackground: {
    background: theme.palette.primary.neutralColor,
    border: `1px solid ${theme.palette.primary.neutralBorder}`,
  },
}));

export default useStyles;
