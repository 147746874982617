import React, { createContext, useEffect, useState } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

const EmailsListContext = createContext();

const EmailsListContextProvider = ({ children }) => {
    const [emailLists, setEmailLists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [newListName, setNewListName] = useState("");
    const [activeTab, setActiveTab] = useState(null);
    const [emailListDetails, setEmailListDetails] = useState([]);
    const [updatedListName, setUpdatedListName] = useState("");
    const [file, setFile] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    
    const fetchEmailLists = async () => {
        try {
            const response = await api.get("/email-lists");
            setEmailLists(response.data);
            setUpdatedListName(response.data.name);

        } catch (error) {
            console.error("Erro ao buscar listas de e-mails:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchEmailListDetails = async (id, page = 1, searchParam = "") => {
        try {
            const response = await api.get(`/list-emails/${id}`, {
                params: { page, searchParam }
            });
            setEmailListDetails(response.data.emails);
            setTotalPages(response.data.totalPages);
            setCurrentPage(page);
        } catch (error) {
            console.error("Erro ao buscar detalhes da lista:", error);
        }
    };


    const updateEmailList = async (emailListDetailsId) => {
        if (!updatedListName.trim()) return;

        try {
            const response = await api.put(`/email-lists/${emailListDetailsId}`, { name: updatedListName });
            setEmailLists(emailLists.map((list) => (list.id === emailListDetailsId ? response.data : list)));
            toast.success("Lista de e-mails atualizada com sucesso!");
        } catch (error) {
            console.error("Erro ao atualizar a lista:", error);
            toastError("Erro ao atualizar a lista.");
        }
    };

    const handleDeleteConfirmation = (id) => {
        setDeleteId(id);
        setOpenDeleteModal(true);
    };

    const deleteEmailList = async () => {
        if (!deleteId) return;

        try {
            await api.delete(`/email-lists/${deleteId}`);
            setEmailLists(emailLists.filter((list) => list.id !== deleteId));
        } catch (error) {
            console.error("Erro ao deletar a lista:", error);
        } finally {
            setOpenDeleteModal(false);
            setDeleteId(null);
        }
    };

    const handleCreateList = async () => {
        if (!newListName.trim()) return;

        try {
            const response = await api.post("/email-lists", { name: newListName });
            setEmailLists([...emailLists, response.data]);
        } catch (error) {
            console.error("Erro ao criar a lista:", error);
        } finally {
            setOpenCreateModal(false);
            setNewListName("");
        }
    };

    const handleTabClick = async (id, page, searchParam) => {
        setActiveTab(id);
        try {
            const response = await api.get(`/list-emails/${id}`, {
                params: { page, searchParam }
            });
            setEmailListDetails(response.data.emails);
            setTotalPages(response.data.totalPages);
            setUpdatedListName(response.data.name);
        } catch (error) {
            console.error("Erro ao buscar detalhes da lista:", error);
        }
    };

    const handleUpload = async (id, file) => {
        if (!file) {
            toastError("Por favor, selecione um arquivo Excel.");
            return;
        }
        
        const formData = new FormData();
        formData.append("file", file);

        try {
            await api.post(`/email-lists/${id}/upload`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            toast.success("Upload realizado com sucesso!");
        } catch (error) {
            console.error("Erro ao fazer upload do arquivo:", error);
            toastError("Erro ao fazer upload do arquivo.");
        }
    };

    return (
        <EmailsListContext.Provider
            value={{
                emailLists,
                setEmailLists,
                loading,
                setLoading,
                openDeleteModal,
                setOpenDeleteModal,
                deleteId,
                setDeleteId,
                openCreateModal,
                setOpenCreateModal,
                newListName,
                setNewListName,
                activeTab,
                setActiveTab,
                emailListDetails,
                setEmailListDetails,
                updatedListName,
                setUpdatedListName,
                file,
                setFile,
                fetchEmailLists,
                fetchEmailListDetails,
                updateEmailList,
                handleDeleteConfirmation,
                deleteEmailList,
                handleCreateList,
                handleTabClick,
                handleUpload,
                currentPage,
                setCurrentPage,
                totalPages,
                setTotalPages
            }}
        >
            {children}
        </EmailsListContext.Provider>
    );
};

export { EmailsListContext, EmailsListContextProvider };
