import { isArray } from "lodash";

const reducer = (state, action) => {
  if (action.type === "LOAD_CHATS") {
    const chats = action.payload
    const newChats = []

    if (isArray(chats)) {
      chats.forEach((chat) => {
        const chatIndex = state.findIndex((u) => u.id === chat.id)
        if (chatIndex !== -1) {
          state[chatIndex] = chat
        } else {
          newChats.push(chat)
        }
      })
    }

    return [...state, ...newChats]
  }

  if (action.type === "UPDATE_CHATS") {
    const chat = action.payload
    const chatIndex = state.findIndex((u) => u.id === chat.id)

    if (chatIndex !== -1) {
      state[chatIndex] = chat
      return [...state]
    } else {
      return [chat, ...state]
    }
  }

  if (action.type === "DELETE_CHAT") {
    const chatId = action.payload

    const chatIndex = state.findIndex((u) => u.id === chatId)
    if (chatIndex !== -1) {
      state.splice(chatIndex, 1)
    }
    return [...state]
  }

  if (action.type === "RESET") {
    return []
  }

  if (action.type === "CHANGE_CHAT") {
    const changedChats = state.map((chat) => {
      if (chat.id === action.payload.chat.id) {
        return action.payload.chat
      }
      return chat
    })
    return changedChats
  }
}

export default reducer;