import React from "react";
import { Button, Tooltip, useTheme } from "@material-ui/core";
import baileyIcon from "../../assets/baileys-icon.png";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PublicIcon from "@material-ui/icons/Public";
import getSocialBackgroundColor from "../../utils/socialMediaBackground";

const SocialMediaFilter = ({ handleSocialButtonClick, contactType, style }) => {
    const theme = useTheme();
    const getButtonStyle = (type) => ({
        background: "transparent",
        color: contactType !== type ? `${theme.palette.primary.main}`: theme.palette.primary.main,
        minWidth: "25px",
        opacity: contactType !== type ? `50%`: "100%",
        width: "25px",
        minHeigth: "25px",
        height: "25px",
        padding: "0.5rem",
        fontFamily: "Poppins",
        boxShadow: "none"
    });

    return (
        <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "0.6rem", ...style }}>
            <Tooltip title="Público" placement="top">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSocialButtonClick(null)}
                    style={getButtonStyle(null)}
                >
                    <PublicIcon />
                </Button>
            </Tooltip>

            <Tooltip title="Facebook" placement="top">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSocialButtonClick("facebook")}
                    style={getButtonStyle("facebook")}
                >
                    <FacebookIcon />
                </Button>
            </Tooltip>

            <Tooltip title="Instagram" placement="top">
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleSocialButtonClick("instagram")}
                    style={getButtonStyle("instagram")}
                >
                    <InstagramIcon />
                </Button>
            </Tooltip>

            <Tooltip title="WhatsApp" placement="top">
                <Button
                    variant="contained"
                    color="default"
                    onClick={() => handleSocialButtonClick("official")}
                    style={getButtonStyle("official")}
                >
                    <WhatsAppIcon />
                </Button>
            </Tooltip>

            <Tooltip title="Baileys" placement="top">
                <Button
                    variant="contained"
                    onClick={() => handleSocialButtonClick("baileys")}
                    style={getButtonStyle("baileys")}
                >
                    <img style={{ width: "25px" }} src={baileyIcon} alt="WhatsApp Official" />
                </Button>
            </Tooltip>
        </div>
    );
};

export default SocialMediaFilter;
