import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Checkbox, Chip, ListItemText, useTheme } from "@material-ui/core";

const TicketsQueueSelect = ({
  userQueues,
  selectedQueueIds = [],
  onChange,
  display
}) => {
  const theme = useTheme();

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div style={{ width: "100%", marginTop: -4 }}>
      <FormControl style={{ display: display }} fullWidth>
        <Select
          multiple
          displayEmpty
          variant="outlined"
          value={selectedQueueIds}
          onChange={handleChange}
          style={{
            border: "none",
            padding: "0",
            minHeight: "40px",
            color: "#9F9797"
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
          renderValue={(selected) =>
            selected.length === 0 ? (
              "Adicionar"
            ) : (
              <div style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
                {userQueues
                  .filter((queue) => selected.includes(queue.id))
                  .map((queue) => (
                    <Chip
                      key={queue.id}
                      label={queue.name}
                      style={{
                        backgroundColor: `${queue.color}70` || "#eee",
                        textShadow: "none",
                        color: theme.mode === "light" ? queue.color : "white",
                        height: 24,
						borderRadius: "4px",
						border: "none"

                      }}
                      size="small"
                      onDelete={() =>
                        onChange(selected.filter((id) => id !== queue.id))
                      }
                    />
                  ))}
              </div>
            )
          }
        >
          {userQueues?.length > 0 &&
            userQueues.map((queue) => (
              <MenuItem dense key={queue.id} value={queue.id} style={{ padding: 0 }}>
                <Checkbox
                  style={{
                    color: queue.color
                  }}
                  size="small"
                  color="primary"
                  checked={selectedQueueIds.includes(queue.id)}
                />
                <ListItemText primary={queue.name} style={{ color: theme.palette.primary.mainReverseByWhite }} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default TicketsQueueSelect;
