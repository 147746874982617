import React, { useEffect, useState } from "react";
import { Modal, Button, TextField, Switch, FormControlLabel, useTheme } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { toast } from "react-toastify";
import handleChange from "./EmailSettingsComponents/utils/handleChange";
import api from "../../services/api";
import useStyles from "./style";
import encryptPassword from "../../utils/encryptPassword";

const EmailSettingsModal = ({ isOpen, onClose, isEdit = false, emailId, setEmailSettings }) => {
    const classes = useStyles();
    const theme = useTheme();

    const [formData, setFormData] = useState({
        userIds: [],
        smtpHost: "smtp.gmail.com",
        smtpPort: 465,
        smtpUser: "",
        smtpPass: "",
        pop3User: "",
        pop3Pass: "",
        pop3Host: "imap.gmail.com",
        pop3Port: 993,
        useTls: true,
        pop3Enabled: false,
        imapEnabled: true,
    });
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        api.get("/users", { params: { limit: 300 } })
            .then(response => {
                setUsers(response?.data?.users || []);
            })
            .catch(error => {
                console.error("Erro ao obter usuários:", error);
                toast.error("Erro ao carregar usuários.");
            });
    }, []);

    useEffect(() => {
        if (isEdit && emailId) {
            api.get(`/email-settings/${emailId}`)
                .then(response => {
                    setFormData({
                        ...response.data,
                        smtpPass: "",
                        pop3Pass: ""
                    });
                })
                .catch(error => {
                    console.error("Erro ao buscar os dados do e-mail:", error);
                    toast.error("Erro ao carregar as configurações do e-mail.");
                });
        }
    }, [isEdit, emailId]);

    const handleUserSelectChange = (event, newValue) => {
        setFormData({ ...formData, userIds: newValue.map(user => user.id) });
    };

    const selectedUsers = users.filter(user => formData.userIds.includes(user.id));

    const handleSubmitForm = e => {
        e.preventDefault();
        setLoading(true);
        if (isEdit && emailId) {
            api.put(`/email-settings/${emailId}`, { ...formData, pop3User: formData.smtpUser, pop3Pass: encryptPassword(formData?.smtpPass), smtpPass: encryptPassword(formData?.smtpPass) })
                .then(({ data }) => {
                    toast.success("Configurações de e-mail atualizadas com sucesso!");
                    onClose();
                    setEmailSettings(prevState => prevState.map(item => item.id === formData.id ? { ...data, ...formData, pop3User: formData.smtpUser, pop3Pass: encryptPassword(formData?.smtpPass), smtpPass: encryptPassword(formData?.smtpPass) } : item));
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Erro ao atualizar configurações:", error);
                    setLoading(false);
                    toast.error("Erro ao atualizar configurações.");
                });
        } else {
            api.post("/email-settings/", { ...formData, pop3User: formData.smtpUser, pop3Pass: encryptPassword(formData?.smtpPass), smtpPass: encryptPassword(formData?.smtpPass) })
                .then(({ data }) => {
                    toast.success("Configurações de e-mail salvas com sucesso!");
                    setLoading(false);
                    setEmailSettings(prevState => [...prevState, { ...data, ...formData, pop3User: formData.smtpUser, pop3Pass: encryptPassword(formData?.smtpPass), smtpPass: encryptPassword(formData?.smtpPass) }]);
                    onClose();
                })
                .catch(error => {
                    console.error("Erro ao salvar configurações:", error);
                    setLoading(false);
                    toast.error("Erro ao salvar configurações.");
                });
        }
    };

    return (
        <Modal open={isOpen} onClose={() => { if (loading) return; onClose(); }} aria-labelledby="email-settings-modal">
            <div className={classes.modal}>
                <div style={{ display: "flex", gap: "0.5rem", alignItems: "center", marginBottom: "1rem" }}>
                    <img src="https://cdn-icons-png.freepik.com/512/2250/2250206.png" alt="E-mail" style={{ width: "30px", height: "30px" }} />
                    <h2 className={classes.title}>
                        {isEdit ? "Editar Canal" : "Cadastrar Canal"}
                    </h2>
                </div>
                <form className={classes.form} onSubmit={handleSubmitForm}>
                    <TextField
                        name="smtpHost"
                        label="SMTP Host (envio de e-mails)"
                        value={formData.smtpHost}
                        onChange={e => handleChange(e, setFormData, formData)}
                        required
                        fullWidth
                    />
                    <TextField
                        name="smtpPort"
                        label="SMTP Port"
                        type="number"
                        value={formData.smtpPort}
                        onChange={e => handleChange(e, setFormData, formData)}
                        required
                        fullWidth
                    />
                    <TextField
                        name="smtpUser"
                        label="E-mail"
                        value={formData.smtpUser}
                        onChange={e => handleChange(e, setFormData, formData)}
                        required
                        fullWidth
                    />
                    <TextField
                        name="smtpPass"
                        label="Senha"
                        type="password"
                        value={formData.smtpPass}
                        onChange={e => handleChange(e, setFormData, formData)}
                        required
                        fullWidth
                    />
                    <TextField
                        name="pop3Host"
                        label="POP3/IMAP Host (recebimento de e-mails)"
                        value={formData.pop3Host}
                        onChange={e => handleChange(e, setFormData, formData)}
                        required
                        fullWidth
                    />
                    <TextField
                        name="pop3Port"
                        label="POP3/IMAP Port"
                        type="number"
                        value={formData.pop3Port}
                        onChange={e => handleChange(e, setFormData, formData)}
                        required
                        fullWidth
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                name="useTls"
                                checked={formData.useTls}
                                onChange={e => handleChange(e, setFormData, formData)}
                            />
                        }
                        label="Usar TLS"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                name="pop3Enabled"
                                checked={formData.pop3Enabled}
                                onChange={e => handleChange(e, setFormData, formData)}
                            />
                        }
                        label="POP3"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                name="imapEnabled"
                                checked={formData.imapEnabled}
                                onChange={e => handleChange(e, setFormData, formData)}
                            />
                        }
                        label="IMAP"
                    />

                    <Autocomplete
                        multiple
                        id="user-autocomplete"
                        options={users}
                        getOptionLabel={(option) => option.name}
                        value={selectedUsers}
                        onChange={handleUserSelectChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Usuários com Acesso ao E-mail"
                                placeholder="Digite para buscar"
                            />
                        )}
                    />

                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px", marginTop: "1rem" }}>
                        <Button
                            type="submit"
                            disabled={loading}
                            style={{
                                color: theme.palette.primary.mainReverseByWhite,
                                border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
                                borderRadius: "8px"
                            }}
                        >
                            {loading ? "Carregando" : (isEdit ? "Atualizar" : "Salvar")}
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default EmailSettingsModal;
