import logoConnect from "../logo-signed-flat.png";
import logoP from "../new-logo-cut.png";
import logo from "../logo.png";
import logoOrbsofty from "../orbgray.png";

const themeBigLogo = {
    "tarifando": logo,
    "purple": logoP,
    "connect": logoConnect,
    "orbsofty": logoOrbsofty
};

export default themeBigLogo