import React, { useContext, useEffect } from "react";
import { Button } from "@material-ui/core";
import { EmailsListContext } from "../../context/EmailsListContext";
import EmailsListsList from "../../components/EmailsListComponents/EmailsListsList/index";
import EmailsContactList from "../../components/EmailsListComponents/EmailsContactList";

export default function EmailListsPage() {
    const {
        emailLists,
        activeTab,
        fetchEmailLists,
    } = useContext(EmailsListContext);

    useEffect(() => {
        if(!emailLists.length) {
            fetchEmailLists();
        }
    }, [emailLists.length, fetchEmailLists]);

    return (
        <div style={{padding: "1rem"}}>
            {!activeTab && <EmailsListsList />}

            <EmailsContactList />
        </div>
    );
}
