import React, { useState, useContext, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";

import api from "../../services/api";
import WhatsAppModal from "../../components/WhatsAppModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import QrcodeModal from "../../components/QrcodeModal";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import toastError from "../../errors/toastError";
import { socketConnection } from "../../services/socket";

import "./style.css";

import { AuthContext } from "../../context/Auth/AuthContext";
import useStyles from "./styles";
import ConnectionsCards from "./ConnectionComponents/cards/ConnectionCards";
import ChannelsCards from "./ConnectionComponents/cards/ChannelsCards";
import {
	handleRequestNewQrCode,
	handleOpenFacebookAppModal,
	handleOpenInstagramAppModal,
	handleOpenWhatsAppModal,
	handleCloseWhatsAppModal,
	handleOpenQrModal,
	handleCloseQrModal,
	handleEditWhatsApp,
	handleOpenConfirmationModal,
	handleSubmitConfirmationModal,
	handleOpenWhatsAppModalOfficial,
	handleOpenFacebookModalOfficial, // Novo handler para Facebook Oficial
	handleOpenInstagramModalOfficial // Novo handler para Instagram Oficial
} from './handles';
import StatusImport from "./ConnectionComponents/cards/StatusImport";
import ConnectionGraphs from "./ConnectionComponents/graphs/ConnectionGraphs";

const Connections = () => {
	const classes = useStyles();
	const history = useHistory();

	const { user } = useContext(AuthContext);
	const { whatsApps: whatsAppsRaw, loading } = useContext(WhatsAppsContext);
	const [whatsApps, setUpdatedWhatsApps] = useState(whatsAppsRaw);
	const [statusImport, setStatusImport] = useState([]);
	const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
	const [qrModalOpen, setQrModalOpen] = useState(false);
	const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
	const [availableChannels, setAvailableChannels] = useState([]);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [modelType, setModalType] = useState("");
	const [emailSettings, setEmailSettings] = useState([]);

	const confirmationModalInitialState = {
		action: "",
		title: "",
		message: "",
		whatsAppId: "",
		open: false,
	};
	const [confirmModalInfo, setConfirmModalInfo] = useState(
		confirmationModalInitialState
	);

	const handleRefresh = useCallback(() => {
		history.go(0);
	  }, [history]);
	
	  useEffect(() => {
		const companyId = localStorage.getItem("companyId");
		const socket = socketConnection({ companyId });
	
		socket.on(`importMessages-${user.companyId}`, (data) => {
		  if (data.action === "refresh") {
			setStatusImport([]);
			handleRefresh();
		  }
		  if (data.action === "update") {
			setStatusImport(data.status);
		  }
		});
	
		return () => {
		  socket.disconnect();
		};
	  }, [user.companyId, handleRefresh]);

	useEffect(() => {
		fetchChannels();
	}, []);

	const fetchChannels = async () => {
		try {
			const { data } = await api.get("/hub-channel/");
			setAvailableChannels(data);
		} catch (err) {
			toastError(err);
		}
	};

	useEffect(() => {
		setUpdatedWhatsApps(whatsAppsRaw);
	}, [whatsAppsRaw]);


	const totalCanais = whatsApps.length + emailSettings.length;
	const canaisUtilizados =
	  whatsApps.filter(whatsapp => whatsapp.status === "CONNECTED").length +
	  emailSettings.filter((email) => email.smtpStatus === "CONNECTED").length;

	const trafegoSite = [...whatsApps, ...emailSettings.map((item) => ({...item, type: "email"}))].map(channel => {
		if (channel.type === "facebook") return "Facebook";
		if (channel.type === "instagram") return "Instagram";
		if (channel.type === "email") return "Email";
		return "WhatsApp";
	});

	return (
		<MainContainer>
			<ConfirmationModal
				title={confirmModalInfo.title}
				open={confirmModalOpen}
				onClose={setConfirmModalOpen}
				onConfirm={handleSubmitConfirmationModal}
				confirmModalInfo={confirmModalInfo}
				setUpdatedWhatsApps={setUpdatedWhatsApps}
				setConfirmModalInfo={setConfirmModalInfo}
				confirmationModalInitialState={confirmationModalInitialState}
			>
				{confirmModalInfo.message}
			</ConfirmationModal>
			<QrcodeModal
				open={qrModalOpen}
				setQrModalOpen={setQrModalOpen}
				setSelectedWhatsApp={setSelectedWhatsApp}
				onClose={handleCloseQrModal}
				setConfirmModalInfo={setConfirmModalInfo}
				setConfirmModalOpen={setConfirmModalOpen}
				setUpdatedWhatsApps={setUpdatedWhatsApps}
				whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
			/>
			<WhatsAppModal
				open={whatsAppModalOpen}
				onClose={handleCloseWhatsAppModal}
				setUpdatedWhatsApps={setUpdatedWhatsApps}
				modelType={modelType}
				setWhatsAppModalOpen={setWhatsAppModalOpen}
				setSelectedWhatsApp={setSelectedWhatsApp}
				whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
			/>
			<div style={{ display: "flex", height: "100%"}}>
				<div className={`cmp-channels__conections-container ${classes.connectionsContainer}`} style={{ height: "calc(100vh - 100px)", display: "flex", flexDirection: "column", gap: "1rem", width: "76%" }}>
					<div className={classes.cardsContainer}>
						<MainHeader>
							<Title>Canais disponíveis</Title>
						</MainHeader>
						<ChannelsCards
							classes={classes}
							availableChannels={availableChannels}
							user={user}
							setWhatsAppModalOpen={setWhatsAppModalOpen}
							setModalType={setModalType}
							handleOpenFacebookAppModal={handleOpenFacebookAppModal}
							handleOpenInstagramAppModal={handleOpenInstagramAppModal}
							handleOpenWhatsAppModal={handleOpenWhatsAppModal}
							handleOpenWhatsAppModalOfficial={handleOpenWhatsAppModalOfficial}
							handleOpenFacebookModalOfficial={handleOpenFacebookModalOfficial}
							handleOpenInstagramModalOfficial={handleOpenInstagramModalOfficial}
							setEmailSettings={setEmailSettings}
						/>
					</div>
					<div className={classes.cardsContainer}>
						<MainHeader>
							<Title>Conexões ativas</Title>
						</MainHeader>
						<StatusImport statusImport={statusImport} classes={classes} />
						<ConnectionsCards
							whatsApps={whatsApps}
							loading={loading}
							user={user}
							setSelectedWhatsApp={setSelectedWhatsApp}
							setQrModalOpen={setQrModalOpen}
							handleRequestNewQrCode={handleRequestNewQrCode}
							setUpdatedWhatsApps={setUpdatedWhatsApps}
							handleOpenQrModal={handleOpenQrModal}
							setConfirmModalOpen={setConfirmModalOpen}
							setModalType={setModalType}
							setWhatsAppModalOpen={setWhatsAppModalOpen}
							handleEditWhatsApp={handleEditWhatsApp}
							setConfirmModalInfo={setConfirmModalInfo}
							handleOpenConfirmationModal={handleOpenConfirmationModal}
							classes={classes} emailSettings={emailSettings}
							setEmailSettings={setEmailSettings}
							
						/>
					</div>
				</div>
				<ConnectionGraphs
					classes={classes}
					totalCanais={totalCanais}
					canaisUtilizados={canaisUtilizados}
					trafegoSite={trafegoSite}
				/>
			</div>
		</MainContainer>
	);
};

export default Connections;
