import React, { useState, createContext, useRef } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const CampaignContext = createContext();

const CampaignContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [previews, setPreviews] = useState([]);
  const requestInProgress = useRef(false);
  const previewsCache = useRef([]);

  const fetchPreviews = async () => {
    if (previewsCache.current.length > 0 || requestInProgress.current) {
      return;
    }

    requestInProgress.current = true;
    setLoading(true);

    try {
      const { data } = await api.get("/schedules-preview");
      setPreviews(data);
      previewsCache.current = data;
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
      requestInProgress.current = false;
    }
  };

  return (
    <CampaignContext.Provider
      value={{
        loading,
        setLoading,
        previews,
        setPreviews,
        fetchPreviews,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};

export { CampaignContext, CampaignContextProvider };
