import React, { useContext, useEffect, useRef, useState } from "react";

import { useParams, useHistory } from "react-router-dom";

import {
  Paper,
} from "@material-ui/core";
import api from "../../services/api";
import { socketConnection } from "../../services/socket";
import "./style.css";
import { has, isObject } from "lodash";

import { AuthContext } from "../../context/Auth/AuthContext";
import withWidth from "@material-ui/core/withWidth";
import useStyles from "./style";
import { ChatModal } from "./ChatComponents/components/ChatModal";
import { CategoriesModal } from "./ChatComponents/components/CategoriesModal";
import { DeleteCategoryModal } from "./ChatComponents/components/DeleteCategoryModal";
import FullChat from "./ChatComponents/components/FullChat";

function Chat(props) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { id: userId } = user;
  const userIdRef = useRef(userId); 
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const [showCategorieDialog, setShowCategorieDialog] = useState(false);
  const [showMobileChat, setMobileChat] = useState(false);
  const [dialogType, setDialogType] = useState("new");
  const [currentChat, setCurrentChat] = useState({});
  const [chatsPageInfo, setChatsPageInfo] = useState({ hasMore: false });
  const [messages, setMessages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [messagesPageInfo, setMessagesPageInfo] = useState({ hasMore: false });
  const [messagesPage, setMessagesPage] = useState(1);
  const [loading, setLoading] = useState(false);
  
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const isMounted = useRef(true);
  const [pickedCategorie, setPickedCategorie] = useState(null);
  const scrollToBottomRef = useRef();
  const { id } = useParams();
  const idRef = useRef(id);
  const [chats, setChats] = useState([]);
  const findCategories = async () => {
    const { data } = await api.get("/chat-categories");
    setCategories(data);
    return data;
  }
  if(chats.length && false) {
    console.log("")
  }
  useEffect(() => {
    findCategories();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    if (isMounted.current) {
      findChats().then((data) => {
        const { records } = data;
        if (records.length > 0) {
          setChats(records);
          setChatsPageInfo(data);

          if (idRef && records.length) {
            const chat = records.find((r) => r.uuid === idRef);
            selectChat(chat);
          }
        }
        setLoading(false)
      }).catch(() => setLoading(false));
    }
  }, []);

  const findMessages = async (chatId) => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/chats/${chatId}/messages?pageNumber=${messagesPage}`
      );
      setMessagesPage((prev) => prev + 1);
      setMessagesPageInfo(data);
      setMessages((prev) => [...data.records, ...prev]);
    } catch (err) { }
    setLoading(false);
  };
  
  const findMessagesRef = useRef(findMessages);

  if (!findMessagesRef.current) {
    findMessagesRef.current = async (chatId) => {
      setLoading(true);
      try {
        const { data } = await api.get(
          `/chats/${chatId}/messages?pageNumber=${messagesPage}`
        );
        setMessagesPage((prev) => prev + 1);
        setMessagesPageInfo(data);
        setMessages((prev) => [...data.records, ...prev]);
      } catch (err) {
      }
      setLoading(false);
    };
  }

  useEffect(() => {
    if (isObject(currentChat) && has(currentChat, "id")) {
      
      findMessagesRef.current(currentChat.id).then(() => {
        if (typeof scrollToBottomRef.current === "function") {
          setTimeout(() => {
            scrollToBottomRef.current();
          }, 300);
        }
      });
    }
  }, [currentChat]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-chat-user-${userIdRef}`, (data) => {
      if (data.action === "create") {
        setChats((prevChats) => [data.record, ...prevChats]);
      }
      if (data.action === "update") {
        setChats((prevChats) => prevChats.map((chat) => {
          if (chat.id === data.record.id) {
            setCurrentChat(data.record);
            return {
              ...data.record,
            };
          }
          return chat;
        }));
      }
    });

    socket.on(`company-${companyId}-chat`, (data) => {
      if (data.action === "delete") {
        setChats((prevChats) => prevChats.filter((c) => c.id !== +data.id));
        setMessages([]);
        setMessagesPage(1);
        setMessagesPageInfo({ hasMore: false });
        setCurrentChat({});
        history.push("/chats");
      }
    });

    if (isObject(currentChat) && has(currentChat, "id")) {
      socket.on(`company-${companyId}-chat-${currentChat.id}`, (data) => {
        if (data.action === "new-message" && (data.newMessage.senderId !== userIdRef || data.newMessage.mediaPath !== null)) {
          setMessages((prevMessages) => [...prevMessages, data.newMessage]);
          setChats((prevChats) => prevChats.map((chat) => {
            if (chat.id === data.newMessage.chatId) {
              return {
                ...data.chat,
              };
            }
            return chat;
          }));
          scrollToBottomRef.current();
        }

        if (data.action === "update") {
          setChats((prevChats) => prevChats.map((chat) => {
            if (chat.id === data.chat.id) {
              return {
                ...data.chat,
              };
            }
            return chat;
          }));
          scrollToBottomRef.current();
        }
      });
    }

    return () => {
      socket.disconnect();
    };
  }, [currentChat, history]);

  const selectChat = (chat) => {
    try {
      setMessages([]);
      setMessagesPage(1);
      setCurrentChat(chat);
      localStorage.setItem("currentChat", JSON.stringify(chat));

    } catch (err) { }
  };

  const sendMessage = async (contentMessage) => {
    setLoading(true);
    try {
      const { data } = await api.post(`/chats/${currentChat.id}/messages`, {
        message: contentMessage,
      });

     
    } catch (err) {
      scrollToBottomRef.current();
      console.error("Erro ao enviar mensagem:", err);
    } finally {
      scrollToBottomRef.current();
      setLoading(false);
    }
  };


  const deleteChat = async (chat, categorie) => {
    try {
      await api.delete(`/chats/${chat.id}`);
      setCategories((prevState => prevState
        .map((stateItem) => stateItem.id === categorie.id 
          ? {...stateItem, chats: stateItem.chats.filter((chatItem) => chatItem.id !== chat.id)}
          : stateItem)));
    } catch (err) { }
  };


  const loadMoreMessages = async () => {
    if (!loading) {
      findMessages(currentChat.id);
    }
  };

  const findChats = async () => {
    try {
      const { data } = await api.get("/chats");
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowNewCategories = (data) => {
    setCategories(prevState => [...prevState, data])
  }

  return (
    <>
      <DeleteCategoryModal
        open={deleteModalOpen}
        category={pickedCategorie}
        handleClose={() => setDeleteModalOpen(false)}
        handleDelete={(data) => {
          setCategories((prevState) => prevState.filter((category) => category.id !== data.id));
          setDeleteModalOpen(false);
        }}
      />
      <ChatModal
        categorie={pickedCategorie}
        type={dialogType}
        open={showDialog}
        chat={currentChat}
        handleEditChat={(data, categoryId, title, chatId) => {
          setCategories(prevState => prevState.map((categorie) => ({...categorie, chats: categorie.chats.map((item) => item.id === chatId ? data : item)})));
          setCurrentChat(data)
        }}
        handleLoadNewChat={(data) => {
          setMessages([]);
          setMessagesPage(1);
          setCurrentChat(data);
          setCategories(prevState => categories.map((categorie) => categorie.id === data.chatCategoryId ? {...categorie, chats: [...(categorie.chats ? categorie.chats : []), data]} : categorie));
          history.push(`/chats/${data.uuid}`);
        }}
        handleClose={() => setShowDialog(false)}
      />
      <CategoriesModal handleShowNewCategories={handleShowNewCategories} handleClose={() => setShowCategorieDialog(false)} open={showCategorieDialog} />
      <Paper className={classes.mainContainer}>
        <FullChat
          classes={classes}
          categories={categories}
          setShowCategorieDialog={setShowCategorieDialog}
          setDialogType={setDialogType}
          setPickedCategorie={setPickedCategorie}
          setShowDialog={setShowDialog}
          setDeleteModalOpen={setDeleteModalOpen}
          loading={loading}
          showMobileChat={showMobileChat}
          setMobileChat={setMobileChat}
          setLoading={setLoading}
          selectChat={selectChat}
          deleteChat={deleteChat}
          currentChat={currentChat}
          scrollToBottomRef={scrollToBottomRef}
          messagesPageInfo={messagesPageInfo}
          messages={messages}
          sendMessage={sendMessage}
          chatsPageInfo={chatsPageInfo}
          loadMoreMessages={loadMoreMessages}
        />
      </Paper>
    </>
  );
}

export default withWidth()(Chat);
