import React, { useContext, useState } from "react";
import {
    Box,
    CircularProgress,
    Menu,
    MenuItem,
    IconButton,
    useTheme,
} from "@material-ui/core";
import ChatList from "../../ChatList";
import { MoreVert, ArrowDropDown } from "@material-ui/icons";
import { AuthContext } from "../../../../context/Auth/AuthContext";

const CategoryItem = ({
    classes,
    categorie,
    setDialogType,
    setPickedCategorie,
    setShowDialog,
    setDeleteModalOpen,
    loading,
    setMobileChat,
    setLoading,
    selectChat,
    deleteChat,
    chatsPageInfo,
}) => {
    const theme = useTheme();
    const { user } = useContext(AuthContext);

    const [isChatListOpen, setIsChatListOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const toggleChatList = () => {
        setIsChatListOpen((prev) => !prev);
    };

    return (
        <div style={{marginBottom: "0.5rem"}}>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px",
                background: theme.palette.dark,  // Fundo suave
                borderRadius: "8px",  // Bordas arredondadas
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",  // Sombra suave
                transition: "box-shadow 0.3s ease",  // Transição para hover
            }}>
                <h4 style={{ fontSize: "16px", fontFamily: "Poppins", fontWeight: "700", color: theme.palette.primary.mainReverseByWhite }}>
                    {categorie.name}
                </h4>
                <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                    <IconButton
                        onClick={toggleChatList}
                        className={classes.btnStyles}
                        style={{ transition: "transform 0.3s", border: "none"}}
                    >
                        <ArrowDropDown
                            style={{
                                transform: isChatListOpen ? "rotate(180deg)" : "rotate(0deg)",
                                transition: "transform 0.3s ease",
                            }}
                        />
                    </IconButton>
                    {user.profile === "admin" && <>
                        <IconButton className={classes.btnStyles} style={{border: "none"}} onClick={handleMenuOpen}>
                            <MoreVert />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={isMenuOpen}
                            onClose={handleMenuClose}
                        >
                            <MenuItem
                                onClick={() => {
                                    setDialogType("");
                                    setPickedCategorie(categorie);
                                    setShowDialog(true);
                                    handleMenuClose();
                                    setIsChatListOpen(true);
                                }}
                            >
                                Adicionar Chat
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setPickedCategorie(categorie);
                                    setDeleteModalOpen(true);
                                    handleMenuClose();
                                }}
                            >
                                Deletar Categoria
                            </MenuItem>
                        </Menu>
                    </>}
                </div>
            </div>
            {isChatListOpen && (
                <div>
                    {loading && !categorie?.chats?.length ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <ChatList
                            chats={categorie.chats}
                            pageInfo={chatsPageInfo}
                            loading={loading}
                            setMobileChat={setMobileChat}
                            setLoading={setLoading}
                            handleSelectChat={(chat) => selectChat(chat)}
                            handleDeleteChat={(chat) => deleteChat(chat, categorie)}
                            handleEditChat={(chat) => {
                                setDialogType("edit");
                                setPickedCategorie(categorie);
                                setShowDialog(true);
                                selectChat(chat);
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default CategoryItem;
