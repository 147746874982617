import React, { useEffect, useState } from "react";
import { 
    IconButton, Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow, Paper, TablePagination, Typography, CircularProgress, Box
} from "@material-ui/core";
import { Add, Visibility, ArrowBack, CheckCircle, Cancel } from "@material-ui/icons";
import api from "../../services/api";
import EmailCampaignModal from "./EmailCampaignReports/EmailCampaignModal";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDate } from "../../hooks/useDate";

const useStyles = makeStyles(theme => ({
    tableStyles: {
        overflow: "auto",
        ...theme.scrollbarStyles
    },
    campaignStats: {
        display: "flex",
        gap: "1rem",
        justifyContent: "center",
        marginTop: "20px",
        padding: "10px",
        borderRadius: "5px",
        color: "white",
    },
    statBox: {
        border: "1px solid #ccc",
        padding: "10px",
        textAlign: "center",
        borderRadius: "5px",
        width: "150px",
        backgroundColor: theme.palette.primary.main,
        color: "white",
    }
}));

const EmailCampaigns = () => {
    const [open, setOpen] = useState(false);
    const [campaigns, setCampaigns] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [logsPage, setLogsPage] = useState(0);
    const [logsPerPage, setLogsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [loadingSelectedCampaign, setLoadingSelectedCampaign] = useState(false); 
    const classes = useStyles();
    const handleOpen = () => setOpen(true);
    const { datetimeToClient } = useDate();

    const fetchReport = async (reportId) => {
        try {
            setLoadingSelectedCampaign(true); 
            const response = await api.get(`/email-campaigns/${reportId}/report?page=${logsPage + 1}&limit=${logsPerPage}`);
            const { report, logs, pagination } = response.data;
            
            setSelectedCampaign({
                ...report,
                logs,
                pagination
            });
        } catch (error) {
            console.error("Erro ao buscar relatório da campanha:", error);
        } finally {
            setLoadingSelectedCampaign(false);
        }
    };

    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                setLoading(true);
                const response = await api.get(`/email-campaigns?page=${page + 1}`);
                setCampaigns(response.data.campaigns);
                setTotalPages(response.data.totalPages);
            } catch (error) {
                console.error("Erro ao buscar campanhas:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCampaigns();
    }, [page]);

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleLogsChangePage = (_, newPage) => {
        setLogsPage(newPage);
    };

    const theme = useTheme();

    return (
        <div style={{ padding: "20px" }}>
            {selectedCampaign ? (
                <div>
                    <div style={{display:"flex", alignItems: "center", gap: "0.5rem"}}>
                        <IconButton onClick={() => setSelectedCampaign(null)} ><ArrowBack /></IconButton>
                        <h5 style={{ margin: "20px 0", fontFamily: "Poppins", fontSize: "16px", fontWeight: "400", color: theme.palette.primary.neutralTextColor}}>Campanha: {selectedCampaign.campaignName}</h5>
                    </div>
                    
                    <div className={classes.campaignStats}>
                        <Box className={classes.statBox}>
                            <Typography style={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "400" }}>Total:</Typography>
                            <Typography style={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "400" }}>{selectedCampaign.totalEmails}</Typography>
                        </Box>
                        <Box className={classes.statBox}>
                            <Typography style={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "400" }}>Enviados:</Typography>
                            <Typography style={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "400" }}>
                                {selectedCampaign.logs.filter(log => log.success).length}
                            </Typography>
                        </Box>
                        <Box className={classes.statBox}>
                            <Typography style={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "400" }}>Agendado:</Typography>
                            <Typography style={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "400" }}>
                                {datetimeToClient(selectedCampaign.scheduledAt)   || "Sem agendamento"} 
                            </Typography>
                        </Box>
                    </div>

                    <TableContainer component={Paper} style={{ marginTop: "20px", height: "calc(100vh - 340px)" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Email</b></TableCell>
                                    <TableCell><b>Status</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedCampaign.logs.length > 0 ? (
                                    selectedCampaign.logs.map((log, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{log.recipientEmail}</TableCell>
                                            <TableCell>
                                                {log.success ? (
                                                    <CheckCircle style={{ color: "green" }} />
                                                ) : (
                                                    <Cancel style={{ color: "red" }} />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">Nenhum log encontrado.</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        component="div"
                        count={selectedCampaign.logs.length}
                        page={logsPage}
                        rowsPerPage={logsPerPage}
                        onPageChange={handleLogsChangePage}
                        rowsPerPageOptions={[10, 25, 50]}
                    />

                    {loadingSelectedCampaign && (
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <CircularProgress />
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <h1 style={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "400", color: theme.palette.primary.neutralTextColor }}>Campanhas de Email</h1>
                        <IconButton color="primary" onClick={handleOpen}>
                            <Add />
                        </IconButton>
                    </div>

                    {loading ? (
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <TableContainer className={classes.tableStyles} component={Paper} style={{ marginTop: "20px", height: "calc(100vh - 185px)" }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Nome</b></TableCell>
                                        <TableCell><b>Total de Contatos</b></TableCell>
                                        <TableCell><b>Data de Criação</b></TableCell>
                                        <TableCell><b>Ações</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {campaigns.length > 0 ? (
                                        campaigns.map((campaign) => (
                                            <TableRow key={campaign.id}>
                                                <TableCell>{campaign.campaignName}</TableCell>
                                                <TableCell>{campaign.totalEmails}</TableCell>
                                                <TableCell>{new Date(campaign.createdAt).toLocaleDateString()}</TableCell>
                                                <TableCell>
                                                    <IconButton color="primary" onClick={() => fetchReport(campaign.id)}>
                                                        <Visibility />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center">Nenhuma campanha encontrada.</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                count={totalPages * rowsPerPage}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[10]}
                            />
                        </TableContainer>
                    )}
                </div>
            )}

            <EmailCampaignModal setCampaigns={setCampaigns} open={open} setOpen={setOpen} />
        </div>
    );
};

export default EmailCampaigns;
