import React, { useContext, useEffect, useState } from "react";
import { Button, TextField, Dialog, DialogContent, DialogActions, Select, MenuItem, CircularProgress, useTheme } from "@material-ui/core";
import { FaPaperclip } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import api from "../../../../services/api";
import { AuthContext } from "../../../../context/Auth/AuthContext";
import { EmailsContext } from "../../../../context/EmailContext";
import toastError from "../../../../errors/toastError";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    tableStyles: {
        overflow: "auto",
        ...theme.scrollbarStyles
    },
}));

const EmailCampaignModal = ({ open, setOpen, setCampaigns }) => {
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState("");
    const [selectedEmailSetting, setSelectedEmailSetting] = useState("");
    const [campaignName, setCampaignName] = useState("");
    const [subject, setSubject] = useState("");
    const [editorValue, setEditorValue] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingContacts, setLoadingContacts] = useState(false);
    const [scheduledAt, setScheduleAt] = useState("");
    const { user } = useContext(AuthContext);
    const { emailSettings, setEmailSettings, abortController, cancelRequests } = useContext(EmailsContext);
    const classes = useStyles();

    useEffect(() => {
        if (emailSettings?.length > 0) {
            return;
        }
        const fetchEmailSettings = async () => {
            try {
                const response = await api.get("/email-settings", {
                    signal: abortController.signal,
                });
                setEmailSettings(response.data);
            } catch (error) {
                if (error.name !== "AbortError") {
                    console.error("Error fetching email settings:", error);
                }
                setEmailSettings([]);
            }
        };

        fetchEmailSettings();
        return cancelRequests;
    }, []);

    useEffect(() => {
        const fetchContacts = async () => {
            setLoadingContacts(true);
            try {
                const response = await api.get(`/contact-lists/list?companyId=${user.companyId}`);
                setContacts(response.data);
            } catch (error) {
                console.error("Erro ao buscar contatos", error);
            }
            setLoadingContacts(false);
        };
        fetchContacts();
    }, [user.companyId]);

    const handleClose = () => setOpen(false);

    const handleFileChange = (e) => {
        setAttachments([...attachments, ...Array.from(e.target.files)]);
    };

    const resetForm = () => {
        setSelectedContact("");
        setSelectedEmailSetting("");
        setCampaignName("");
        setSubject("");
        setEditorValue("");
        setAttachments([]);
        setScheduleAt("");
    };

    const handleSendEmail = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("emailSettingId", selectedEmailSetting);
        formData.append("subject", subject);
        formData.append("body", editorValue);
        formData.append("html", editorValue);
        formData.append("campaignName", campaignName);
        formData.append("contactListId", selectedContact);

        if (scheduledAt) {
            formData.append("scheduledAt", scheduledAt);
        }

        attachments.forEach((file) => {
            formData.append("files", file);
        });

        try {
            const { data } = await api.post(`/email-campaigns/${selectedEmailSetting}`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            setCampaigns(prevState => [data, ...prevState]);
            resetForm();
            handleClose();
        } catch (error) {
            toastError(error);
        }
        setLoading(false);
    };

    const theme = useTheme();

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <h5 style={{ color: theme.palette.primary.neutralTextColor, display: "flex", padding: "1rem", fontSize: "16px", fontFamily: "Poppins", fontWeight: "500", borderBottom: `1px solid ${theme.palette.primary.neutralBorder}` }}>Criar campanha</h5>
            <DialogContent className={classes.tableStyles} style={{ height: "400px" }}>
                <label style={{ display: "flex", marginBottom: "0.5rem" }}>Nome da Campanha</label>
                <TextField
                    variant="outlined"
                    value={campaignName}
                    onChange={(e) => setCampaignName(e.target.value)}
                    fullWidth
                    size="small"
                />
                <label style={{ margin: "1rem 0 0.5rem 0", display: "flex" }}>Agendar envio</label>
                <TextField
                    variant="outlined"
                    type="datetime-local"
                    value={scheduledAt}
                    onChange={(e) => setScheduleAt(e.target.value)}
                    fullWidth
                    size="small"
                />

                <label style={{ display: "flex", marginTop: "1rem", marginBottom: "0.5rem", }}>E-mail de envio</label>
                <Select
                    value={selectedEmailSetting}
                    onChange={(e) => setSelectedEmailSetting(e.target.value)}
                    displayEmpty
                    fullWidth
                    variant="outlined"
                    size="small"
                >
                    <MenuItem value="" disabled>Selecione uma configuração de e-mail</MenuItem>
                    {emailSettings.map((setting) => (
                        <MenuItem key={setting.id} value={setting.id}>
                            {setting.smtpUser}
                        </MenuItem>
                    ))}
                </Select>

                <label style={{ display: "flex", marginBottom: "0.5rem", marginTop: "1rem" }}>Para</label>
                {loadingContacts ? (
                    <CircularProgress size={24} style={{ display: "block", margin: "10px auto" }} />
                ) : (
                    <Select
                        value={selectedContact}
                        onChange={(e) => setSelectedContact(e.target.value)}
                        displayEmpty
                        fullWidth
                        variant="outlined"
                        size="small"
                    >
                        <MenuItem value="" disabled>Selecione um contato</MenuItem>
                        {contacts.map((contact) => (
                            <MenuItem key={contact.id} value={contact.id}>
                                {contact.name}
                            </MenuItem>
                        ))}
                    </Select>
                )}

                <label style={{ margin: "1rem 0 0.5rem 0", display: "flex" }}>Assunto</label>
                <TextField
                    variant="outlined"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    fullWidth
                    size="small"
                />

                <label style={{ margin: "1rem 0 0.5rem 0", display: "flex" }}>Corpo</label>
                <div style={{ height: "200px", display: "flex", flexDirection: "column" }}>
                    <ReactQuill
                        value={editorValue}
                        onChange={setEditorValue}
                        style={{ height: "70%" }}
                    />
                </div>


            </DialogContent>

            {attachments.length > 0 && (
                <div style={{ padding: "0 1.4rem" }}>
                    <label>Anexos:</label>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {attachments.map((file, index) => (<span key={index}>{file.name}</span>))}
                    </div>
                </div>
            )}

            <span style={{ fontFamily: "Poppins", padding: "1rem", fontSize: "12px" }}>
                {`Utilize variáveis como {nome}, {numero}, {email}, {cpf}, {numeroContrato}, {valorDevido}, {diasAtraso}, {observacao}, {campo1}, {campo2}, {campo3}, ou defina suas próprias variáveis personalizadas para criar mensagens dinâmicas e completas.`}
            </span>

            <DialogActions style={{ padding: "1rem", borderTop: "1px solid #ccc" }}>
                <input type="file" multiple onChange={handleFileChange} style={{ display: "none" }} id="file-upload" />
                <label htmlFor="file-upload">
                    <Button component="span" color="primary" variant="contained" style={{ padding: "0.65rem", color: "white" }}>
                        <FaPaperclip />
                    </Button>
                </label>
                <Button onClick={handleSendEmail} color="primary" variant="contained" style={{ color: "white" }} disabled={loading}>
                    {loading ? <CircularProgress size={24} style={{ color: "white" }} /> : "Enviar"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EmailCampaignModal;
