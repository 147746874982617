import React from 'react';
import { useTheme } from "@material-ui/core/styles";
import {
    Card,
    CardContent,
    Paper,
    IconButton,
    Typography,
    Tooltip
} from "@material-ui/core";
import {
    Edit,
    DeleteOutline,
    Instagram,
    WhatsApp
} from "@material-ui/icons";
import TableRowSkeleton from "../../../../components/TableRowSkeleton";
import { Can } from "../../../../components/Can";
import { format, parseISO } from "date-fns";
import ActionButtons from '../buttons/ActionButtons';
import StatusToolTips from '../StatusTooltips';
import baileyIcon from "../../../../assets/baileys-icon.png";
import FacebookInstagramIcon from '../../../../components/FacebookInstagramIcon';
import useStyles from './style';
import CrownIcon from './CrowIcon';
import api from '../../../../services/api';
import EmailSettingsCards from '../../../../components/EmailSettingCards';

const ConnectionsCards = ( {
    whatsApps,
    loading,
    user,
    setUpdatedWhatsApps,
    handleEditWhatsApp,
    handleRequestNewQrCode,
    handleOpenQrModal,
    setSelectedWhatsApp,
    setConfirmModalInfo,
    setModalType,
    setConfirmModalOpen,
    setWhatsAppModalOpen,
    setQrModalOpen,
    handleOpenConfirmationModal,
    setEmailSettings,
    emailSettings
} ) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    
    const renderDetails = {
        instagram: { title: "Instagram", icon: <Instagram style={{ fontSize: 30, color: "#E1306C" }} /> },
        facebook: { title: "Facebook", icon: <img style={{ maxWidth: "25px" }} alt="Whatsapp Official" src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg" />},
        official: { title: "Whatsapp", icon: <WhatsApp style={{ fontSize: 30, color: "#25D366" }} />, subtype: "Official" },
        default: { title: "Whatsapp", icon: <img style={{ maxWidth: "25px" }} alt="Whatsapp Official" src={baileyIcon} />, subtype: "Baileys" }
    };
    
    const renderTitleAndIcon = (type) => {
        return renderDetails[type] || renderDetails.default;
    };

    const isDeleteConnectionButtonActive = !(localStorage.getItem("isDeleteConnectionButtonActive") === "true");

    return (
        <Paper className={`${classes.mainPaper} cmp-connection_cards`}>
            {loading ? (
                <TableRowSkeleton />
            ) : (
                <>
                    {whatsApps?.length > 0 && whatsApps.map(whatsApp => {
                        const { title, icon, subtype } = renderTitleAndIcon(whatsApp.type);
                        const isSocial = whatsApp.type === "instagram" || whatsApp.type === "facebook" || whatsApp.type === "official";
                        const isHub = whatsApp.type === "instagram" || whatsApp.type === "facebook";
                        return (
                            <Card className={`${classes.card} cmp-connection__card`} key={whatsApp.id}>
                                <div style={{position: "absolute", top: "3.4rem", left: "1rem", zIndex: "10"}}>
                                    {whatsApp.isDefault === true ? <CrownIcon /> : ""}
                                </div>
                                <CardContent className={classes.cardBox}>
                                    <div className={classes.iconTitleBox}>
                                        <Typography variant="h6" className={classes.cardTitleStyles}>
                                            {title} <span style={{ fontSize: "10px", fontWeight: "400" }}>| {isHub ? "Hub Noti." : subtype}</span>
                                        </Typography>
                                    </div>
                                    <div style={{ display: "flex", gap: "4px", alignItems: "center", justifyContent: "space-between", margin: "8px 0" }}>
                                        <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                                            {icon}
                                            <Tooltip title={whatsApp.name} arrow>
                                                <div style={{ fontSize: "16px", fontFamily: "Poppins", color: "gray", fontWeight: "700" }}>
                                                    {whatsApp.name}
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div>
                                        {!isSocial && (
                                            <Can
                                                role={user.profile}
                                                perform="connections-page:actionButtons"
                                                yes={() => (
                                                    <ActionButtons
                                                        whatsApp={whatsApp}
                                                        setConfirmModalOpen={setConfirmModalOpen}
                                                        handleOpenConfirmationModal={handleOpenConfirmationModal}
                                                        handleOpenQrModal={handleOpenQrModal}
                                                        classes={classes}
                                                        setConfirmModalInfo={setConfirmModalInfo}
                                                        setSelectedWhatsApp={setSelectedWhatsApp}
                                                        setQrModalOpen={setQrModalOpen}
                                                        setUpdatedWhatsApps={setUpdatedWhatsApps}
                                                        handleRequestNewQrCode={handleRequestNewQrCode}
                                                    />
                                                )}
                                            />
                                        )}
                                    </div>
                                    <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                                        <Typography className={classes.cardTitleStyles}>Criado:</Typography>
                                        <StatusToolTips whatsApp={whatsApp} classes={classes} />
                                        <Typography>{format(parseISO(whatsApp.updatedAt), "dd/MM/yy HH:mm")}</Typography>
                                    </div>
                                    <Can
                                        role={user.profile}
                                        perform="connections-page:editOrDeleteConnection"
                                        yes={() => (
                                            <div className={`${classes.actionButtons} cmp-action-buttons`}>
                                                <IconButton size="small" className={classes.addButton} onClick={() => handleEditWhatsApp(whatsApp, whatsApp.type, setSelectedWhatsApp, setModalType, setWhatsAppModalOpen)}>
                                                    <Edit />
                                                </IconButton>
                                                {!isDeleteConnectionButtonActive && <IconButton
                                                    size="small"
                                                    className={classes.addButton}
                                                    onClick={() => handleOpenConfirmationModal("delete", whatsApp.id, setConfirmModalInfo, setConfirmModalOpen)}
                                                >
                                                    <DeleteOutline />
                                                </IconButton>}
                                            </div>
                                        )}
                                    />
                                </CardContent>
                            </Card>
                        );
                    })}
                    <EmailSettingsCards
                        emailSettings={emailSettings}
						setEmailSettings={setEmailSettings}
                        user={user}/>
                </>
            )}
        </Paper>
    );
};

export default ConnectionsCards;
