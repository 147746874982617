import React, { useContext, useEffect, useState } from "react";

import api from "../../services/api";
import toastError from "../../errors/toastError";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import MessagesList from "../MessagesList";
import SpyMessageList from "../SpyMessageList";

import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import { socketConnection } from "../../services/socket";
import ContactDetailsModal from "../ContactDetailsModal";
import CampaingDetails from "../CampaingDetails";


const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
    borderRadius: "8px",
    border: `2px solid ${theme.palette.primary.man}70`,
  },

}));

export default function TicketMessagesDialog({ open, handleClose, ticketId, markAsRead = true, isSpy = false, fullTicket = {} }) {
  const history = useHistory();
  const classes = useStyles();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [campaingDetailsOpen, setCampaignDetailsOpen] = useState(false);
  useEffect(() => {
    let delayDebounceFn = null;
    if(isSpy) {
      setLoading(false);
      setContact(fullTicket.contact)
      setTicket(fullTicket);
      return
    }
    if (open) {
      setLoading(true);
      delayDebounceFn = setTimeout(() => {
        const fetchTicket = async () => {
          try {
            const { data } = await api.get("/tickets/" + ticketId);

            setContact(data.contact);
            setTicket(data);
            setLoading(false);
          } catch (err) {
            setLoading(false);
            toastError(err);
          }
        };
        fetchTicket();
      }, 500);
    }
    return () => {
      if (delayDebounceFn !== null) {
        clearTimeout(delayDebounceFn);
      }
    };
  }, [ticketId, user, history, open]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    let socket = null;
    if(isSpy) {
      return
    }
    if (open) {
      socket = socketConnection({ companyId });
      socket.on("connect", () => socket.emit("joinChatBox", `${ticket.id}`));

      socket.on(`company-${companyId}-ticket`, (data) => {
        if (data.action === "update") {
          setTicket(data.ticket);
        }

        if (data.action === "delete") {
          // toast.success("Ticket deleted sucessfully.");
          history.push("/tickets");
        }
      });

      socket.on(`company-${companyId}-contact`, (data) => {

        if (data.action === "update") {
          setContact((prevState) => {
            if (prevState.id === data.contact?.id) {
              return { ...prevState, ...data.contact };
            }
            return prevState;
          });
        }
      });
    }

    return () => {
      if (socket !== null) {
        socket.disconnect();
      }
    };
  }, [ticketId, ticket, history, open]);

  const handleCloseModal = () => {
    setDetailsModalOpen(false);
  };

  const renderTicketInfo = () => {
    if (ticket.user !== undefined) {
      return (
        <>
          <TicketInfo
            contact={contact}
            ticket={ticket}
          />
        </>
      );
    }
  };

  const renderMessagesList = () => {
    return (
      <Box className={classes.root}>
        <SpyMessageList
          ticket={ticket}
          ticketId={ticket.id}
          isGroup={ticket.isGroup}
          user={user}
          markAsRead={markAsRead}
          isSpy={true}
          maxHeigth={400}
        ></SpyMessageList>
        <ContactDetailsModal
          contactId={contact.id}
          companyId={contact.companyId}
          open={detailsModalOpen}
          onClose={handleCloseModal}
        />
      </Box>
    );
  };

  return (
    <Dialog maxWidth="md" onClose={handleClose} open={open}>
      <TicketHeader loading={loading} className={classes.contentWrapper}>
        {renderTicketInfo()}

        {ticket.status === "pending" && (<Button
          onClick={() => setDetailsModalOpen(true)}
          color="primary"
          style={{ marginRight: "1rem" }}
        >
          Detalhes do contrato
        </Button>)}
        {ticket.status === "pending" && (<Button
          onClick={() => setCampaignDetailsOpen(true)}
          color="primary"
          style={{ marginRight: "1rem" }}>
          Detalhes da campanha
        </Button>)}
      </TicketHeader>

      <CampaingDetails
        ticketId={ticket.id}
        open={campaingDetailsOpen}
        onClose={() => setCampaignDetailsOpen(false)}
      />
      <ReplyMessageProvider>{renderMessagesList()}</ReplyMessageProvider>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
