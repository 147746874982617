import React from "react";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { i18n } from "../../translate/i18n";
import { Field } from "formik";
import QueueSelect from "../QueueSelect";
import { Can } from "../Can";

const ModalForm = ({
    classes,
    touched,
    errors,
    loggedInUser,
    selectedQueueIds,
    setSelectedQueueIds,
    whatsappId,
    setWhatsappId,
    whatsApps,
    callRestriction,
    setCallRestriction,
    cannotCall,
    setCannotCall
}) => {
    return (
        <DialogContent dividers>
            <div className={classes.multFieldLine}>
                <Field
                    as={TextField}
                    label={i18n.t("userModal.form.name")}
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                />
                <Field
                    as={TextField}
                    label={"Sua senha"}
                    type="newkey"
                    name="newkey"
                    error={touched.newkey && Boolean(errors.newkey)}
                    helperText={touched.newkey && errors.newkey}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    autoComplete="false"
                    inputProps={{
                        autoComplete: "off",
                    }}
                    InputProps={{
                        classes: {
                            root: "disable-autofill",
                        },
                    }}
                />
            </div>

            <div className={classes.multFieldLine}>
                <Field
                    as={TextField}
                    label={"Usuário de login"}
                    name="userInfo"
                    error={touched.userInfo && Boolean(errors.userInfo)}
                    helperText={touched.userInfo && errors.userInfo}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    autoComplete="false"
                    inputProps={{
                        autoComplete: "off",
                    }}
                    InputProps={{
                        classes: {
                            root: "disable-autofill",
                        },
                    }}
                />
                <FormControl variant="outlined" className={classes.formControl} margin="dense">
                    <Can
                        role={loggedInUser.profile}
                        perform="user-modal:editProfile"
                        yes={() => (
                            <>
                                <InputLabel id="profile-selection-input-label">
                                    {i18n.t("userModal.form.profile")}
                                </InputLabel>

                                <Field
                                    as={Select}
                                    label={i18n.t("userModal.form.profile")}
                                    name="profile"
                                    labelId="profile-selection-label"
                                    id="profile-selection"
                                    required
                                >
                                    <MenuItem value="admin">Administrador</MenuItem>
                                    <MenuItem value="user">Usuário</MenuItem>
                                    <MenuItem value="supervisor">Supervisor</MenuItem>
                                </Field>
                            </>
                        )}
                    />
                </FormControl>
            </div>

            <div className={classes.multFieldLine}>
                <Field
                    as={TextField}
                    label={i18n.t("contactModal.form.number")}
                    name="wpp"
                    placeholder="5513912344321"
                    error={touched.wpp && Boolean(errors.wpp)}
                    helperText={touched.wpp && errors.wpp}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                />
            </div>

            <div className={classes.multFieldLine}>
                <Field
                    as={TextField}
                    label={i18n.t("userModal.form.maxOpenTickets")}
                    name="maxOpenTickets"
                    type="number"
                    error={touched.maxOpenTickets && Boolean(errors.maxOpenTickets)}
                    helperText={touched.maxOpenTickets && errors.maxOpenTickets}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                />
            </div>

            <Can
                role={loggedInUser.profile}
                perform="user-modal:editQueues"
                yes={() => (
                    <QueueSelect
                        selectedQueueIds={selectedQueueIds}
                        onChange={values => setSelectedQueueIds(values)}
                    />
                )}
            />
            <Can
                role={loggedInUser.profile}
                perform="user-modal:editProfile"
                yes={() => (
                    <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                        <InputLabel>
                            {i18n.t("userModal.form.whatsapp")}
                        </InputLabel>
                        <Field
                            as={Select}
                            value={whatsappId}
                            onChange={(e) => setWhatsappId(e.target.value)}
                            label={i18n.t("userModal.form.whatsapp")}
                        >
                            <MenuItem value={''}>&nbsp;</MenuItem>
                            {whatsApps.filter((whats) => {
                                return whats.type === null;
                            }).map((whatsapp) => (
                                <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
                            ))}
                        </Field>
                    </FormControl>
                )}
            />
            <Can
                role={loggedInUser.profile}
                perform="user-modal:editProfile"
                yes={() => (
                    <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                        <InputLabel>
                            {"Bloquear recebimento de chamadas."}
                        </InputLabel>
                        <Field
                            as={Select}
                            value={callRestriction ? "yes" : "no"}
                            onChange={(e) => setCallRestriction(e.target.value === "yes")}
                            label={"Bloquear recebimento de chamadas."}
                        >
                            <MenuItem value="no">Não</MenuItem>
                            <MenuItem value="yes">Sim</MenuItem>
                        </Field>
                    </FormControl>
                )}
            />
            <Can
                role={loggedInUser.profile}
                perform="user-modal:editProfile"
                yes={() => (
                    <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                        <InputLabel>
                            {"Bloquear envio de chamadas"}
                        </InputLabel>
                        <Field
                            as={Select}
                            value={cannotCall ? "yes" : "no"}
                            onChange={(e) => setCannotCall(e.target.value === "yes")}
                            label={"Bloquear envio de chamadasx"}
                        >
                            <MenuItem value="no">Não</MenuItem>
                            <MenuItem value="yes">Sim</MenuItem>
                        </Field>
                    </FormControl>
                )}
            />
        </DialogContent>
    );
};

export default ModalForm;
