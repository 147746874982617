import React from 'react';

const CrownIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        fill="currentColor"
    >
        <path d="M12 2L8.5 8.5L2 10L6 14L5 21H19L18 14L22 10L15.5 8.5L12 2ZM17.5 19H6.5L7.4 14.3L12 17L16.6 14.3L17.5 19ZM9.4 9.8L12 5.5L14.6 9.8L19.1 11L16 13.9L16.7 18.1L12 15.5L7.3 18.1L8 13.9L4.9 11L9.4 9.8Z"/>
    </svg>
);

export default CrownIcon;
