import React, { useState, useRef, createContext, useReducer } from "react";
import api from "../../services/api";
import reducer from "./ChatContextComponents/reducer";
import toastError from "../../errors/toastError";

const ChatContext = createContext();

const ChatContextProvider = ({ children }) => {
  const [chats, dispatch] = useReducer(reducer, []);
  const [searchParam] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const isFetching = useRef(false);
  const lastPageNumber = useRef(1); // Armazena a última página carregada

  const fetchChats = async () => {
    if (isFetching.current || (chats.length > 0 && pageNumber === lastPageNumber.current)) return;

    isFetching.current = true;
    setLoading(true);

    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber },
      });

      dispatch({ type: "LOAD_CHATS", payload: data.records });
      setHasMore(data.hasMore);
      lastPageNumber.current = pageNumber;
    } catch (err) {
      toastError(err);
    } finally {
      isFetching.current = false;
      setLoading(false);
    }
  };

  return (
    <ChatContext.Provider
      value={{
        fetchChats,
        chats,
        dispatch,
        searchParam,
        pageNumber,
        setPageNumber,
        hasMore,
        setHasMore,
        loading,
        setLoading
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export { ChatContext, ChatContextProvider };
