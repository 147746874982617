import { Box, Chip, TextField, useTheme } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";

export function ModalTagsFilter({ onFiltered, selectedTags = [], widthStyle = null, foundTags, setFoundTags }) {
  const [tags, setTags] = useState([]);
  const theme = useTheme();
  useEffect(() => {
    async function fetchData() {
      await loadTags();
    }
    fetchData();
  }, []);

  const loadTags = async () => {
    try {
      const { data } = await api.get(`/tags/list`);
      setTags(data);
      const filteredTags = data.filter((tag) => selectedTags.includes(tag.id));
      setFoundTags(filteredTags);
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = async (value) => {
    setFoundTags(value);
    onFiltered(value);
  };

  return (
    <Box style={{ padding: widthStyle ? "0" : "0 1rem 1rem 1rem", width: widthStyle ? "100%" : "50%" }}>
      <Autocomplete
        multiple
        size="small"
        options={tags}
        value={foundTags}
        style={{ color: "#9F9797", fontFamily: "Poppins", fontSize: "14px" }}

        onChange={(e, v, r) => onChange(v)}
        getOptionLabel={(option) => option.name}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              style={{
                background: `${theme.palette.primary.mainReverseByWhite}70`,
                fontFamily: "Poppins",
                textShadow: "none",
                color: theme.palette.primary.mainReverseByWhite,
                borderRadius: "4px",
                border: "none"
              }}
              label={option.name}
              {...getTagProps({ index })}
              size="small"
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Tags"
          />
        )}
      />
    </Box>
  );
}
