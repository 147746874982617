import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Tooltip, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@material-ui/core";
import { MailOutline, Edit, DeleteOutline } from "@material-ui/icons";
import api from "../../services/api";
import truncateText from "../../utils/truncateText";
import { Can } from "../Can";
import EmailSettingsModal from "../EmailSettingsModal";

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        background: "transparent",
        boxShadow: "none",
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem",
    },
    card: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "relative",
        border: "1px solid #ddd",
        width: "calc((100% / 3) - 1rem)",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    cardBox: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        gap: "0.5rem",
        minHeight: "207px"
    },
    cardTitleStyles: {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "bold",
        color: theme.palette.primary.mainReverseByWhite,
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
    },
    actionButtons: {
        display: "flex",
        gap: "1rem",
        justifyContent: "flex-end",
        position: "absolute",
        top: "1rem",
        right: "1rem",
    },
    addButton: {
        color: theme.palette.primary.mainReverseByWhite,
        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        borderRadius: "8px",
        background: "transparent",
        "&:hover": {
            background: "transparent",
        },
    },
    greenCircle: {
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        backgroundColor: "rgb(76, 175, 80)",
        display: "inline-block",
    },
}));

const EmailSettingsCards = ({ user, emailSettings, setEmailSettings }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedEmailId, setSelectedEmailId] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [emailToDelete, setEmailToDelete] = useState(null);

    useEffect(() => {
        const fetchEmailSettings = async () => {
            try {
                const response = await api.get("/email-settings/company");
                setEmailSettings(response.data);
            } catch (error) {
                console.error("Erro ao carregar configurações de email:", error);
            }
        };

        fetchEmailSettings();
    }, []);

    const handleOpenModal = (emailId) => {
        setSelectedEmailId(emailId);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedEmailId(null);
    };

    const handleDelete = async () => {
        try {
            await api.delete(`/email-settings/${emailToDelete}`);
            setEmailSettings((prev) =>
                prev.filter((setting) => setting.id !== emailToDelete)
            );
            setIsDeleteDialogOpen(false);
            setEmailToDelete(null);
        } catch (error) {
            console.error("Erro ao deletar configuração de email:", error);
        }
    };

    const handleOpenDeleteDialog = (emailId) => {
        setEmailToDelete(emailId);
        setIsDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
        setEmailToDelete(null);
    };

    const isDeleteConnectionButtonActive = !(localStorage.getItem("isDeleteConnectionButtonActive") === "true");

    return (
        <>
            {emailSettings?.length === 0 ? (
                <></>
            ) : (
                emailSettings.map((emailSetting) => (
                    <Card className={classes.card} key={emailSetting.id}>
                        <CardContent className={classes.cardBox}>
                            <Typography className={classes.cardTitleStyles}>
                                E-mail <span style={{ fontSize: "10px", fontWeight: "400" }}>| Oficial</span>
                            </Typography>
                            <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                                <img src="https://cdn-icons-png.freepik.com/512/2250/2250206.png" alt="E-mail" style={{ width: "30px", height: "30px" }} />
                                <Tooltip title={emailSetting.smtpUser}>
                                    <span
                                        style={{
                                            fontSize: "16px",
                                            fontFamily: "Poppins",
                                            color: "gray",
                                            fontWeight: "700",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {truncateText(emailSetting.smtpUser, 8)}
                                    </span>
                                </Tooltip>
                            </div>
                            <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                                <Typography className={classes.cardTitleStyles} style={{ fontSize: "12px" }}>
                                    Criado:
                                </Typography>
                                <div className={classes.greenCircle} style={{
                                    backgroundColor: emailSetting.smtpStatus !== "CONNECTED" ? "rgb(244, 67, 54)" : "rgb(76, 175, 80)", // vermelho ou verde
                                }}></div>
                                <Typography style={{ fontSize: "12px" }}>
                                    {new Date(emailSetting.createdAt).toLocaleDateString("pt-BR")}
                                </Typography>
                            </div>
                            <Can
                                role={user.profile}
                                perform="connections-page:editOrDeleteConnection"
                                yes={() => (
                                    <div className={`${classes.actionButtons} cmp-action-buttons`}>
                                        <IconButton
                                            size="small"
                                            className={classes.addButton}
                                            onClick={() => handleOpenModal(emailSetting.id)}
                                        >
                                            <Edit />
                                        </IconButton>
                                        {!isDeleteConnectionButtonActive && <IconButton
                                            size="small"
                                            className={classes.addButton}
                                            onClick={() => handleOpenDeleteDialog(emailSetting.id)}
                                        >
                                            <DeleteOutline />
                                        </IconButton>}
                                    </div>
                                )}
                            />
                        </CardContent>
                    </Card>
                ))
            )}
            {isModalOpen && (
                <EmailSettingsModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    isEdit={true}
                    setEmailSettings={setEmailSettings}
                    emailId={selectedEmailId}
                />
            )}
            <Dialog
                open={isDeleteDialogOpen}
                onClose={handleCloseDeleteDialog}
            >
                <DialogTitle>Confirmar Exclusão</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Tem certeza de que deseja excluir esta configuração de email?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDelete} color="secondary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EmailSettingsCards;
