import React, { useState, useEffect, createContext, useRef } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const ContactsContext = createContext();

const initialState = {
  name: "",
  number: "",
  email: "",
  disableBot: false,
};

const ContactsContextProvider = ({ children }) => {
  const [disableBot, setDisableBot] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [whatsAppId, setWhatsAppId] = useState(0);
  const [contactBotInfo, setContactBot] = useState(initialState);
  const isMounted = useRef(true);
  const lastContactId = useRef(null);
  const fetchPromises = useRef(new Map());
  const cache = useRef(new Map());
  const [contact, setContact] = useState(initialState);

  const fetchContact = async (contactId) => {
    if (!contactId?.id || contactId) return;

    if (fetchPromises.current.has(contactId)) {
      return fetchPromises.current.get(contactId);
    }

    if (cache.current.has(contactId)) {
      const cachedData = cache.current.get(contactId);
      setContactBot(cachedData);
      setDisableBot(cachedData.disableBot);
      setWhatsAppId(cachedData.whatsappId);
      setIsBlocked(cachedData.isBlocked);
      setContact(cachedData);
      return Promise.resolve(cachedData);
    }

    try {
      lastContactId.current = contactId;

      const fetchPromise = api.get(`/contacts/${contactId?.id || contactId}`).then(({ data }) => {
        cache.current.set(contactId, data);
        setContactBot(data);
        setDisableBot(data.disableBot);
        setWhatsAppId(data.whatsappId);
        setIsBlocked(data.isBlocked);
        setContact(data);
        fetchPromises.current.delete(contactId);
        return data;
      });

      fetchPromises.current.set(contactId, fetchPromise);
      return fetchPromise;
    } catch (err) {
      fetchPromises.current.delete(contactId);
      toastError(err);
    }
  };

  return (
    <ContactsContext.Provider
      value={{
        fetchContact,
        contactBotInfo,
        setContactBot,
        disableBot,
        setDisableBot,
        isBlocked,
        setIsBlocked,
        whatsAppId,
        setWhatsAppId,
        contact,
        setContact,
        isMounted,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};

export { ContactsContext, ContactsContextProvider };
