import React, { useState, useRef, createContext, useEffect } from "react";
import api from "../../services/api";

const SettingsContext = createContext();

const SettingsContextProvider = ({ children }) => {
  const [showGroupTab, setShowTabGroup] = useState(false);
  const [settings, setSettings] = useState([]);
  const fetchPromise = useRef(null); // Armazena a Promise da requisição

  const fetchSettings = async () => {
    if (settings.length > 0) return settings; // Retorna os dados se já estiverem carregados
    if (fetchPromise.current) return fetchPromise.current; // Se já estiver buscando, reutiliza a mesma Promise

    fetchPromise.current = api.get("/settings").then(({ data }) => {
      setSettings(data);
      const showGroups = data.find((s) => s.key === "CheckMsgIsGroup");
      setShowTabGroup(showGroups?.value === "disabled");
      fetchPromise.current = null; // Reseta a Promise após a conclusão
      return data;
    }).catch((err) => {
      fetchPromise.current = null; // Reseta mesmo em caso de erro
      throw err; // Relança o erro para que `getAll` o capture
    });

    return fetchPromise.current;
  };

  const getAll = async () => {
    return settings.length > 0 ? settings : fetchSettings();
  };

  return (
    <SettingsContext.Provider
      value={{ showGroupTab, setShowTabGroup, fetchSettings, settings, getAll }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export { SettingsContext, SettingsContextProvider };
