import React, { useState } from "react";
import DOMPurify from "dompurify";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ReplyIcon from "@material-ui/icons/Reply";
import EmailModal from "../../../../components/EmailSender/EmailModal";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Poppins, sans-serif",
    height: "100%",
    display: "grid",
    maxHeight: "calc(100vh - 200px)",
    overflowY: "auto",
    ...theme.scrollbarStyles,
  },
  card: {
    fontFamily: "Poppins, sans-serif",
    overflowY: "auto",
    ...theme.scrollbarStyles,
  },
  messageContainer: {
    display: "block",
    marginBottom: "1rem",
    paddingBottom: "1rem",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  messageHeader: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    marginBottom: '1rem',
  },
  messageBody: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },
  messageInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "Poppins",
    fontWeight: "400",
    color: theme.mode === "light" ? "#9F9797" : "#FFFFFF",
  },
  replyButton: {
    marginLeft: 'auto',
    padding: 0,
  },
}));

const EmailDetail = ({ selectedEmail, setSelectedEmail, emailSettingId }) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentMessageId, setCurrentMessageId] = useState(null);
  const classes = useStyles();
  const theme = useTheme();

  if (!selectedEmail || !selectedEmail.messages) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardHeader
          style={{ borderBottom: `1px solid ${theme.palette.primary.neutralBorder}` }}
          avatar={
            <IconButton
              style={{
                borderRadius: "8px",
                display: "flex",
                width: "32px",
                height: "32px",
                minWidth: "32px",
                minHeight: "32px",
                alignItems: "center",
                textTransform: "none",
                background: theme.mode === "light" ? "#F6F3F3" : "#303030",
              }}
              onClick={() => setSelectedEmail(null)}
              aria-label="voltar"
            >
              <ArrowBackIcon fontSize="normal" />
            </IconButton>
          }
          title={selectedEmail.subject}
        />
        <CardContent style={{ color: "black !important", background: "white" }}>
          {selectedEmail.messages.map((message, index) => {
            const sanitizedBody = DOMPurify.sanitize(message.body);
            return (
              <div key={index} className={classes.messageContainer}>
                <div className={classes.messageHeader}>
                  <Avatar src={message.fromPhoto}>
                    {!message.fromPhoto && message.fromEmail.charAt(0).toUpperCase()}
                  </Avatar>
                  <div className={classes.messageBody}>
                    <span
                      style={{
                        display: "flex",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        color: theme.mode === "light" ? "#534D4D" : "#FFFFFF",
                      }}
                    >
                      {`${message.from.replace(/"/g, "")}`}
                    </span>
                    <div className={classes.messageInfo}>
                      <span style={{ display: "flex" }}>Para: Você</span>
                      <span style={{ display: "flex" }}>
                        {`${new Intl.DateTimeFormat("pt-BR", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        }).format(new Date(message.receivedAt))}`}
                      </span>
                    </div>
                  </div>
                  {/* Ícone de Resposta */}
                  <IconButton
                    className={classes.replyButton}
                    onClick={() => {
                      setOpenModal(true);
                      setCurrentMessageId(message.id);
                    }}
                    aria-label="Responder"
                  >
                    <ReplyIcon />
                  </IconButton>
                </div>
                <Typography
                  variant="body1"
                  component="div"
                  style={{ color: "black" }}
                  dangerouslySetInnerHTML={{ __html: sanitizedBody }}
                />
                {/* Modal de resposta com o messageId específico */}
                {openModal && currentMessageId === message.id && (
                  <EmailModal
                    type="response"
                    selectedEmail={selectedEmail}
                    open={openModal}
                    handleClose={() => setOpenModal(false)}
                    messageId={message.messageId}
                    emailSettingId={emailSettingId}
                  />
                )}
              </div>
            );
          })}
        </CardContent>
      </Card>
    </div>
  );
};

export default EmailDetail;
