import { daysTranslation } from "./constansts";
import api from "../../../../services/api";
import toastError from "../../../../errors/toastError";

const formatTime = (time) => `${time}:00`;

export const handleChange = (day, field, value, setSchedule) => {
    setSchedule((prev) => ({
        ...prev,
        [day]: { ...prev[day], [field]: value },
    }));
};

export const handleChangeAll = (setSchedule, newStartTime, newEndTime) => {
    setSchedule((prev) => {
        const updatedSchedule = { ...prev };
        Object.keys(updatedSchedule).forEach((day) => {
            updatedSchedule[day] = {
                inicio: newStartTime,
                fim: newEndTime,
            };
        });
        return updatedSchedule;
    });
};

export const handleSave = async (schedule, user, setIsTimeModalOpen) => {
    const formattedSchedule = Object.entries(schedule).reduce(
        (acc, [day, times]) => {
            const translatedDay = daysTranslation[day];
            acc[translatedDay] = {
                startTime: formatTime(times.inicio),
                endTime: formatTime(times.fim),
            };
            return acc;
        },
        {}
    );

    const payload = {
        userId: user?.id || null,
        schedules: formattedSchedule,
    };

    try {
        await api.post("/workHours", payload);
        setIsTimeModalOpen(false);
    } catch (error) {
        toastError("Erro ao salvar horários:", error);
    }
};
