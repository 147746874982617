import React, { useState, useRef, createContext } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const TicketsChannelContext = createContext();

const TicketsChannelContextProvider = ({ children }) => {
  const [channelType, setChannelType] = useState(null);
  const fetchPromises = useRef(new Map());
  const lastTicketId = useRef(null);

  const fetchChannelType = async (ticketId) => {
    if (!ticketId) return null;

    if (lastTicketId.current !== ticketId) {
      lastTicketId.current = ticketId;

      if (fetchPromises.current.has(ticketId)) {
        fetchPromises.current.get(ticketId).cancel && fetchPromises.current.get(ticketId).cancel();
      }

      const fetchPromise = api.get(`/tickets/${ticketId}`)
        .then(({ data }) => {
          setChannelType(data.whatsapp?.type);
          fetchPromises.current.delete(ticketId);
          return data.whatsapp?.type;
        })
        .catch((err) => {
          fetchPromises.current.delete(ticketId);
          toastError(err);
          return null;
        });

      fetchPromises.current.set(ticketId, fetchPromise);
      return fetchPromise;
    }

    return fetchPromises.current.get(ticketId);
  };

  return (
    <TicketsChannelContext.Provider
      value={{
        fetchChannelType,
        channelType,
        setChannelType,
      }}
    >
      {children}
    </TicketsChannelContext.Provider>
  );
};

export { TicketsChannelContext, TicketsChannelContextProvider };
