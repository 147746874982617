import React, { useContext, useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { IconButton, MenuItem } from "@material-ui/core";
import { Delete, TransferWithinAStation, Schedule, TransferWithinAStationOutlined, SyncAltOutlined } from "@material-ui/icons";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import TransferTicketModalCustom from "../TransferTicketModalCustom";
import ScheduleModal from "../ScheduleModal";
import toastError from "../../errors/toastError";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import Tooltip from '@material-ui/core/Tooltip';
import useSettings from "../../hooks/useSettings";

const useStyles = makeStyles(theme => ({
	iconButton: {
		color: theme.palette.primary.neutralIconColor,
		borderRadius: '4px',
		padding: "0",
		minWidth: "54px",
		display: "flex",
		justifyContent: "start"
	},
}));

const TicketOptionsMenu = ({ ticket, handleMenuClose = () => {} }) => {
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
	const [contactId, setContactId] = useState(null);
	const [deleteTicketButton, setDeleteTicketButton] = useState("enabled");
	const { user } = useContext(AuthContext);
	const isMounted = useRef(true);
	const theme = useTheme();
	const classes = useStyles(theme);
	const { getAll } = useSettings(); // Obtenha a função getAll do hook

	useEffect(() => {
		const fetchSettings = async () => {
			try {
				const settings = await getAll(); // Use getAll para buscar as configurações
				const deleteTicketButtonSetting = settings.find((s) => s.key === "deleteTicketButton");
				setDeleteTicketButton(deleteTicketButtonSetting ? deleteTicketButtonSetting.value : "enabled");
			} catch (error) {
				console.error("Erro ao buscar configurações:", error);
			}
		};

		fetchSettings();
	}, [getAll]);

	const handleDeleteTicket = async () => {
		try {
			await api.delete(`/tickets/${ticket.id}`);
		} catch (err) {
			toastError(err);
		}
	};

	const handleOpenConfirmationModal = () => {
		setConfirmationOpen(true);
	};

	const handleOpenTransferModal = () => {
		setTransferTicketModalOpen(true);
	};

	const handleCloseTransferTicketModal = () => {
		if (isMounted.current) {
			setTransferTicketModalOpen(false);
		}
	};

	const handleOpenScheduleModal = () => {
		setContactId(ticket.contact.id);
		setScheduleModalOpen(true);
	};

	const handleCloseScheduleModal = () => {
		setScheduleModalOpen(false);
		setContactId(null);
	};

	return (
		<div style={{ display: "flex", flexDirection: "column" }}>
			{/* Botão para agendar */}
			<MenuItem onClick={handleOpenScheduleModal} style={{ width: "100%" }}>
				<Tooltip title={i18n.t("ticketOptionsMenu.schedule")}>
					<IconButton className={classes.iconButton} >
						<Schedule />
					</IconButton>
				</Tooltip>
				<span style={{
					fontFamily: "Poppins",
					color: theme.mode === "light" ? "rgb(58, 56, 56)": "#FFFFFF",
					fontSize: "12px"
				}}> Agendamento</span>
			</MenuItem >

			<MenuItem onClick={handleOpenTransferModal} style={{ width: "100%" }}>
				<Tooltip title={i18n.t("ticketOptionsMenu.transfer")}>
					<IconButton className={classes.iconButton} >
						<SyncAltOutlined/>
					</IconButton>
				</Tooltip>
				<span style={{
					fontFamily: "Poppins",
					color: theme.mode === "light" ? "rgb(58, 56, 56)": "#FFFFFF",

					fontSize: "12px"
				}}> Transferir</span>
			</MenuItem >
			{/* Botão para transferir */}


			<ConfirmationModal
				title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}${ticket.id} ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${ticket?.contact?.name}`}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteTicket}
			>
				{i18n.t("ticketOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>

			<TransferTicketModalCustom
				modalOpen={transferTicketModalOpen}
				onClose={handleCloseTransferTicketModal}
				ticketid={ticket.id}
				ticket={ticket}
			/>

			<ScheduleModal
				open={scheduleModalOpen}
				onClose={handleCloseScheduleModal}
				aria-labelledby="form-dialog-title"
				contactId={contactId}
			/>
		</div>
	);
};

export default TicketOptionsMenu;
