import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    appBar: {
        background: "transparent",
        display: "flex",
        borderRadius: "8px 8px 0 0",
        padding: "1rem",
        justifyContent: "space-between",
        boxShadow: "none",
        borderBottom: `1px solid ${theme.palette.primary.neutralBorder}`,
        background: theme.mode === "light" ? "white" : "#2C2B2B",
    },
    title: {
        flexGrow: 1,
    },
    contentContainer: {
        backgroundColor: theme.mode === "light" ? "white" : "#171717",
        marginTop: theme.spacing(2),
    },
    quillEditor: {
        height: "100px",
        margin: "1rem 0",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
    newEmailButton: {
        background: `${theme.palette.primary.main}70`,
        borderRadius: "8px",
        display: "flex",
        width: "32px",
        height: "32px",
        minWidth: "32px",
        minHeight: "32px",
        alignItems: "center",
        gap: theme.spacing(1),
        color: theme.palette.primary.main,
        textTransform: "none",
        "&:hover": {
            color: theme.palette.primary.main,
            background: `${theme.palette.primary.main}70`,
        }
    },
    reloadButton: {
        minWidth: "30px",
        minHeight: "30px",
        padding: "0",
        background: "transparent",
        boxShadow: "none",
        "&:hover": {
            background: "transparent",
            boxShadow: "none",
        },
        "& img": {
            filter: theme.mode !== "light" ? "brightness(0) invert(1)" : "none",
        }
    },
    deleteButton: {
        minWidth: "30px",
        minHeight: "30px",
        padding: "0",
        boxShadow: "none",
        backgroundColor: "transparent !important",
        "&:hover": {
            backgroundColor: "transparent !important",
            boxShadow: "none",
        },
        "&.Mui-disabled": {
            boxShadow: "none",
            opacity: 0.3,
            "& img": {
                filter: "grayscale(100%) brightness(0%)",
                opacity: 0.3,
            },
        },
        "& img": {
            filter: theme.mode !== "light" ? "brightness(0) invert(1)" : "none",
        }
    },
    input: {
        fontFamily: "Poppins",
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #B0B0B0',
        },
        '& .MuiInput-underline:hover:before': {
            borderBottom: '1px solid #A0A0A0',
        },
    },
}));

export default useStyles;