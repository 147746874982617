import { randomBytes, createCipheriv } from "crypto";

const secretKey = process.env.REACT_APP_SECRET_KEY || "";
const key = Buffer.from(secretKey.padEnd(32, ' ').slice(0, 32), 'utf8');

function encryptPassword(password) {
    const iv = randomBytes(16);

    const cipher = createCipheriv("aes-256-ctr", key, iv);
    let encrypted = cipher.update(password, "utf8", "hex");
    encrypted += cipher.final("hex");

    return `${iv.toString("hex")}:${encrypted}`;
}

export default encryptPassword;
