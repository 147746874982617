import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    mainPaper: {
        background: "transparent",
        boxShadow: "none",
        display: 'flex',
        flexWrap: 'wrap',
        gap: "1rem",
    },
    card: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        border: "1px solid #ddd",
        width: "calc((100% / 3) - 1rem)",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    },
    cardBox: {
        display: "flex",
        width: "100%",
        gap: "1rem",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexGrow: 1,
        position: "relative"
    },
    iconTitleBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    statusIcon: {
        marginLeft: "10px",
    },
    centerText: {
        textAlign: "left",
    },
    actionButtons: {
        display: "flex",
        gap: "1rem",
        justifyContent: "flex-end",
        position: "absolute",
        top: "1rem",
        right: "1rem"
    },
    cardTitleStyles: {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "bold",
        color: theme.palette.primary.mainReverseByWhite,
    },
    addButton: {
        color: theme.palette.primary.mainReverseByWhite,
        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        borderRadius: "8px",
        background: "transparent",
        "&:hover": {
            background: "transparent",
        }
    },
}));

export default useStyles;