import React, { useState, useEffect, createContext } from "react";
import api from "../../services/api";

const EmailsContext = createContext();

const EmailsContextProvider = ({ children }) => {
    const [emailSettings, setEmailSettings] = useState([]);
    const [selectedSetting, setSelectedSetting] = useState(null);
    const [folders, setFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState("INBOX");
    const [emails, setEmails] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [loadingFolders, setLoadingFolders] = useState(false);
    const [selectedEmailId, setSelectedEmailId] = useState("");
    const pageSize = 30;
    const abortController = new AbortController();
    const [selectAll, setSelectAll] = useState(false);

    const cancelRequests = () => {
        abortController.abort();
    };

    const reloadAll = async () => {
        setLoading(true);
        setLoadingFolders(true);
        setEmails([]);
        setFolders([])
        setCurrentPage(1)
        try {
            const responseSettings = await api.get("/email-settings", {
                signal: abortController.signal,
            });
            setEmailSettings(responseSettings.data);
        } catch (error) {
            console.error("Error reloading email settings:", error);
            setEmailSettings([]);
        }

        try {
            if (selectedSetting) {
                const responseFolders = await api.get(
                    `/emails/folders?emailSettingId=${selectedSetting.id}`,
                    { signal: abortController.signal }
                );
                setFolders(responseFolders.data.folders || []);
            }
        } catch (error) {
            console.error("Error reloading folders:", error);
            setFolders([]);
        }

        try {
            if (selectedSetting && selectedFolder) {
                const responseEmails = await api.get(
                    `/emails/receive?emailSettingId=${selectedSetting.id}&folder=${selectedFolder}&page=1&pageSize=${pageSize}`,
                    { signal: abortController.signal }
                );
                const newEmails = (responseEmails.data.emails.emails || []).map((email, index) => ({
                    ...email,
                    id: email.id || `${email.receivedAt}-${index}`,
                }));
                setEmails(newEmails);
            }
        } catch (error) {
            console.error("Error reloading emails:", error);
            setEmails([]);
        } finally {
            setLoading(false);
            setLoadingFolders(false);
        }
    };

    const reloadWithSearch = async (search) => {
        setLoading(true);
        setEmails([]);
        setCurrentPage(1);
        try {
            if (selectedSetting && selectedFolder) {
                const responseEmails = await api.get(
                    `/emails/receive?emailSettingId=${selectedSetting.id}&folder=${selectedFolder}&page=1&pageSize=${pageSize}&search=${search}`,
                    { signal: abortController.signal }
                );
                const newEmails = (responseEmails.data.emails.emails || []).map((email, index) => ({
                    ...email,
                    id: email.id || `${email.receivedAt}-${index}`,
                }));
                setEmails(newEmails);
            }
        } catch (error) {
            console.error("Error reloading emails with search:", error);
            setEmails([]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <EmailsContext.Provider
            value={{
                emailSettings,
                setEmailSettings,
                selectedSetting,
                setSelectedSetting,
                folders,
                setFolders,
                selectedFolder,
                setSelectedFolder,
                emails,
                setEmails,
                selectedEmail,
                setSelectedEmail,
                currentPage,
                setCurrentPage,
                loading,
                loadingFolders,
                pageSize,
                cancelRequests,
                setLoading,
                setLoadingFolders,
                reloadAll,
                selectedEmailId,
                setSelectedEmailId,
                hasMore,
                setHasMore,
                selectedEmails,
                setSelectedEmails,
                selectAll,
                setSelectAll,
                abortController,
                cancelRequests,
                reloadWithSearch,
                searchTerm,
                setSearchTerm
            }}
        >
            {children}
        </EmailsContext.Provider>
    );
};

export { EmailsContext, EmailsContextProvider };
