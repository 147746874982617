import React, { useState } from "react";
import SocialCard from "../../../../components/SocialCard";
import baileyIcon from "../../../../assets/baileys-icon.png";
import FacebookInstagramIcon from "../../../../components/FacebookInstagramIcon";
import EmailSettingsModal from "../../../../components/EmailSettingsModal";
import { GoogleOAuthProvider } from "@react-oauth/google";

const ChannelsCards = ({
    classes,
    availableChannels,
    user,
    setWhatsAppModalOpen,
    setModalType,
    handleOpenFacebookAppModal,
    handleOpenInstagramAppModal,
    handleOpenWhatsAppModal,
    handleOpenWhatsAppModalOfficial,
    setEmailSettings
}) => {
    const [isEmailModalOpen, setEmailModalOpen] = useState(false); // Estado para abrir/fechar o modal de e-mail

    const handleOpenEmailModal = () => setEmailModalOpen(true); // Função para abrir o modal
    const handleCloseEmailModal = () => setEmailModalOpen(false);
    return (
        <div className={`${classes.cardBox} cmp-cardbox`}>

            <SocialCard
                user={user}
                title="WhatsApp"
                subtitle="Oficial"
                incoming={false}
                iconSrc={"https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"}
                isDisabled={false}
                onButtonClick={() => handleOpenWhatsAppModalOfficial(setWhatsAppModalOpen, setModalType)}
                classes={classes}
            />

            <SocialCard
                user={user}
                title="Facebook"
                subtitle="Hub Not."
                iconSrc="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
                isDisabled={!availableChannels.some((channelObject) => channelObject.channel === "facebook")}
                onButtonClick={() => handleOpenFacebookAppModal(setWhatsAppModalOpen, setModalType)}
                classes={classes}
            />

            <SocialCard
                user={user}
                title="WhatsApp"
                subtitle="Baileys"
                iconSrc={baileyIcon}
                isDisabled={false}
                incoming={false}
                onButtonClick={() => handleOpenWhatsAppModal(setWhatsAppModalOpen, setModalType)}
                classes={classes}
            />

            <SocialCard
                user={user}
                title="Instagram"
                incoming={false}
                subtitle="Hub Not."
                iconSrc="https://t.ctcdn.com.br/OOPbTa2T8_Y7SIFKQ8r1GU0URnU=/1080x1080/smart/i489927.jpeg"
                isDisabled={!availableChannels.some((channelObject) => channelObject.channel === "instagram")}
                onButtonClick={() => handleOpenInstagramAppModal(setWhatsAppModalOpen, setModalType)}
                classes={classes}
            />

            <SocialCard
                user={user}
                title="Mercado Livre"
                subtitle="Oficial"
                incoming={true}
                iconSrc="https://ehoradeaprender.com/wp-content/uploads/2023/01/unnamed.png"
                isDisabled={!availableChannels.some((channelObject) => channelObject.channel === "mercadolivre")}
                onButtonClick={() => { }}
                classes={classes}
            />

            <SocialCard
                user={user}
                title="LinkedIn"
                subtitle="Em Breve"
                incoming={true}
                iconSrc="https://img.icons8.com/?size=512&id=13930&format=png"
                isDisabled={true}
                onButtonClick={() => { }}
                classes={classes}
            />

            <SocialCard
                user={user}
                title="Webchat"
                subtitle="Em Breve"
                incoming={true}
                iconSrc="https://w7.pngwing.com/pngs/955/412/png-transparent-social-media-wechat-computer-icons-chat-leaf-text-smiley-thumbnail.png"
                isDisabled={true}
                onButtonClick={() => { }}
                classes={classes}
            />

            <SocialCard
                user={user}
                title="Google Business"
                subtitle="Em Breve"
                incoming={true}
                iconSrc="https://cdn-icons-png.flaticon.com/512/2875/2875428.png"
                isDisabled={true}
                onButtonClick={() => { }}
                classes={classes}
            />

            <SocialCard
                user={user}
                title="E-mail"
                subtitle="Oficial"
                incoming={false}
                iconSrc="https://cdn-icons-png.freepik.com/512/2250/2250206.png"
                isDisabled={false}
                onButtonClick={handleOpenEmailModal}
                classes={classes}
            />

            {/* <SocialCard
                user={user}
                title="Facebook"
                subtitle="Oficial"
                incoming={false}
                iconSrc="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
                isDisabled={false}
                onButtonClick={() => handleOpenFacebookModalOfficial(setWhatsAppModalOpen, setModalType)}
                classes={classes}
            /> */}

            {/* <SocialCard
                user={user}
                title="Instagram"
                subtitle="Oficial"
                incoming={false}
                iconComponent={<div
                    style={{
                        background: "linear-gradient(45deg, #feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5)",
                        borderRadius: "8px",
                        padding: "2px",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Instagram style={{ fontSize: "24px", color: "white" }} />
                </div>}
                isDisabled={false}
                onButtonClick={() => handleOpenInstagramModalOfficial(setWhatsAppModalOpen, setModalType)}
                classes={classes}
            /> */}
            {/* Modal de E-mail */}
            <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
                {isEmailModalOpen && (
                    <EmailSettingsModal isOpen={isEmailModalOpen} onClose={handleCloseEmailModal} setEmailSettings={setEmailSettings} />
                )}
            </GoogleOAuthProvider>
        </div>
    );
};

export default ChannelsCards;
