import React, { useState, useEffect } from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    Chip,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTheme } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import api from "../../services/api";
import useStyles from "./style";
import { FaPlus, FaPaperclip } from "react-icons/fa";
import { EmailsContext } from "../../context/EmailContext";

const EmailModal = ({ open, handleClose, emailSettingId, type, selectedEmail = {}, messageId }) => {
    const classes = useStyles();
    const [editorValue, setEditorValue] = useState("");
    const [to, setTo] = useState([]);
    const [subject, setSubject] = useState("");
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [attachments, setAttachments] = useState([]);
    useEffect(() => {
        if (type === "response" && selectedEmail) {
            setTo([selectedEmail.fromEmail]);
            setSubject(`Re: ${selectedEmail.subject}`);
            setEditorValue(``);
        }
    }, [type, selectedEmail]);

    const handleChange = (value) => {
        setEditorValue(value);
    };

    const handleAddEmail = () => {
        if (inputValue.trim()) {
            setTo([...to, inputValue.trim()]);
            setInputValue("");
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleAddEmail();
        }
    };

    const handleDeleteEmail = (emailToDelete) => {
        setTo(to.filter(email => email !== emailToDelete));
    };

    const handleFileChange = (event) => {
        setAttachments([...attachments, ...Array.from(event.target.files)]);
    };

    const handleSendEmail = async () => {
        if (to.length === 0 || !subject || !editorValue) {
            toast.error("Os campos 'Para', 'Assunto' e 'Corpo' são obrigatórios.");
            return;
        }
    
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("emailSettingId", emailSettingId);
            formData.append("to", to.join(","));
            formData.append("subject", subject);
            formData.append("html", editorValue);
            
            if (type === "response") {
                formData.append("messageId", messageId);
            }
    
            attachments.forEach((file) => {
                formData.append("attachment", file);
            });
    
            let url = "/emails/send";
            if (type === "response") {
                url = "/emails/reply";
            }
    
            const response = await api.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            toast.success("E-mail enviado com sucesso:", response.data);
        } catch (error) {
            toast.error("Erro ao enviar e-mail:", error);
        } finally {
            setTo([]);
            setSubject("");
            setEditorValue("");
            setAttachments([]);
            setLoading(false);
            handleClose();
        }
    };
    
    const theme = useTheme();

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <h4 style={{ fontSize: "16px", fontFamily: "Poppins", fontWeight: "400", padding: "1rem", borderBottom: `1px solid ${theme.palette.primary.neutralBorder}` }}>Enviar E-mail</h4>
            <DialogContent style={{ height: "400px" }}>
                <label style={{display: "flex", marginBottom: "0.5rem"}}>Para</label>
                <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", border: "1px solid #ccc", padding: " 0 1rem 0 0", borderRadius: "4px" }}>
                    {to.map((email, index) => (
                        <Chip
                            key={index}
                            label={email}
                            onDelete={() => handleDeleteEmail(email)}
                            style={{ margin: "2px" }}
                        />
                    ))}
                    <input
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder={to.length === 0 ? "Digite um e-mail" : ""}
                        style={{
                            border: "none",
                            outline: "none",
                            flex: 1,
                            padding: "0 1rem",
                            minHeight: "40px",
                            minWidth: "150px",
                        }}
                    />
                    <Button
                        onClick={handleAddEmail}
                        color="primary"
                        variant="contained"
                        style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "10px", padding: "0", width: "30px", height: "30px", minWidth: "30px", minHeight: "30px" }}
                    >
                        <FaPlus style={{color: "white"}} />
                    </Button>
                </div>
                    
                <label style={{margin: "1rem 0 0.5rem 0", display: "flex"}}>Assunto</label>
                <TextField
                    variant="outlined"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    fullWidth
                    size="small"
                    style={{
                        border: "none",
                        outline: "none",
                        minHeight: "40px",
                        minWidth: "150px",
                    }}
                />

                <label style={{margin: "1rem 0 0.5rem 0", display: "flex"}}>Corpo</label>
                <ReactQuill
                    value={editorValue}
                    onChange={handleChange}
                    modules={{
                        toolbar: [
                            [{ header: "1" }, { header: "2" }, { font: [] }],
                            [{ list: "ordered" }, { list: "bullet" }],
                            ["bold", "italic", "underline"],
                            [{ align: [] }],
                            ["link", "image"],
                            [{ color: [] }, { background: [] }],
                            ["clean"],
                        ],
                    }}
                    className={classes.quillEditor}
                />
            </DialogContent>
            {attachments.length > 0 && <div style={{padding: "0 1.4rem", fontFamily: "Poppins"}}>

                <label>Anexos:</label>
                <div style={{display: "flex", flexDirection: "column"}}>
                    {attachments.map((file, index) => (
                        <span style={{display: "block"}} key={index}>{file.name}</span>
                    ))}
                </div>
            </div>}
            <DialogActions style={{ padding: "1rem", borderTop: `1px solid ${theme.palette.primary.neutralBorder}` }}>
                <input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="file-upload"
                />
                <label htmlFor="file-upload">
                    <Button component="span" color="primary" variant="contained" style={{padding: "0.65rem", color: "white"}}>
                        <FaPaperclip />
                    </Button>
                </label>
                <Button
                    onClick={handleSendEmail}
                    color="primary"
                    disabled={loading}
                    style={{ background: theme.palette.primary.main, color: "white" }}
                >
                    {loading ? "Carregando..." : "Enviar"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EmailModal;
