import React, { useContext } from "react";
import { List, ListItem, ListItemText, CircularProgress } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import inboxIcon from "../../../../assets/emailsIcons/inbox.svg";
import startIcon from "../../../../assets/emailsIcons/estrela.svg";
import sendIcon from "../../../../assets/emailsIcons/email enviado.svg";
import draftIcon from "../../../../assets/emailsIcons/rascunho.svg";
import allIcon from "../../../../assets/emailsIcons/todos.svg";
import spamIcon from "../../../../assets/emailsIcons/spam.svg";
import deleteIcon from "../../../../assets/emailsIcons/lixeira.svg";
import importantIcon from "../../../../assets/emailsIcons/Important.svg";
import { EmailsContext } from "../../../../context/EmailContext";

const folderIcons = {
    INBOX: <img src={inboxIcon} alt="card letter icon" />,
    "Com estrela": <img src={startIcon} alt="estrela" />,
    "E-mails enviados": <img src={sendIcon} alt="seta" />,
    Rascunhos: <img src={draftIcon} alt="pagina com rascunhos" />,
    "Todos os e-mails": <img src={allIcon} alt="card letter icon" />,
    Spam: <img src={spamIcon} alt="card letter icon" />,
    Lixeira: <img src={deleteIcon} alt="card letter icon" />,
    Importante: <img src={importantIcon} alt="card letter icon" />,
    "Sent": <img src={sendIcon} alt="card letter icon" />,
    "Drafts": <img src={draftIcon} alt="card letter icon" />,
    "Archive": <img src={allIcon} alt="card letter icon" />,
    "spam": <img src={spamIcon} alt="card letter icon" />,
    "Trash": <img src={deleteIcon} alt="card letter icon" />,
};

const names = {
    "INBOX": "Caixa de entrada",
    "Sent": "E-mails enviados",
    "Drafts": "Rascunhos",
    "Archive": "Todos",
    "spam": "Spam",
    "Trash": "Lixeira",
}

const FolderList = ({ loadingFolders, folders, setSelectedFolder, selectedFolder, theme, loading, setSelectedEmail, }) => {
    const { setHasMore, setCurrentPage } = useContext(EmailsContext);
    const isDarkMode = theme.mode !== "light";

    return (
        <List>
            {(loadingFolders) ? (
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                </div>
            ) : (
                folders.map((folder) => (
                    <ListItem
                        key={folder.path}
                        button
                        onClick={() => {
                            setSelectedFolder(null);
                            setHasMore(true);
                            setCurrentPage(1);
                            setSelectedFolder(folder.path);
                            setSelectedEmail(null);
                        }}
                        style={{
                            fontFamily: "Poppins, sans-serif",
                            color: theme.palette.primary.neutralIcon,
                        }}
                        disabled={loading}
                    >
                        <span style={{ marginRight: "8px", fontFamily: "Poppins, sans-serif", color: theme.palette.primary.neutralIcon, filter: isDarkMode ? 'brightness(0) invert(1)' : 'unset' }}>
                            {loadingFolders ? (
                                <Skeleton variant="circle" width={24} height={24} />
                            ) : (
                                folderIcons[folder.name] || <img src={allIcon} alt="card letter icon" />
                            )}
                        </span>
                        <ListItemText
                            primary={
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span
                                        style={{
                                            fontFamily: "Poppins, sans-serif",
                                            fontWeight: selectedFolder === folder.path ? "600" : "normal",
                                            fontSize: "12px",
                                            color: theme.palette.primary.neutralIcon,
                                        }}
                                    >
                                        {names[folder?.name] ? names[folder?.name] : folder.name}
                                    </span>
                                    <span
                                        style={{
                                            color: theme.palette.primary.neutralIcon,
                                            marginLeft: "8px",
                                            fontFamily: "Poppins, sans-serif",
                                            fontWeight: "400",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {loadingFolders ? <Skeleton width={50} /> : folder.unreadMessages || 0}
                                    </span>
                                </div>
                            }
                            style={{ fontWeight: selectedFolder === folder.path ? "bold" : "normal" }}
                        />
                    </ListItem>
                ))
            )}
        </List>
    );
};

export default FolderList;
