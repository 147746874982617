import React, { useContext, useRef, useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip, TextField, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@material-ui/core";
import { EmailsListContext } from "../../../context/EmailsListContext";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./style";
import { ArrowBack, CloudUploadOutlined, Edit, Delete } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import api from "../../../services/api";
import { toast } from "react-toastify";
import toastError from "../../../errors/toastError";

export default function EmailListsPage({emailsListDetailId}) {
    const {
        activeTab,
        handleUpload,
        setActiveTab,
        emailListDetails,
        fetchEmailListDetails,
        currentPage,
        totalPages,
        loading,
        setEmailListDetails
    } = useContext(EmailsListContext);

    const [searchTerm, setSearchTerm] = useState("");
    const inputFileRef = useRef(null);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [editedEmail, setEditedEmail] = useState("");

    const typingTimeout = useRef(null);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        if (typingTimeout.current) {
            clearTimeout(typingTimeout.current);
        }

        typingTimeout.current = setTimeout(() => {
            fetchEmailListDetails(activeTab, 1, value);
        }, 3000);
    };

    const handleBackClick = () => {
        setActiveTab(null);
    };

    const handlePageChange = (event, page) => {
        fetchEmailListDetails(activeTab, page, searchTerm);
    };

    const handleEditClick = (email) => {
        setSelectedEmail(email);
        setEditedEmail(email.email); // Preenche o campo de edição com o valor atual
        setOpenEditModal(true);
    };

    const handleDeleteClick = (email) => {
        setSelectedEmail(email);
        setOpenDeleteModal(true);
    };

    const handleEditSubmit = async () => {
        try {
            await api.put(`/email-lists/${activeTab}/emails/${selectedEmail.id}`, { email: editedEmail });
            toast.success("Email editado!")
            setOpenEditModal(false);
            setEmailListDetails(prevState => prevState.map((item) => item.id === selectedEmail.id ? {...item, email: editedEmail} : item));
        } catch {
            toastError("Algo deu errado, tente novamente em alguns segundos");
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            await api.delete(`/email-lists/${activeTab}/emails/${selectedEmail.id}`);
            setEmailListDetails(prevState => prevState.filter((item) => item.id !== selectedEmail.id));
            toast.success("Email deletado!")
    
            setOpenDeleteModal(false);
        } catch {
            toastError("Algo deu errado, tente novamente em alguns segundos");
        }
        
    };

    const filteredEmails = emailListDetails.filter(emailDetail => 
        emailDetail.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const classes = useStyles();
    const theme = useTheme();

    return (
        <>
            {activeTab && emailListDetails && (
                <div>
                    <div style={{display: "flex", alignItems: "center", gap: "0.5rem", marginBottom: "1rem", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                            <IconButton variant="outlined" onClick={handleBackClick}>
                                <ArrowBack />
                            </IconButton>
                            <h2 style={{ fontFamily: "Poppins", fontWeight: "400", fontSize: "16px", color: theme.palette.primary.neutralTextColor }}>
                                Detalhes da Lista: {emailListDetails.name}
                            </h2>
                        </div>

                        <div className="mt-4">
                            <input
                                type="file"
                                ref={inputFileRef}
                                style={{ display: "none" }}
                                onChange={(e) => {
                                    handleUpload(emailListDetails.id, e.target.files[0]);
                                }}
                            />
                            <Tooltip title="Fazer upload">
                                <IconButton
                                    variant="contained"
                                    color="primary"
                                    onClick={() => inputFileRef.current.click()}
                                >
                                    <CloudUploadOutlined />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>

                    <TextField
                        label="Pesquisar Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />

                    {/* Carregamento */}
                    {loading ? (
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <TableContainer component={Paper} className={classes.emailsListStyles} style={{ marginTop: "2rem", height: "calc(100vh - 335px)" }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" style={{fontFamily: "Poppins"}}>Email</TableCell>
                                        <TableCell align="left" style={{fontFamily: "Poppins"}}>Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredEmails.map((emailDetail, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="left" style={{fontFamily: "Poppins"}}>{emailDetail.email}</TableCell>
                                            <TableCell align="left">
                                                <IconButton onClick={() => handleEditClick(emailDetail)}>
                                                    <Edit />
                                                </IconButton>
                                                <IconButton onClick={() => handleDeleteClick(emailDetail)}>
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}

                    <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                        <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} color="primary" />
                    </div>
                </div>
            )}

            {/* Modal de Edição */}
            <Dialog open={openEditModal} onClose={() => setOpenEditModal(false)}>
                <DialogTitle>Editar Email</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={editedEmail}
                        onChange={(e) => setEditedEmail(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditModal(false)} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={handleEditSubmit} color="primary">
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Modal de Confirmação de Exclusão */}
            <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                <DialogTitle>Confirmar Exclusão</DialogTitle>
                <DialogContent>
                    <p>Tem certeza de que deseja excluir este email?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteModal(false)} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
