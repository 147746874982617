import React, { useContext } from "react";

import {
    Button,
    Grid,
    useMediaQuery,
} from "@material-ui/core";
import CategoryItem from "./CategoryItem";
import { AuthContext } from "../../../../context/Auth/AuthContext";

const ChatNavigation = ({
    classes,
    categories,
    setShowCategorieDialog,
    setDialogType,
    setPickedCategorie, 
    setShowDialog,
    setDeleteModalOpen,
    loading,
    setMobileChat,
    setLoading,
    selectChat,
    deleteChat,
    chatsPageInfo,
}) => {
    const isMobile = useMediaQuery("(max-width: 900px)");
    const { user } = useContext(AuthContext);
    return (
        <Grid className={classes.gridItem} style={{ maxWidth: isMobile ? "100%" : "330px" }}>
            <div className={classes.btnContainer}>
                <h3>Agrupamentos</h3>
                {user?.profile === "admin" && <Button
                    onClick={() => {
                        setShowCategorieDialog(true);
                    }}
                    color="primary"
                    className={classes.btnStyles}
                    variant="contained">
                    +
                </Button>}
            </div>
            <div>
                {categories.map((categorie, index) => (
                    <CategoryItem
                        classes={classes}
                        key={`category_${index}`}
                        categorie={categorie}
                        setDialogType={setDialogType}
                        setPickedCategorie={setPickedCategorie}
                        setShowDialog={setShowDialog}
                        setDeleteModalOpen={setDeleteModalOpen}
                        loading={loading}
                        setMobileChat={setMobileChat}
                        setLoading={setLoading}
                        selectChat={selectChat}
                        deleteChat={deleteChat}
                        chatsPageInfo={chatsPageInfo}
                    />
                ))}
            </div>

        </Grid>
    );
};

export default ChatNavigation;
