import React, { useState, useEffect, createContext, useRef } from "react";
import JsSIP from 'jssip';

const PabxContext = createContext();

const PabxContextProvider = ({ children }) => {
    const [phone, setPhone] = useState(null);
    const [session, setSession] = useState(null);
    const [isCallActive, setIsCallActive] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [incomingCall, setIncomingCall] = useState(null);
    const [callerInfo, setCallerInfo] = useState("");
    const [isMuted, setIsMuted] = useState(false);
    const audioElementRef = useRef(null);
    const ringtoneRef = useRef(null);
    const playSound = (soundFile) => {
        const audio = new Audio(`/sounds/${soundFile}`);
        audio.play();
    };


    return (
        <PabxContext.Provider
            value={{
                phone, setPhone,
                session, setSession,
                isCallActive, setIsCallActive,
                phoneNumber, setPhoneNumber,
                incomingCall, setIncomingCall,
                callerInfo, setCallerInfo,
                isMuted, setIsMuted,
                audioElementRef,
                ringtoneRef, playSound

            }}
        >
            {children}
        </PabxContext.Provider>
    );
};

export { PabxContext, PabxContextProvider };
