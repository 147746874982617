import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";  // Importando o ícone de calendário
import api from "../../services/api";
import toastError from "../../errors/toastError";
import useStyles from "./style";
import { daysTranslation, scheduleInitialState } from "./WorkHoursModalComponents/utils/constansts";
import HoursTimeSelector from "../HoursTimeSelector";
import EveryHourUpdaterComponent from "../EveryHourUpdaterComponent";
import { handleChange, handleChangeAll, handleSave } from "./WorkHoursModalComponents/utils/functions";
import { toast } from "react-toastify";
import HolidaysCalendar from "../HolidaysCalendar";

const WorkHoursModal = ({ user, isTimeModalOpen, setIsTimeModalOpen }) => {
  const classes = useStyles();
  const [schedule, setSchedule] = useState(scheduleInitialState);
  const [newStartTime, setNewStartTime] = useState("09:00");
  const [newEndTime, setNewEndTime] = useState("19:00");
  const [openAll, setOpenAll] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [modalMessage, setModalMessage] = useState(""); // Estado para a mensagem

  useEffect(() => {
    const getUserSchedule = async () => {
      try {
        const { data } = await api.get("/workHours", {
          params: {
            userId: user?.id || null,
          },
        });

        if (Object.keys(data).length === 0) {
          setModalMessage("O usuário não tem nenhuma restrição de horário");
        } else {
          setSchedule((prev) => {
            const updatedSchedule = { ...prev };
            Object.entries(daysTranslation).forEach(([key, value]) => {
              if (data[value]) {
                updatedSchedule[key] = {
                  inicio: data[value].startTime.slice(0, 5),
                  fim: data[value].endTime.slice(0, 5),
                };
              }
            });
            return updatedSchedule;
          });
          setModalMessage(""); // Limpa a mensagem se houver dados
        }
      } catch (error) {
        setModalMessage("Erro ao buscar horários"); // Define a mensagem de erro
      }
    };

    getUserSchedule();
  }, []);

  useEffect(() => {
    const getHolidays = async () => {
      try {
        const { data } = await api.get("/holidays", {
          params: { userId: user?.id || null },
        });

        setSelectedDays((prev) => {
          const updatedSchedule = [...prev];
          data.forEach((holiday) => {
            const holidayDate = holiday.holidayDate;

            // Criar a data usando UTC para evitar problemas de fuso horário
            const holidayDay = new Date(holidayDate + "T00:00:00Z"); // Força a data a ser considerada no horário UTC

            const formattedDate = `${holidayDay.getUTCFullYear()}-${String(holidayDay.getUTCMonth() + 1).padStart(2, '0')}-${String(holidayDay.getUTCDate()).padStart(2, '0')}`;

            // Verifique se a data está no mês e ano atuais
            const currentYearMonth = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}`;
            if (formattedDate.startsWith(currentYearMonth)) {
              updatedSchedule.push(formattedDate);
            }
          });

          return updatedSchedule;
        });
      } catch (error) {
        toastError("Erro ao buscar feriados:", error);
      }
    };

    getHolidays();
  }, [currentMonth, currentYear, user]);


  const handleCancel = () => {
    setIsTimeModalOpen(false);
  };

  const handleSaveDays = async () => {
    try {
      await api.post("/holidays", {
        userId: user?.id || null,
        holidayDates: selectedDays,
      });

      toast.success("Dias salvos com sucesso!");
      setOpenCalendar(false);
    } catch (error) {
      toastError("Erro ao salvar os dias:", error);
    }
  };

  return (
    <Dialog
      open={isTimeModalOpen}
      onClose={handleCancel}
      maxWidth="sm"
      fullWidth
      classes={{ paper: classes.dialog }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle className={classes.dialogTitle}>
          Configurar Horários
        </DialogTitle>
        <Tooltip title="Feriados">
          <IconButton
            onClick={() => setOpenCalendar(true)} // Abre o calendário ao clicar no ícone
            color="primary"
            className={classes.calendarIcon}
          >
            <CalendarToday />
          </IconButton>
        </Tooltip>
      </div>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Poppins", fontWeight: "600"}}>
        {modalMessage && <p className={classes.modalMessage}>{modalMessage}</p>}
      </div>
      <DialogContent>
        <Button
          onClick={() => setOpenAll(!openAll)}
          className={classes.expandButton}
          fullWidth
        >
          {openAll ? "Fechar alterações em todos os horários" : "Alterar todos os horários"}
        </Button>

        <EveryHourUpdaterComponent
          openAll={openAll}
          classes={classes}
          newStartTime={newStartTime}
          setNewStartTime={setNewStartTime}
          newEndTime={newEndTime}
          setNewEndTime={setNewEndTime}
          handleChangeAll={handleChangeAll}
          setSchedule={setSchedule}
        />

        <HoursTimeSelector
          schedule={schedule}
          classes={classes}
          handleChange={handleChange}
          setSchedule={setSchedule}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSave(schedule, user, setIsTimeModalOpen)} className={classes.button}>
          Salvar
        </Button>
      </DialogActions>

      {openCalendar && (
        <Dialog classes={{ paper: classes.dialog }} open={openCalendar} onClose={() => setOpenCalendar(false)} maxWidth="sm" fullWidth>
          <DialogTitle className={classes.dialogTitle}>Selecione os Dias</DialogTitle>
          <DialogContent>
            <HolidaysCalendar
              currentMonth={currentMonth}
              currentYear={currentYear}
              selectedDays={selectedDays}
              setSelectedDays={setSelectedDays}
              setCurrentMonth={setCurrentMonth}
              setCurrentYear={setCurrentYear}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSaveDays} color="primary">Salvar</Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
};

export default WorkHoursModal;
