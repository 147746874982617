import React, { useEffect, useState } from "react"
import { BrowserRouter, Switch } from "react-router-dom"
import { ToastContainer } from "react-toastify"

import LoggedInLayout from "../layout"
import Dashboard from "../pages/Dashboard/"
import TicketResponsiveContainer from "../pages/TicketResponsiveContainer"
import Signup from "../pages/Signup/"
import Login from "../pages/Login/"
import Connections from "../pages/Connections/"
import SettingsCustom from "../pages/SettingsCustom/"
import Financeiro from "../pages/Financeiro/"
import Users from "../pages/Users"
import Contacts from "../pages/Contacts/"
import Queues from "../pages/Queues/"
import Tags from "../pages/Tags/"
import MessagesAPI from "../pages/MessagesAPI/"
import Helps from "../pages/Helps/"
import ContactLists from "../pages/ContactLists/"
import ContactListItems from "../pages/ContactListItems/"
// import Companies from "../pages/Companies/";
import QuickMessages from "../pages/QuickMessages/"
import Kanban from "../pages/Kanban"
import { AuthProvider } from "../context/Auth/AuthContext"
import { TicketsContextProvider } from "../context/Tickets/TicketsContext"
import { ForwardMessageProvider } from "../context/ForwarMessage/ForwardMessageContext"
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext"
import Route from "./Route"
import Schedules from "../pages/Schedules"
import Campaigns from "../pages/Campaigns"
import CampaignsConfig from "../pages/CampaignsConfig"
import CampaignReport from "../pages/CampaignReport"
import Annoucements from "../pages/Annoucements"
import Chat from "../pages/Chat"
import ToDoList from "../pages/ToDoList/"
import Subscription from "../pages/Subscription/"
import Files from "../pages/Files/"
import Prompts from "../pages/Prompts"
import QueueIntegration from "../pages/QueueIntegration"
import Reports from "../pages/Reports"
import PasswordReset from "../pages/PasswordReset"
import CreateGroupPage from "../pages/Grupos"
import Timeline from "../pages/Timeline"
import BlackList from "../pages/Blacklist"
import EmailInbox from "../pages/Emails"
import { EmailsContextProvider } from "../context/EmailContext"
import { TabulacaoContextProvider } from "../context/TabulacaoProvider"
import { QueueContextProvider } from "../context/QueueProvider"
import EmailListsPage from "../pages/EmailsLists"
import { EmailsListContextProvider } from "../context/EmailsListContext"
import EmailCampaigns from "../pages/EmailCampaigns"
import { PabxContextProvider } from "../context/PabxContext"
import { SettingsContextProvider } from "../context/SettingsContext"
import { ChatContextProvider } from "../context/ChatsContext"
import { TicketsChannelContextProvider } from "../context/TicketsChannelContext"
import { ContactsContextProvider } from "../context/ContactsContext"
import { CampaignContextProvider } from "../context/CampaignContext"
import { MessagesContextProvider } from "../context/MessagesContext"
import { GroupNotificationContextProvider } from "../context/GroupNotificationContext"

const Routes = () => {
  const [showCampaigns, setShowCampaigns] = useState(false)

  useEffect(() => {
    const cshow = localStorage.getItem("cshow")
    if (cshow !== undefined) {
      setShowCampaigns(true)
    }
  }, [])

  return (
    <BrowserRouter>
      <AuthProvider>
        <ForwardMessageProvider>
          <TicketsContextProvider>
            <QueueContextProvider>
              <TabulacaoContextProvider>
                <CampaignContextProvider>
                  <ChatContextProvider>
                    <TicketsChannelContextProvider>
                      <MessagesContextProvider>
                        <ContactsContextProvider>
                          <SettingsContextProvider>
                            <EmailsListContextProvider>
                              <PabxContextProvider>
                                <GroupNotificationContextProvider>
                                  <EmailsContextProvider>
                                    <Switch>
                                      <Route
                                        exact
                                        path="/login"
                                        component={Login}
                                      />
                                      <Route
                                        exact
                                        path="/signup"
                                        component={Signup}
                                      />
                                      <Route
                                        exact
                                        path="/recovery-password"
                                        component={PasswordReset}
                                      />
                                      {/* <Route exact path="/create-company" component={Companies} /> */}
                                      <WhatsAppsProvider>
                                        <LoggedInLayout>
                                          <Route
                                            exact
                                            path="/"
                                            component={Dashboard}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/tickets/:ticketId?"
                                            component={
                                              TicketResponsiveContainer
                                            }
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/connections"
                                            component={Connections}
                                            isPrivate
                                          />

                                          <Route
                                            exact
                                            path="/email-campaigns"
                                            component={EmailCampaigns}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/quick-messages"
                                            component={QuickMessages}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/emails"
                                            component={EmailInbox}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/todolist"
                                            component={ToDoList}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/schedules"
                                            component={Schedules}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/tags"
                                            component={Tags}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/contacts"
                                            component={Contacts}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/helps"
                                            component={Helps}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/users"
                                            component={Users}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/files"
                                            component={Files}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/prompts"
                                            component={Prompts}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/reports"
                                            component={Reports}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/groups"
                                            component={CreateGroupPage}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/queue-integration"
                                            component={QueueIntegration}
                                            isPrivate
                                          />

                                          <Route
                                            exact
                                            path="/messages-api"
                                            component={MessagesAPI}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/settings"
                                            component={SettingsCustom}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/kanban"
                                            component={Kanban}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/financeiro"
                                            component={Financeiro}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/queues"
                                            component={Queues}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/announcements"
                                            component={Annoucements}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/subscription"
                                            component={Subscription}
                                            isPrivate
                                          />
                                          <Route
                                            exact
                                            path="/chats/:id?"
                                            component={Chat}
                                            isPrivate
                                          />
                                          {showCampaigns && (
                                            <>
                                              <Route
                                                exact
                                                path="/contact-lists"
                                                component={ContactLists}
                                                isPrivate
                                              />
                                              <Route
                                                exact
                                                path="/emails-lists"
                                                component={EmailListsPage}
                                                isPrivate
                                              />
                                              <Route
                                                exact
                                                path="/contact-lists/:contactListId/contacts"
                                                component={ContactListItems}
                                                isPrivate
                                              />
                                              <Route
                                                exact
                                                path="/campaigns"
                                                component={Campaigns}
                                                isPrivate
                                              />

                                              <Route
                                                exact
                                                path="/blacklist"
                                                component={BlackList}
                                                isPrivate
                                              />
                                              <Route
                                                exact
                                                path="/campaign/:campaignId/report"
                                                component={CampaignReport}
                                                isPrivate
                                              />
                                              <Route
                                                exact
                                                path="/campaigns-config"
                                                component={CampaignsConfig}
                                                isPrivate
                                              />
                                              <Route
                                                exact
                                                path="/timeline"
                                                component={Timeline}
                                                isPrivate
                                              />
                                            </>
                                          )}
                                        </LoggedInLayout>
                                      </WhatsAppsProvider>
                                    </Switch>
                                  </EmailsContextProvider>
                                </GroupNotificationContextProvider>
                              </PabxContextProvider>
                            </EmailsListContextProvider>
                          </SettingsContextProvider>
                        </ContactsContextProvider>
                      </MessagesContextProvider>
                    </TicketsChannelContextProvider>
                  </ChatContextProvider>
                </CampaignContextProvider>
              </TabulacaoContextProvider>
            </QueueContextProvider>
            <ToastContainer autoClose={3000} />
          </TicketsContextProvider>
        </ForwardMessageProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default Routes
