import React, { useEffect, useState, useCallback, useRef } from "react";
import QRCode from "qrcode.react";
import toastError from "../../errors/toastError";
import { Dialog, DialogContent, Paper, Typography, useTheme } from "@material-ui/core";
import api from "../../services/api";
import { socketConnection } from "../../services/socket";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import "./style.css";
import { handleRequestNewQrCode } from "../../pages/Connections/handles";

const QrcodeModal = ({ open, onClose, whatsAppId, setUpdatedWhatsApps, setQrModalOpen, setSelectedWhatsApp }) => {
  const [qrCode, setQrCode] = useState("");
  const [closed, setClosed] = useState(false);
  const checkIntervalId = useRef(null);

  const onCloseHandler = useCallback(() => {
    onClose(setQrModalOpen, setSelectedWhatsApp);
  }, [onClose, setQrModalOpen, setSelectedWhatsApp]);

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`/whatsapp/${whatsAppId}`);
        setQrCode(data.qrcode);
      } catch (err) {
        toastError(err);
      }
    };
    handleRequestNewQrCode()
    fetchSession();
  }, [whatsAppId]);

  useEffect(() => {
    if (!whatsAppId) return;
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-whatsappSession`, (data) => {
      console.log("CONNECTIONS", data)
      if (data.action === "update" && data.session.id === whatsAppId) {
        setQrCode(data.session.qrcode);

        setUpdatedWhatsApps(prevWhatsApps =>
          prevWhatsApps.map(whatsApp =>
            whatsApp.id === whatsAppId
              ? { ...whatsApp, status: data.session.status, qrcode: data.session.qrcode }
              : whatsApp
          )
        );
      }

      if (data.action === "update" && data.session.qrcode === "") {
        onCloseHandler();
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [whatsAppId, onCloseHandler, setUpdatedWhatsApps]);

  useEffect(() => {
    if (!whatsAppId || closed) return;
    const checkWhatsAppSession = async () => {
      try {
        const response = await api.get(`/whatsapp/${whatsAppId}`);
        if (response.data.status === "CONNECTED") {
          setUpdatedWhatsApps(prevWhatsApps =>
            prevWhatsApps.map(whatsApp =>
              whatsApp.id === whatsAppId
                ? { ...whatsApp, status: "CONNECTED" }
                : whatsApp
            )
          );
          clearInterval(checkIntervalId.current);
          onCloseHandler();
        }
      } catch (err) {
        toastError(err);
      }
    };

    const intervalId = setInterval(checkWhatsAppSession, 20000);
    checkIntervalId.current = intervalId;

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      toastError("Não conseguimos estabelecer a conexão. Tente novamente.");
      onCloseHandler();
    }, 80000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
      if (!closed) {
        setClosed(true);
        checkWhatsAppSession();
      }
    };
  }, [whatsAppId, onCloseHandler, setUpdatedWhatsApps, closed]);

  const theme = useTheme();
  return (
    <Dialog className="cmp-qrcode" open={open} onClose={onCloseHandler} maxWidth="lg" scroll="paper">
      <DialogContent>
        <Paper elevation={0} style={{ textAlign: 'center', padding: '40px', borderRadius: '20px' }}>
          {qrCode ? (
            <>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
                <WhatsAppIcon style={{ color: '#25D366', backgroundColor: '#e5ffe5', borderRadius: '50%', padding: '10px', fontSize: '36px' }} />
                <Typography variant="h5" style={{ fontFamily: 'Poppins', fontWeight: 500, marginLeft: '10px', color: theme.palette.primary.mainReverseByWhite }}>
                  Escaneie o QR Code
                </Typography>
              </div>
              <QRCode value={qrCode} size={256} />
              <Typography style={{ fontFamily: 'Poppins', fontWeight: 500, marginTop: '20px', maxWidth: "500px", fontSize: "12px" }}>
                Para iniciar o processo de conexão, por favor, abra o aplicativo do WhatsApp em seu dispositivo móvel. Assim que o aplicativo estiver aberto, direcione a câmera do seu smartphone para o código QR exibido na tela.
              </Typography>
            </>
          ) : (
            <span style={{ fontFamily: 'Poppins', fontWeight: 500 }}>Aguardando QR Code</span>
          )}
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(QrcodeModal);
