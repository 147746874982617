import React, { useContext, useState, useEffect } from "react";
import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    InputAdornment,
    TextField,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import api from "../../services/api";
import { EmailsContext } from "../../context/EmailContext";
import editIcon from "../../assets/emailsIcons/cil_pen.svg";
import returnIcon from "../../assets/emailsIcons/material-symbols-light_refresh.svg";
import trashIcon from "../../assets/emailsIcons/trash.svg";
import useStyles from "./style";
import EmailModal from "./EmailModal";
import search from "../../assets/emailsIcons/search.svg"

const EmailSender = ({ emailSettingId, setCurrentPage, selectedEmail, openModal, setOpenModal }) => {
    const classes = useStyles();
    const { reloadAll, selectedEmails, searchTerm, setSearchTerm, reloadWithSearch, setSelectedEmails } = useContext(EmailsContext);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [typingTimeout, setTypingTimeout] = useState(null);

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setTypingTimeout(
            setTimeout(() => {
                reloadWithSearch(value);
            }, 2000)
        );
    };

    const handleDeleteEmails = async () => {
        try {
            const emailIds = selectedEmails.map(email => email.id);
            await api.delete("/emails/bulk-delete", {
                params: { emailIds: emailIds.join(","), emailSettingId }
            });
            toast.success("E-mails deletados com sucesso.");
            reloadAll();
            setConfirmDeleteOpen(false);
            setSelectedEmails([]);
        } catch (error) {
            toast.error("Erro ao deletar e-mails.");
        }
    };

    const theme = useTheme();

    return (
        <div>
            <AppBar position="static" className={classes.appBar}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                    <Button onClick={() => setOpenModal(true)} className={classes.newEmailButton}>
                        <img style={{ width: "20px" }} src={editIcon} alt="Pencil" />
                    </Button>
                    {!selectedEmail && <TextField
                        placeholder="Pesquisar"
                        variant="standard"
                        size="small"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        style={{ marginLeft: "1rem", width: "80%" }}
                        InputProps={{
                            disableUnderline: false,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={search} alt="Search Icon" />
                                </InputAdornment>
                            ),
                            classes: {
                                underline: classes.input,
                                fontFamily: "Poppins"
                            },
                        }}
                    />}
                    {!selectedEmail && (
                        <div>
                            <Button
                                className={classes.reloadButton}
                                style={{ borderRight: `1px solid ${theme.palette.primary.neutralBorder}`, paddingRight: "0.3rem" }}
                                onClick={() => {
                                    setCurrentPage(1);
                                    reloadAll();
                                }}
                            >
                                <img src={returnIcon} alt="Reload" />
                            </Button>
                            <Button
                                variant="contained"
                                disabled={selectedEmails.length === 0}
                                className={classes.deleteButton}
                                onClick={() => setConfirmDeleteOpen(true)}
                            >
                                <img src={trashIcon} style={{ width: "42px" }} alt="Delete" />
                            </Button>
                        </div>
                    )}
                </div>
            </AppBar>
            <EmailModal open={openModal} handleClose={() => setOpenModal(false)} emailSettingId={emailSettingId} />
            <Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
                <DialogContent>
                    Tem certeza que deseja deletar todos os e-mails selecionados?
                </DialogContent>
                <DialogActions>
                    <Button style={{ background: theme.palette.primary.main, color: "white" }} onClick={handleDeleteEmails}>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default EmailSender;
