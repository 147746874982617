import React, {
    useState,
    useEffect,
    useReducer,
    useRef,
    useContext,
  } from "react"
  
  import { isSameDay, parseISO, format } from "date-fns"
  import clsx from "clsx"
  
  import {
    Button,
    CircularProgress,
    IconButton,
    useTheme,
  } from "@material-ui/core"
  
  import {
    AccessTime,
    Block,
    Done,
    DoneAll,
    ExpandMore,
    GetApp,
    Reply,
    Description,
    PictureAsPdf,
    InsertDriveFile,
  } from "@material-ui/icons"
  
  import MarkdownWrapper from "../MarkdownWrapper"
  import ModalImageCors from "../ModalImageCors"
  import MessageOptionsMenu from "../MessageOptionsMenu"
  import LocationPreview from "../LocationPreview"
  
  import { ForwardMessageContext } from "../../context/ForwarMessage/ForwardMessageContext"
  import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext"
  
  import api from "../../services/api"
  import toastError from "../../errors/toastError"
  import { socketConnection } from "../../services/socket"
  import VcardPreview from "../VcardPreview"
  import SelectMessageCheckbox from "../MessagesList/SelectMessageCheckbox"
  import useStyles from "../MessagesList/style"
  
  const reducer = (state, action) => {
    if (action.type === "LOAD_MESSAGES") {
      const messages = action.payload
      const newMessages = []
  
      messages.forEach((message) => {
        const messageIndex = state.findIndex((m) => m.id === message.id)
        if (messageIndex !== -1) {
          state[messageIndex] = message
        } else {
          newMessages.push(message)
        }
      })
  
      return [...newMessages, ...state]
    }
  
    if (action.type === "ADD_MESSAGE") {
      const newMessage = action.payload
      const messageIndex = state.findIndex((m) => m.id === newMessage.id)
  
      if (messageIndex !== -1) {
        state[messageIndex] = newMessage
      } else {
        state.push(newMessage)
      }
  
      return [...state]
    }
  
    if (action.type === "UPDATE_MESSAGE") {
      const messageToUpdate = action.payload
      const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id)
  
      if (messageIndex !== -1) {
        state[messageIndex] = messageToUpdate
      }
  
      return [...state]
    }
  
    if (action.type === "RESET") {
      return []
    }
  }
  
  const MessagesList = ({
    ticket,
    ticketId,
    isGroup,
    user,
    markAsRead = true,
    isSpy = false,
    maxHeigth = false
  }) => {
    const classes = useStyles()
  
    const [messagesList, dispatch] = useReducer(reducer, [])
    const [pageNumber, setPageNumber] = useState(1)
    const [hasMore, setHasMore] = useState(false)
    const [loading, setLoading] = useState(false)
    const lastMessageRef = useRef()
  
    const [selectedMessage, setSelectedMessage] = useState({})
    const [anchorEl, setAnchorEl] = useState(null)
    const messageOptionsMenuOpen = Boolean(anchorEl)
    const currentTicketId = useRef(ticketId)
    const { setReplyingMessage } = useContext(ReplyMessageContext)
    const { showSelectMessageCheckbox } = useContext(ForwardMessageContext)
    const [currentAudio, setCurrentAudio] = useState(null)
    const [isAudioLoading, setIsAudioLoading] = useState(false)
    const messagesListRef = useRef(null);

    useEffect(() => {
      const list = messagesListRef?.current
      if (list) {
        list.addEventListener("scroll", handleScroll)
      }
  
      return () => {
        if (list) {
          list.removeEventListener("scroll", handleScroll)
        }
      }
    }, [messagesListRef])
  
    const handleAudioPlay = (audioElement) => {
      if (currentAudio && currentAudio !== audioElement) {
        currentAudio.pause()
      }
  
      setCurrentAudio(audioElement)
    }
  
    useEffect(() => {
      dispatch({ type: "RESET" })
      setPageNumber(1)
  
      currentTicketId.current = ticketId
    }, [ticketId])
  
    useEffect(() => {
      setLoading(true)
      const delayDebounceFn = setTimeout(() => {
        const fetchMessages = async () => {
          if (ticketId === undefined) return
          try {
            const { data } = await api.get("/messages/" + ticketId, {
              params: { pageNumber, markAsRead: !markAsRead },
            })
  
            if (currentTicketId.current === ticketId) {
              dispatch({ type: "LOAD_MESSAGES", payload: data.messages })
              setHasMore(data.hasMore)
              setLoading(false)
            }
  
            if (pageNumber === 1 && data.messages.length > 1) {
              scrollToBottom()
            }
          } catch (err) {
            setLoading(false)
            toastError(err)
          }
        }
        fetchMessages()
      }, 500)
      return () => {
        clearTimeout(delayDebounceFn)
      }
    }, [pageNumber, ticketId])
  
    useEffect(() => {
      if (user.id) {
        const companyId = localStorage.getItem("companyId")
        if(!isSpy) {
          const socket = socketConnection({ companyId })
          
          socket.on("connect", () => socket.emit("joinChatBox", `${ticket.id}`))
    
          socket.on(`company-${companyId}-appMessage`, (data) => {
            if (data.action === "create") {
              dispatch({ type: "ADD_MESSAGE", payload: data.message })
              scrollToBottom()
            }
    
            if (data.action === "update") {
              dispatch({ type: "UPDATE_MESSAGE", payload: data.message })
            }
          })
    
          return () => {
            socket.disconnect()
          }
        }
      }
    }, [ticketId, ticket, user])
  
    const loadMore = () => {
      setPageNumber((prevPageNumber) => prevPageNumber + 1)
    }
  
    const scrollToBottom = () => {
      if (lastMessageRef.current) {
        lastMessageRef.current.scrollIntoView({})
      }
    }
  
    const handleScroll = (e) => {
      if (!hasMore) return
      const { scrollTop } = e.currentTarget
  
      if (scrollTop === 0) {
        const listSpy = document.getElementById("messagesListSpy");
        if(listSpy) {
            document.getElementById("messagesListSpy").scrollTop = 1
        }
      }
  
      if (loading) {
        return
      }
  
      if (scrollTop < 50) {
        loadMore()
      }
    }
  
    const hanldeReplyMessage = (e, message) => {
      setAnchorEl(null)
      setReplyingMessage(message)
    }
  
    const handleOpenMessageOptionsMenu = (e, message) => {
      setAnchorEl(e.currentTarget)
      setSelectedMessage(message)
    }
  
    const handleCloseMessageOptionsMenu = (e) => {
      setAnchorEl(null)
    }
    const theme = useTheme()
  
    const checkMessageMedia = (message) => {
      if (
        message.mediaType === "contactMessage" ||
        message.mediaType === "contacts"
      ) {
        let array = message.body.split("\n")
        let obj = []
        let contact = ""
        for (let index = 0; index < array.length; index++) {
          const v = array[index]
          let values = v.split(":")
          for (let ind = 0; ind < values.length; ind++) {
            if (values[ind].indexOf("+") !== -1) {
              obj.push({ number: values[ind] })
            }
            if (values[ind].indexOf("FN") !== -1) {
              contact = values[ind + 1]
            }
          }
        }
        const mediaTypeFound = {
          contacts: "official",
          contactMessage: null,
        }
  
        return (
          <VcardPreview
            type={mediaTypeFound[message.mediaType]}
            contact={contact}
            numbers={obj[0]?.number}
            queueId={ticket?.queueId}
            whatsappId={ticket?.whatsappId}
          />
        )
      } else if (
        message.mediaType === "locationMessage" &&
        message.body.split("|").length >= 2
      ) {
        let locationParts = message.body.split("|")
        let imageLocation = locationParts[0]
        let linkLocation = locationParts[1]
  
        let descriptionLocation = null
  
        if (locationParts.length > 2)
          descriptionLocation = message.body.split("|")[2]
  
        return (
          <LocationPreview
            image={imageLocation}
            link={linkLocation}
            description={descriptionLocation}
          />
        )
      } else if (
      
        message.mediaType === "image" ||
        message.mediaType === "imageMessage"
      ) {
        return <ModalImageCors imageUrl={message?.mediaUrl} />
      } else if (message.mediaType === "audio") {
        return (
          <div className={classes.downloadMedia}>
            {isAudioLoading && (
              <div
                style={{
                  position: "absolute",
                  top: "1.4rem",
                  zIndex: "100",
                  left: "1rem",
                  background: "#F1F3F4",
                }}
              >
                <CircularProgress color="inherit" />
              </div>
            )}
            <audio
              controls
              onLoadedMetadata={(e) => {
                if (e.target.duration === 0) {
                  setIsAudioLoading(true)
                } else {
                  setIsAudioLoading(false)
                }
              }}
              onError={(e) => {
                setIsAudioLoading(true)
              }}
              onPlay={(e) => handleAudioPlay(e.target)}
            >
              <source src={message.mediaUrl} type="audio/ogg" />
            </audio>
          </div>
        )
      } else if (message.mediaType === "video") {
        return (
          <video
            className={classes.messageMedia}
            src={message.mediaUrl}
            controls
          />
        )
      } else {
        return (
          <>
            <div
              className={classes.fileMessageContainer}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "0.5rem",
                padding: "10px",
              }}
            >
              <div
                className={classes.fileIcon}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {message.body.toLowerCase().endsWith(".doc") ||
                message.body.toLowerCase().endsWith(".docx") ? (
                  <Description style={{ color: "#2B579A", fontSize: 40 }} /> // Ícone para Word
                ) : message.body.toLowerCase().endsWith(".pdf") ? (
                  <PictureAsPdf style={{ color: "#D9534F", fontSize: 40 }} /> // Ícone para PDF
                ) : (
                  <InsertDriveFile style={{ color: "#888", fontSize: 40 }} /> // Ícone genérico para outros tipos de arquivo
                )}
              </div>
  
              <div
                className={classes?.fileDetails}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div className={classes?.fileName}>{message?.body}</div>
                <div>
                  {(() => {
                    try {
                      const parsedData = message?.dataJson
                        ? JSON.parse(message.dataJson)
                        : null
                      const fileLength =
                        parsedData?.message?.documentMessage?.fileLength
  
                      if (fileLength) {
                        return `${(parseInt(fileLength, 10) / 1024).toFixed(
                          2
                        )} KB`
                      } else {
                        return "Tamanho não disponível"
                      }
                    } catch (error) {
                      console.error("Erro ao processar fileLength:", error)
                      return "Erro ao processar tamanho do arquivo"
                    }
                  })()}
                </div>
              </div>
  
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  border: `2px solid ${theme.palette.primary.main}`,
                  cursor: "pointer",
                }}
              >
                <IconButton
                  color="primary"
                  target="_blank"
                  href={message.mediaUrl}
                  style={{
                    borderRadius: "50%",
                  }}
                >
                  <GetApp />
                </IconButton>
              </div>
            </div>
          </>
        )
      }
    }
  
    const renderMessageAck = (message) => {
      if (message.ack === 0) {
        return <AccessTime fontSize="small" className={classes.ackIcons} />
      } else if (message.ack === 1) {
        return <Done fontSize="small" className={classes.ackIcons} />
      } else if (message.ack === 2) {
        return <DoneAll fontSize="small" className={classes.ackIcons} />
      } else if (message.ack === 3 || message.ack === 4) {
        return (
          <DoneAll
            fontSize="small"
            className={
              message.mediaType === "audio"
                ? classes.ackPlayedIcon
                : classes.ackDoneAllIcon
            }
          />
        )
      } else if (message.ack === 5) {
        return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />
      }
    }
  
    const renderDailyTimestamps = (message, index) => {
      if (index === 0) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        )
      }
      if (index < messagesList.length - 1) {
        let messageDay = parseISO(messagesList[index].createdAt)
        let previousMessageDay = parseISO(messagesList[index - 1].createdAt)
  
        if (!isSameDay(messageDay, previousMessageDay)) {
          return (
            <span
              className={classes.dailyTimestamp}
              key={`timestamp-${message.id}`}
            >
              <div className={classes.dailyTimestampText}>
                {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
              </div>
            </span>
          )
        }
      }
      if (index === messagesList.length - 1) {
        return (
          <div
            key={`ref-${message.createdAt}`}
            ref={lastMessageRef}
            style={{ float: "left", clear: "both" }}
          />
        )
      }
    }
  
    const renderNumberTicket = (message, index) => {
      if (index < messagesList.length && index > 0) {
        let messageTicket = message.ticketId
        let previousMessageTicket = messagesList[index - 1].ticketId
        if (messageTicket !== previousMessageTicket) {
          return (
            <center>
              <div className={classes.ticketNunberClosed}>
                Conversa encerrada:{" "}
                {format(
                  parseISO(messagesList[index - 1].createdAt),
                  "dd/MM/yyyy HH:mm:ss"
                )}
              </div>
  
              <div className={classes.ticketNunberOpen}>
                Conversa iniciada:{" "}
                {format(parseISO(message.createdAt), "dd/MM/yyyy HH:mm:ss")}
              </div>
            </center>
          )
        }
      }
    }
  
    const renderMessageDivider = (message, index) => {
      if (index < messagesList.length && index > 0) {
        let messageUser = messagesList[index].fromMe
        let previousMessageUser = messagesList[index - 1].fromMe
  
        if (messageUser !== previousMessageUser) {
          return (
            <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
          )
        }
      }
    }
  
    const renderQuotedMessage = (message) => {
      return (
        <div
          className={clsx(classes.quotedContainerLeft, {
            [classes.quotedContainerRight]: message.fromMe,
          })}
        >
          <span
            className={clsx(classes.quotedSideColorLeft, {
              [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
            })}
          ></span>
          <div className={classes.quotedMsg}>
            {!message.quotedMsg?.fromMe && (
              <span className={classes.messageContactName}>
                {message.quotedMsg?.contact?.name}
              </span>
            )}
  
            {message.quotedMsg.mediaType === "audio" && (
              <div className={classes.downloadMedia}>
                {!message.quotedMsg.mediaUrl ? (
                  <div>Carregando áudio...</div>
                ) : (
                  <>
                    <audio controls>
                      <source src={message.quotedMsg.mediaUrl} type="audio/ogg" />
                    </audio>
                  </>
                )}
              </div>
            )}
  
            {message.quotedMsg.mediaType === "video" && (
              <video
                className={classes.messageMedia}
                src={message.quotedMsg.mediaUrl}
                controls
              />
            )}
            {(message.quotedMsg.mediaType === "contactMessage" ||
              message.quotedMsg.mediaType === "contacts") &&
              "Contato"}
            {message.quotedMsg.mediaType === "application" && (
              <div className={classes.downloadMedia}>
                <Button
                  startIcon={<GetApp />}
                  color="primary"
                  variant="outlined"
                  target="_blank"
                  href={message.quotedMsg.mediaUrl}
                >
                  Download
                </Button>
              </div>
            )}
  
            {message.quotedMsg.mediaType === "image" ? (
              <ModalImageCors imageUrl={message.quotedMsg.mediaUrl} />
            ) : (
              message.quotedMsg?.body
            )}
          </div>
        </div>
      )
    }
  
    const renderMessages = () => {
      if (messagesList.length > 0) {
        const viewMessagesList = messagesList.map((message, index) => {
          if (message.mediaType === "call_log") {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderNumberTicket(message, index)}
                {renderMessageDivider(message, index)}
                <div className={classes.messageCenter}>
                  <IconButton
                    variant="contained"
                    size="small"
                    id="messageActionsButton"
                    disabled={message.isDeleted}
                    className={classes.messageActionsButton}
                    onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                  >
                    <ExpandMore />
                  </IconButton>
                  {isGroup && (
                    <span className={classes.messageContactName}>
                      {message.contact?.name}
                    </span>
                  )}
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 17"
                      width="20"
                      height="17"
                    >
                      <path
                        fill="#df3333"
                        d="M18.2 12.1c-1.5-1.8-5-2.7-8.2-2.7s-6.7 1-8.2 2.7c-.7.8-.3 2.3.2 2.8.2.2.3.3.5.3 1.4 0 3.6-.7 3.6-.7.5-.2.8-.5.8-1v-1.3c.7-1.2 5.4-1.2 6.4-.1l.1.1v1.3c0 .2.1.4.2.6.1.2.3.3.5.4 0 0 2.2.7 3.6.7.2 0 1.4-2 .5-3.1zM5.4 3.2l4.7 4.6 5.8-5.7-.9-.8L10.1 6 6.4 2.3h2.5V1H4.1v4.8h1.3V3.2z"
                      ></path>
                    </svg>{" "}
                    <span>
                      Chamada de voz/vídeo perdida às{" "}
                      {format(parseISO(message.createdAt), "HH:mm")}
                    </span>
                  </div>
                </div>
              </React.Fragment>
            )
          }
  
          if (!message.fromMe) {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderNumberTicket(message, index)}
                {renderMessageDivider(message, index)}
                <div
                  className={classes.messageLeft}
                  title={message.queueId && message.queue?.name}
                  onDoubleClick={(e) => hanldeReplyMessage(e, message)}
                >
                  {showSelectMessageCheckbox && (
                    <SelectMessageCheckbox
                      message={message}
                    />
                  )}
                  <IconButton
                    variant="contained"
                    size="small"
                    id="messageActionsButton"
                    disabled={message.isDeleted}
                    className={classes.messageActionsButton}
                    onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                  >
                    <ExpandMore />
                  </IconButton>
                  {message.isForwarded && (
                    <div>
                      <span className={classes.forwardMessage}>
                        <Reply
                          style={{ color: "grey", transform: "scaleX(-1)" }}
                        />{" "}
                        Encaminhada
                      </span>
                      <br />
                    </div>
                  )}
                  {isGroup && (
                    <span className={classes.messageContactName}>
                      {message.contact?.name}
                    </span>
                  )}
  
                  {message.isDeleted && (
                    <div>
                      <span className={"message-deleted"}>
                        Essa mensagem foi apagada pelo contato &nbsp;
                        <Block
                          color="error"
                          fontSize="small"
                          className={classes.deletedIcon}
                        />
                      </span>
                    </div>
                  )}
  
                  {(message.mediaUrl ||
                    message.mediaType === "locationMessage" ||
                    message.mediaType === "contactMessage" ||
                    message.mediaType === "contacts") &&
                    checkMessageMedia(message)}
                  <div
                    className={`${classes.textContentItem} cmp-text__max-width`}
                  >
                    {message.quotedMsg && renderQuotedMessage(message)}
                    <MarkdownWrapper>
                      {message.mediaType === "locationMessage" ||
                      message.mediaType === "contactMessage" ||
                      message.mediaType === "contacts"
                        ? null
                        : message.body}
                    </MarkdownWrapper>
                    <span className={classes.timestamp}>
                      {message.isEdited
                        ? "Editada " +
                          format(parseISO(message.createdAt), "HH:mm")
                        : format(parseISO(message.createdAt), "HH:mm")}
                    </span>
                  </div>
                </div>
              </React.Fragment>
            )
          } else {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderNumberTicket(message, index)}
                {renderMessageDivider(message, index)}
                <div
                  className={classes.messageRight}
                  onDoubleClick={(e) => hanldeReplyMessage(e, message)}
                >
                  {showSelectMessageCheckbox && (
                    <SelectMessageCheckbox
                      message={message}
                    />
                  )}
                  <IconButton
                    variant="contained"
                    size="small"
                    id="messageActionsButton"
                    disabled={message.isDeleted}
                    className={classes.messageActionsButton}
                    onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                  >
                    <ExpandMore />
                  </IconButton>
                  {message.isForwarded && (
                    <div>
                      <span className={classes.forwardMessage}>
                        <Reply
                          style={{ color: "grey", transform: "scaleX(-1)" }}
                        />{" "}
                        Encaminhada
                      </span>
                      <br />
                    </div>
                  )}
                  {(message.mediaUrl ||
                    message.mediaType === "locationMessage" ||
                    message.mediaType === "contactMessage" ||
                    message.mediaType === "contacts") &&
                    checkMessageMedia(message)}
                  <div
                    className={clsx(classes.textContentItem, {
                      [classes.textContentItemDeleted]: message.isDeleted,
                    })}
                  >
                    {message.isDeleted && (
                      <Block
                        color="disabled"
                        fontSize="small"
                        className={classes.deletedIcon}
                      />
                    )}
                    {message.quotedMsg &&
                      message.mediaType !== "contactMessage" &&
                      message.mediaType !== "contacts" &&
                      renderQuotedMessage(message)}
                    {!message?.mediaUrl &&
                      message.mediaType !== "contactMessage" &&
                      message.mediaType !== "contacts" && (
                        <MarkdownWrapper>{message.body}</MarkdownWrapper>
                      )}
                    <span className={classes.timestamp}>
                      {message.isEdited
                        ? "Editada " +
                          format(parseISO(message.createdAt), "HH:mm")
                        : format(parseISO(message.createdAt), "HH:mm")}
                      {renderMessageAck(message)}
                    </span>
                  </div>
                </div>
              </React.Fragment>
            )
          }
        })
        return viewMessagesList
      } else {
        return <div>Diga olá para seu novo contato!</div>
      }
    }
  
    return (
      <div style={{maxHeight: maxHeigth ? maxHeigth + "px" : ""}} className={classes.messagesListWrapper}>
        <MessageOptionsMenu
          message={selectedMessage}
          anchorEl={anchorEl}
          menuOpen={messageOptionsMenuOpen}
          handleClose={handleCloseMessageOptionsMenu}
        />
        <div
          id="messagesListSpyed"
          ref={messagesListRef}
          className={classes.messagesList}
          onScroll={handleScroll}
        >
          {messagesList.length > 0 ? renderMessages() : []}
        </div>
        {loading && (
          <div>
            <CircularProgress className={classes.circleLoading} />
          </div>
        )}
      </div>
    )
  }
  
  export default MessagesList