import { useState, useEffect, useRef } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useTickets = ({
  searchParam,
  tags,
  users,
  pageNumber,
  status,
  date,
  updatedAt,
  showAll,
  queueIds,
  withUnreadMessages,
  tab
}) => {
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [tickets, setTickets] = useState([]);
  const cache = useRef({});

  useEffect(() => {
    if (status === "closed" && tab !== "closed") return;
    
    const cacheKey = JSON.stringify({ searchParam, tags, users, pageNumber, status, date, updatedAt, showAll, queueIds, withUnreadMessages });
    if (cache.current[cacheKey]) {
      setTickets(cache.current[cacheKey].tickets);
      setHasMore(cache.current[cacheKey].hasMore);
      setLoading(false);
      return;
    }
    
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTickets = async () => {
        try {
          const { data } = await api.get("/tickets", {
            params: {
              searchParam,
              pageNumber,
              tags,
              users,
              status,
              date,
              updatedAt,
              showAll,
              queueIds,
              withUnreadMessages,
            },
          });
          setTickets(data.tickets);
          setHasMore(data.hasMore);
          cache.current[cacheKey] = { tickets: data.tickets, hasMore: data.hasMore };
        } catch (err) {
          toastError(err);
        } finally {
          setLoading(false);
        }
      };
      fetchTickets();
    }, 500);
    
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, tags, users, pageNumber, status, date, updatedAt, showAll, queueIds, withUnreadMessages, tab]);

  return { tickets, loading, hasMore };
};

export default useTickets;
