import { useEffect, useCallback } from "react";
import api from "../../../../services/api";
import toastError from "../../../../errors/toastError";

export const useWhatsAppProxyEffect = (
  whatsApp,
  setProxyHost,
  setProxyPort,
  setProxyUsername,
  setProxyPassword,
  setEnableLocalProxy
) => {
  const memoizedSetProxyHost = useCallback(setProxyHost, []);
  const memoizedSetProxyPort = useCallback(setProxyPort, []);
  const memoizedSetProxyUsername = useCallback(setProxyUsername, []);
  const memoizedSetProxyPassword = useCallback(setProxyPassword, []);
  const memoizedSetEnableLocalProxy = useCallback(setEnableLocalProxy, []);

  useEffect(() => {
    if (whatsApp.localProxy) {
      memoizedSetEnableLocalProxy(true);
      const { host, port, username, password } = whatsApp.localProxy;
      memoizedSetProxyHost(host || "");
      memoizedSetProxyPort(port || "");
      memoizedSetProxyUsername(username || "");
      memoizedSetProxyPassword(password || "");
    }
  }, [whatsApp, memoizedSetEnableLocalProxy, memoizedSetProxyHost, memoizedSetProxyPort, memoizedSetProxyUsername, memoizedSetProxyPassword]);
};

export const usePromptsEffect = (whatsAppId, setPrompts) => {
  const memoizedSetPrompts = useCallback(setPrompts, []);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/prompt");
        memoizedSetPrompts(data.prompts);
      } catch (err) {
        toastError(err);
      }
    })();
  }, [whatsAppId, memoizedSetPrompts]);
};

export const useFetchSessionEffect = (
  whatsAppId,
  setWhatsApp,
  setSelectedPrompt,
  setSelectedQueueIds,
  setEnableImportMessage,
  setImportOldMessages,
  setImportRecentMessages,
  setClosedTicketsPostImported,
  setImportOldMessagesGroups,
  fetchChannels
) => {
  const memoizedSetWhatsApp = useCallback(setWhatsApp, []);
  const memoizedSetSelectedPrompt = useCallback(setSelectedPrompt, []);
  const memoizedSetSelectedQueueIds = useCallback(setSelectedQueueIds, []);
  const memoizedSetEnableImportMessage = useCallback(setEnableImportMessage, []);
  const memoizedSetImportOldMessages = useCallback(setImportOldMessages, []);
  const memoizedSetImportRecentMessages = useCallback(setImportRecentMessages, []);
  const memoizedSetClosedTicketsPostImported = useCallback(setClosedTicketsPostImported, []);
  const memoizedSetImportOldMessagesGroups = useCallback(setImportOldMessagesGroups, []);
  const memoizedFetchChannels = useCallback(fetchChannels, []);

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`whatsapp/${whatsAppId}?session=0`);
        memoizedSetWhatsApp(data);
        data.promptId ? memoizedSetSelectedPrompt(data.promptId) : memoizedSetSelectedPrompt(null);
        const whatsQueueIds = data.queues?.map((queue) => queue.id);
        memoizedSetSelectedQueueIds(whatsQueueIds);
        if (data?.importOldMessages) {
          memoizedSetEnableImportMessage(true);
          memoizedSetImportOldMessages(data?.importOldMessages);
          memoizedSetImportRecentMessages(data?.importRecentMessages);
          memoizedSetClosedTicketsPostImported(data?.closedTicketsPostImported);
          memoizedSetImportOldMessagesGroups(data?.importOldMessagesGroups);
        }
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
    memoizedFetchChannels();
  }, [
    whatsAppId,
    memoizedSetWhatsApp,
    memoizedSetSelectedPrompt,
    memoizedSetSelectedQueueIds,
    memoizedSetEnableImportMessage,
    memoizedSetImportOldMessages,
    memoizedSetImportRecentMessages,
    memoizedSetClosedTicketsPostImported,
    memoizedSetImportOldMessagesGroups,
    memoizedFetchChannels
  ]);
};

export const useQueuesEffect = (setQueues) => {
  const memoizedSetQueues = useCallback(setQueues, []);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/queue");
        memoizedSetQueues(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, [memoizedSetQueues]);
};

export const useLocalStorageCallsEffect = (setIsCallsEnabled) => {
  const memoizedSetIsCallsEnabled = useCallback(setIsCallsEnabled, []);

  useEffect(() => {
    const callEnabled = localStorage.getItem("callEnabled") === "true";
    memoizedSetIsCallsEnabled(callEnabled);
  }, [memoizedSetIsCallsEnabled]);
};

export const useModelTypeEffect = (modelType, setWhatsApp, fetchChannels) => {
  const memoizedSetWhatsApp = useCallback(setWhatsApp, []);
  const memoizedFetchChannels = useCallback(fetchChannels, []);

  useEffect(() => {
    if (modelType !== "whatsapp") {
      memoizedFetchChannels();
    }
    if (modelType === "official") {
      memoizedSetWhatsApp((prevState) => ({ ...prevState, isOfficial: true }));
    } else {
      memoizedSetWhatsApp((prevState) => ({ ...prevState, isOfficial: false }));
    }
  }, [modelType, memoizedSetWhatsApp, memoizedFetchChannels]);
};
