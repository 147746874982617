import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    form: {
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
    },
    title: {
        fontFamily: "Poppins",
        color: theme.palette.primary.mainReverseByWhite,
        fontSize: "16px",
        fontWeight: "500",
    },
    modal: {
        position: "absolute",
        top: "50%",
        height: "700px",
        overflow: "auto",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "8px",
        maxWidth: "500px",
        width: "100%",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        ...theme.scrollbarStyles
    }
}));

export default useStyles;
