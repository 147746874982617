import {
  Chip,
  Paper,
  TextField,
  useTheme,
  makeStyles,
  Tooltip,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import React, { useEffect, useRef, useState } from "react"
import { isArray, isString } from "lodash"
import toastError from "../../errors/toastError"
import api from "../../services/api"
import "./TagsContainer.css"

const useStyles = makeStyles((theme) => ({
  tagBox: {
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    marginTop: 8,
    ...theme.scrollbarStyles,
  },
}))

export function TagsContainer({ ticket, titleBlue = true, open = true }) {
  const classes = useStyles()
  const [selectedKanbanTags, setSelectedKanbanTags] = useState([])
  const [selectedNormalTags, setSelectedNormalTags] = useState([])
  const isMounted = useRef(true)
  const theme = useTheme()
  const [kanbanTags, setKanbanTags] = useState([])
  const [normalTags, setNormalTags] = useState([])

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (isMounted.current && open) {
        async function fetchData () {
          const { data } = await api.get(`/tickets/u/${ticket?.uuid}`)

          const ticketKanbanTags = data.tags.filter((tag) => tag.kanban === 1);
          const ticketNormalTags = data.tags.filter((tag) => tag.kanban !== 1);
          setSelectedKanbanTags(ticketKanbanTags);
          setSelectedNormalTags(ticketNormalTags);
        }
        fetchData()
      }
      
      if(open) {
        loadTags();
      }
  }, [ticket, open])

  const createTag = async (data) => {
    try {
      const { data: responseData } = await api.post(`/tags`, data)
      return responseData
    } catch (err) {
      toastError(err)
      return err
    }
  }

  const loadTags = async () => {
    try {
      const { data } = await api.get(`/tags/list`)
      const kanban = data.filter((tag) => tag.kanban === 1)
      const normal = data.filter((tag) => tag.kanban !== 1)
      setKanbanTags(kanban)
      setNormalTags(normal)
    } catch (err) {
      toastError(err)
    }
  }

  const syncTags = async (data) => {
    try {
      const { data: responseData } = await api.post(`/tags/sync`, data)
      return responseData
    } catch (err) {
      toastError(err)
    }
  }

  const onChangeKanban = async (value, reason) => {
    let optionsChanged = []

    if (reason === "create-option") {
      if (isArray(value)) {
        for (let item of value) {
          if (isString(item)) {
            if (item.length <= 2) {
              toastError("O nome da tag deve ter mais de 2 caracteres.")
              continue
            }
            const newTag = await createTag({ name: item, kanban: 1 })
            if (newTag?.error) {
              return
            }
            optionsChanged.push(newTag)
          } else {
            optionsChanged.push(item)
          }
        }
      }
    } else {
      optionsChanged = value
    }

    if (optionsChanged.length === 0) {
      return
    }

    setSelectedKanbanTags(optionsChanged)
    await syncTags({
      ticketId: ticket.id,
      tags: [...optionsChanged, ...selectedNormalTags],
    })
  }

  const onChangeNormal = async (value, reason) => {
    let optionsChanged = []

    if (reason === "create-option") {
      if (isArray(value)) {
        for (let item of value) {
          if (isString(item)) {
            if (item.length <= 2) {
              toastError("O nome da tag deve ter mais de 2 caracteres.")
              continue
            }
            const newTag = await createTag({ name: item, kanban: 0 })
            if (newTag?.error) {
              return
            }
            optionsChanged.push(newTag)
          } else {
            optionsChanged.push(item)
          }
        }
      }
    } else {
      optionsChanged = value
    }

    if (optionsChanged.length === 0) {
      return
    }

    setSelectedNormalTags(optionsChanged)
    await syncTags({
      ticketId: ticket.id,
      tags: [...optionsChanged, ...selectedKanbanTags],
    })
  }

  const handleDelete = async (tagToDelete, type) => {
    let updatedTags = [
      ...selectedKanbanTags.filter((tag) => tag.name !== tagToDelete.name),
      ...selectedNormalTags.filter((tag) => tag.name !== tagToDelete.name),
    ]

    if (type === "kanban") {
      setSelectedKanbanTags(
        selectedKanbanTags.filter((tag) => tag.name !== tagToDelete.name)
      )
    } else {
      setSelectedNormalTags(
        selectedNormalTags.filter((tag) => tag.name !== tagToDelete.name)
      )
    }

    await syncTags({ ticketId: ticket.id, tags: updatedTags })
  }

  return (
    <Paper
      style={{
        padding: "0",
        boxShadow: "none",
        background: "transparent !important",
      }}
    >
      {/* Normal Tags */}
      <div style={{ margin: "1rem 0" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "0.5rem",
          }}
        >
          <h3
            style={{
              color: theme.palette.primary.neutralTextColor,
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "14px",
            }}
          >
            Tags Gerais
          </h3>
        </div>
        <div>
          <Autocomplete
            multiple
            size="small"
            options={normalTags}
            value={selectedNormalTags}
            freeSolo
            onChange={(e, v, r) => onChangeNormal(v, r)}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Adicionar Tags"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Tooltip title={option.name}>
                  <Chip
                    key={index}
                    variant="outlined"
                    style={{
                      border: `none`,
                      backgroundColor: `${option?.color || "#eee"}50`,
                      color:
                        theme.mode === "light"
                          ? option.color || "#eee"
                          : "#FFFFFF",
                      marginRight: 4,
                      marginBottom: 4,
                      fontWeight: 400,
                      borderRadius: 4,
                      fontSize: "12px",
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                    label={option.name}
                    {...getTagProps({ index })}
                    onDelete={() => handleDelete(option, "tags")}
                  />
                </Tooltip>
              ))
            }
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1rem 0 0.5rem 0",
        }}
      >
        <h3
          style={{
            color: theme.palette.primary.neutralTextColor,
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: "14px",
          }}
        >
          Kanban
        </h3>
      </div>
      <div style={{}}>
        <Autocomplete
          multiple
          size="small"
          options={kanbanTags}
          value={selectedKanbanTags}
          freeSolo
          onChange={(e, v, r) => onChangeKanban(v, r)}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Adicionar Tags Kanban"
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Tooltip title={option.name}>
                <Chip
                  key={index}
                  variant="outlined"
                  style={{
                    border: `none`,
                    backgroundColor: `${
                      option?.color || theme.palette.primary.main
                    }30`,
                    color:
                      theme.mode === "light"
                        ? option.color || theme.palette.primary.main
                        : "#FFFFFF",
                    marginRight: 4,
                    marginBottom: 4,
                    fontWeight: 400,
                    borderRadius: 4,
                    fontSize: "12px",
                    fontFamily: "Poppins",
                    textTransform: "capitalize",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                  }}
                  label={option.name}
                  {...getTagProps({ index })}
                  onDelete={() => handleDelete(option, "kanban")}
                />
              </Tooltip>
            ))
          }
        />
      </div>
    </Paper>
  )
}
