import React, { useContext, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FolderList from "./FolderList";
import { EmailsContext } from "../../../../context/EmailContext";

const useStyles = makeStyles((theme) => ({
    emailListStyles: {
        display: "grid",
        maxHeight: "calc(100vh - 74px)",
        overflowY: "auto",
        maxWidth: "300px",
        ...theme.scrollbarStyles,
    },
    dropdown: {
        width: "100%",
        padding: "8px",
        fontFamily: "Poppins, sans-serif",
        border: "none", // Remove a borda
        fontSize: "14px",
        fontWeight: "400",
        background: theme.mode === "light" ? "#FBF9F9": "#393737", // Remove o fundo
        appearance: "none", // Remove a aparência padrão do navegador
        WebkitAppearance: "none", // Remove no Safari
        MozAppearance: "none", // Remove no Firefox
        outline: "none", // Remove a linha de foco
        cursor: "pointer", // Aplica um cursor de ponteiro
        color: theme.palette.primary.neutralTextColor,
        position: "relative", // Necessário para posicionar a seta
    },
    dropdownWrapper: {
        position: "relative", // Necessário para colocar a seta sobre o select,
        padding: "0.5rem",
        background: theme.mode === "light" ? "#FBF9F9": "#393737",
        borderRadius: "8px 8px 0 0",
        borderBottom: `1px solid ${theme.palette.primary.neutralBorder}`,
    },
    dropdownArrow: {
        content: "''",
        position: "absolute",
        right: "1rem",
        top: "50%",
        borderTop: `5px solid ${theme.palette.primary.neutralTextColor}`,
        transform: "translateY(-50%)",
        borderLeft: "5px solid transparent",
        borderRight: "5px solid transparent",
    },
}));

const EmailAccounts = ({
    emailSettings,
    setSelectedSetting,
    setCurrentPage,
    loadingFolders,
    loading,
    folders,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const { selectedEmailId, setSelectedEmailId, setHasMore, setSelectedFolder,selectedFolder, setSelectedEmail} = useContext(EmailsContext);

    const handleEmailChange = (event) => {
        const selectedId = event.target.value;
        setSelectedEmailId(selectedId);
        setSelectedEmail(null)
        setHasMore(true);
        const selectedEmailSetting = emailSettings.find((setting) => Number(setting.id) === Number(selectedId));
        setSelectedSetting(selectedEmailSetting);
        setSelectedFolder("INBOX");
        setCurrentPage(1);
    };

    return (
        <div className={classes.emailListStyles}>
            <div className={classes.dropdownWrapper}>
                <select
                    value={selectedEmailId}
                    onChange={handleEmailChange}
                    className={classes.dropdown}
                    disabled={loadingFolders || loading}
                >
                    <option value="" disabled>
                        Selecione um e-mail
                    </option>
                    {emailSettings.map((setting) => (
                        <option key={setting.id} value={setting.id}>
                            {setting.smtpUser}
                        </option>
                    ))}
                </select>
                <span className={classes.dropdownArrow} />
            </div>

            {selectedEmailId && (
                <FolderList
                    setSelectedEmail={setSelectedEmail}
                    loadingFolders={loadingFolders}
                    folders={folders}
                    setSelectedFolder={setSelectedFolder}
                    selectedFolder={selectedFolder}
                    theme={theme}
                    loading={loading}
                />
            )}
        </div>
    );
};

export default EmailAccounts;
