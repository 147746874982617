import React, { useContext, useEffect, useRef, useState } from "react";
import { Avatar, Typography, CircularProgress, Checkbox } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import api from "../../../../services/api";
import { EmailsContext } from "../../../../context/EmailContext";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "grid",
        gridTemplateColumns: "68px 1fr 2fr 1fr",
        width: "100%",
        background: theme.mode === "light" ? "white" : "#171717",
    },
    header: {
        fontWeight: "400",
        fontFamily: "Poppins",
        borderBottom: `1px solid ${theme.palette.primary.neutralBorder}`,
        display: "flex",
        alignItems: "center",
        color: "#9F9797"
    },
    row: {
        display: "grid",
        gridTemplateColumns: "68px 1fr 2fr 1fr",
        alignItems: "center",
        fontFamily: "Poppins",
        padding: "10px 0 10px 1rem",
        borderBottom: `1px solid ${theme.palette.primary.neutralBorder}`,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#f5f5f5",
        },
    },
    avatarContainer: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
    },
    listContainer: {
        maxHeight: "calc(100vh - 232px)",
        overflowY: "auto",
        background: theme.mode === "light" ? "white" : "#171717",
        ...theme.scrollbarStyles
    },
    checkboxStyles: {
        transition: ".3s",
        position: "absolute",
        top: "0",
        zIndex: "1000",
        opacity: "0%",
        background: "#FFFFFF",
        "&:hover": {
            background: "#FFFFFF !important",
            opacity: "100% !important",
        }
    }
}));

const EmailList = ({ emails = [], loading = false, setSelectedEmail, setCurrentPage, selectedSetting, setEmails, loadingFolders }) => {
    const classes = useStyles();
    const containerRef = useRef(null);
    const {
        hasMore,
        selectedEmails,
        setSelectedEmails,
        selectAll,
        setSelectAll
    } = useContext(EmailsContext);

    const handleScroll = () => {
        if (containerRef.current) {
            const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 5 && !loading && hasMore) {
                setCurrentPage((prev) => prev + 1);
            }
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
            return () => container.removeEventListener("scroll", handleScroll);
        }
    }, [loading, hasMore, setCurrentPage]);

    const handleSelectAll = (event) => {
        const checked = event.target.checked;
        setSelectAll(checked);
        setSelectedEmails(checked ? emails : []);
    };

    const theme = useTheme();

    return (
        <div>
            <div className={classes.container}>
                <span className={classes.header} style={{ padding: "1rem 0", justifyContent: "center" }}>
                    <Checkbox color="primary" style={{ padding: "0" }} checked={selectAll} onChange={handleSelectAll} />
                </span>
                <span className={classes.header} style={{ paddingLeft: "1rem" }}>De</span>
                <span className={classes.header} style={{ paddingLeft: "0.5rem" }}>Assunto</span>
                <span className={classes.header} style={{ justifyContent: "end", paddingRight: "1rem" }}>Recebido</span>
            </div>
            <div className={classes.listContainer} ref={containerRef}>
                {emails.length > 0 ? (
                    emails.map((email, index) => (
                        <div key={index} className={classes.row} style={{ opacity: email.isRead ? 0.8 : 1, background: email.isRead ? "#00000010" : "transparent" }} onClick={async (e) => {
                            if (!e.target.closest('.checkbox-container')) {
                                setSelectedEmail(email);
                                if (!email.isRead) {
                                    setEmails((prevState) => prevState.map((emailFound) => {
                                        if (emailFound.id === email.id) {
                                            return {
                                                ...emailFound,
                                                isRead: "true",
                                            }
                                        }
                                        return emailFound
                                    }));
                                    const { data } = await api.put(`/emails/${selectedSetting?.id}/${email?.id}/read`);
                                }
                            }
                        }}>
                            <div className="checkbox-container" style={{ position: "relative" }}>
                                <Checkbox
                                    className={classes.checkboxStyles}
                                    checked={selectedEmails.includes(email)}
                                    style={{ opacity: selectedEmails.includes(email) ? "100%" : "0%", background: selectedEmails.includes(email) ? "white" : "transparent" }}
                                    onChange={(event) => {
                                        setSelectedEmails(prev => prev.includes(email) ? prev.filter(e => e !== email) : [...prev, email]);
                                    }}
                                    color="primary"
                                    sx={{
                                        color: theme.palette.primary.main,
                                        "&.Mui-checked": {
                                            color: theme.palette.primary.main,
                                        },
                                        "&.MuiCheckbox-root": {
                                            color: theme.palette.primary.main,
                                        },
                                    }}
                                />
                                <Avatar src={email.fromPhoto}>{!email.fromPhoto && email.fromEmail.charAt(0).toUpperCase()}</Avatar>
                            </div>

                            <div style={{ color: theme.mode === "light" ? "#222222" : "white" }} className={classes.avatarContainer}>
                                {email?.from?.match(/^"([^"]+)"/)?.[1] || "Desconhecido"}
                            </div>
                            <div>
                                <Typography style={{ color: theme.mode === "light" ? "#534D4D" : "white" }}>{email.subject}</Typography>
                                <Typography style={{ color: theme.mode === "light" ? "#ff6b6b" : "white"  }}>{email.preview}</Typography>
                            </div>
                            <Typography style={{ textAlign: "right", color: theme.mode === "light" ? "#222222" : "white", paddingRight: "0.5rem", fontSize: "12px" }}>
                                {new Intl.DateTimeFormat("pt-BR", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "2-digit",
                                }).format(new Date(email.receivedAt))}
                            </Typography>
                        </div>
                    ))
                ) : (
                    <>
                        {(!loading && !loadingFolders) && <div style={{ textAlign: "center", padding: "20px", color: theme.mode === "light" ? "#222222" : "white" }}>Nenhum e-mail encontrado.</div>}
                    </>
                )}
                {(loading || loadingFolders) && (
                    <div className={classes.loadingContainer}>
                        <CircularProgress size={24} />
                        <Typography style={{ marginLeft: 10, color: theme.mode === "light" ? "#222222" : "white" }}>Carregando...</Typography>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmailList;
