import React, { useState, useEffect, useContext } from 'react';
import Chip from '@material-ui/core/Chip';
import {
  Button,
  Typography,
  Divider,
  Grid,
  TextField,
  Modal,
  Switch,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  LinearProgress,
  Paper,
  Box,
  CircularProgress,
  useTheme,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GroupAdd, PeopleAlt } from '@material-ui/icons';
import api from '../../services/api';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import ParticipantsTable from '../ParticipantsTable';
import useStyles from './style';
import { WhatsAppsContext } from '../../context/WhatsApp/WhatsAppsContext';

const CreateGroupComponent = () => {
  const [isCreateGroupOpen, setCreateGroupOpen] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [useDropdown, setUseDropdown] = useState(false);
  const [contactNumber, setContactNumber] = useState('');
  const [selectedConnection, setSelectedConnection] = useState('');
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [groupCount, setGroupCount] = useState(1);
  const [loadingParticipants, setLoadingParticipants] = useState(false);

  const { whatsApps, loading } = useContext(WhatsAppsContext);

  const handleOpenCreateGroup = () => setCreateGroupOpen(true);
  const handleCloseCreateGroup = () => setCreateGroupOpen(false);
  const theme = useTheme();
  const classes = useStyles(theme);
  useEffect(() => {
    if (isCreateGroupOpen) {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get('/contacts');
          setContacts(data.contacts);
        } catch (err) {
          toast.error('Erro ao buscar contatos.');
        }
      };
      fetchContacts();
    }
  }, [isCreateGroupOpen]);

  useEffect(() => {
    if (selectedConnection) {
      const fetchGroups = async () => {
        try {
          const { data } = await api.get(`/api/groups/list-groups`, {
            params: { whatsappId: selectedConnection }
          });
          setGroups(data.groups);
        } catch (err) {
          toast.error('Erro ao buscar grupos.');
        }
      };
      fetchGroups();
    }
  }, [selectedConnection]);

  const handleListParticipants = async () => {
    if (!selectedConnection || selectedGroups.length === 0) {
      toast.error('Selecione uma conexão e ao menos um grupo.');
      return;
    }

    setLoadingParticipants(true);

    try {
      const { data } = await api.post(`/api/groups/list-group-participants`, {
        whatsappId: selectedConnection,
        groupIds: selectedGroups
      });

      let allParticipants = [];
      data.data.forEach(group => {
        group.participants.forEach(participant => {
          allParticipants.push({
            ...participant,
            groupName: group.groupName
          });
        });
      });

      if (allParticipants.length > 0) {
        setParticipants(allParticipants);
        toast.success('Participantes listados com sucesso!');
      } else {
        setParticipants([]);
        toast.info('Nenhum participante encontrado.');
      }
    } catch (err) {
      toast.error('Erro ao listar participantes.');
    } finally {
      setLoadingParticipants(false);
    }
  };

  const handleExportToXLS = () => {
    if (participants.length === 0) {
      toast.error('Nenhum participante para exportar.');
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(participants);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Participantes');
    XLSX.writeFile(workbook, 'participantes.xlsx');
    toast.success('Exportado com sucesso!');
  };

  const handleClear = () => {
    setSelectedConnection('');
    setSelectedGroups([]);
    setGroups([]);
    setParticipants([]);
  };

  const handleCreateGroup = async () => {
    const formattedContactNumber = useDropdown
      ? null
      : formatContactNumber(contactNumber);

    if (useDropdown && selectedContacts.length === 0) {
      toast.error('Selecione ao menos um contato.');
      return;
    }

    if (!useDropdown && !formattedContactNumber) {
      toast.error('Número inválido. O formato deve conter o código DDD e o número.');
      return;
    }

    if (!groupName) {
      toast.error('Nome do grupo é obrigatório.');
      return;
    }

    if (!selectedConnection) {
      toast.error('Selecione uma conexão.');
      return;
    }

    try {
      setIsLoading(true);

      const participants = useDropdown
        ? selectedContacts.map(contact => `${contact.number}@s.whatsapp.net`)
        : [formattedContactNumber];

      for (let i = 0; i < groupCount; i++) {
        await api.post('/api/groups/create-group', {
          whatsappId: selectedConnection,
          groupName: groupName,
          participants,
        });
      }

      toast.success(`Criado ${groupCount} grupo(s) com sucesso!`);
      handleCloseCreateGroup();
    } catch (err) {
      toast.error('Erro ao criar grupo(s).');
    } finally {
      setIsLoading(false);
    }
  };

  const formatContactNumber = (number) => {
    let formattedNumber = number.replace(/[^0-9]/g, '');

    if (formattedNumber.length === 13 && formattedNumber[4] === '9') {
      formattedNumber = formattedNumber.slice(0, 4) + formattedNumber.slice(5);
    }

    if (formattedNumber.length !== 12) {
      toast.error('Número inválido. O formato deve conter o código DDD e o número.');
      return null;
    }

    return `${formattedNumber}@s.whatsapp.net`;
  };
  return (
    <div >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Box display="flex" alignItems="center" mb={2}>
              <GroupAdd style={{ marginRight: '10px', fontSize: '30px' }} />
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                Gerador de Grupos
              </Typography>
            </Box>
            <Divider style={{ marginBottom: '2rem' }} />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Button variant="contained" color="primary" fullWidth onClick={handleOpenCreateGroup}>
                  Criar Grupos em Massa
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Box display="flex" alignItems="center" mb={2}>
              <PeopleAlt style={{ marginRight: '10px', fontSize: '30px' }} />
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                Listar
              </Typography>
            </Box>
            <Divider style={{ marginBottom: '2rem' }} />
            <Grid container spacing={3} alignItems="center" direction={{ xs: 'column', sm: 'row' }}>
              <Grid item xs={12} sm={6}>
                {/* Autocomplete para seleção única de conexão */}
                <Autocomplete
                  options={whatsApps.filter((contact) => contact.type === null)}
                  getOptionLabel={(option) => option.name || option.number}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        label={option.name || option.number}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Conexão"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                        style: { color: theme.palette.primary.mainReverseByWhite }, // Aplica a cor do rótulo
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          color: theme.palette.primary.mainReverseByWhite,
                        },
                        classes: {
                          notchedOutline: {
                            borderColor: `${theme.palette.primary.mainReverseByWhite} !important`, // Aplica a cor da borda
                          },
                        },
                      }}
                    />
                  )}
                  value={whatsApps.filter((contact) => contact.type === null).find(whatsApp => whatsApp.id === selectedConnection) || null}
                  onChange={(event, newValue) => setSelectedConnection(newValue ? newValue.id : '')}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Autocomplete para seleção múltipla de grupos */}
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={groups}
                  getOptionLabel={(option) => option.subject}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        label={option.subject}
                        style={{
                          backgroundColor: `${theme.palette.primary.main}70`,
                          color: theme.palette.primary.contrastText,
                          border: `2px solid ${theme.palette.primary.main}`,
                          borderRadius: "8px"
                        }}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Grupos"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                        style: { color: theme.palette.primary.mainReverseByWhite },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          borderColor: theme.palette.primary.mainReverseByWhite,
                          color: theme.palette.primary.mainReverseByWhite,
                        },
                        classes: {
                          notchedOutline: {
                            borderColor: `${theme.palette.primary.mainReverseByWhite} !important`,
                          },
                        },
                      }}
                    />
                  )}
                  value={groups.filter((group) => selectedGroups.includes(group.id))}
                  onChange={(event, newValue) => setSelectedGroups(newValue.map((group) => group.id))}
                  disabled={!selectedConnection || groups.length === 0}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: '40px' }} direction={{ xs: 'column', sm: 'row' }}>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={handleListParticipants}
                  disabled={selectedGroups.length === 0}
                >
                  {loadingParticipants ? <CircularProgress size={24} /> : 'Listar'}
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={handleExportToXLS}
                  disabled={participants.length === 0}
                >
                  Exportar XLS
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button variant="contained" fullWidth color="default" onClick={handleClear}>
                  Limpar
                </Button>
              </Grid>
            </Grid>

          </Paper>
        </Grid>

        {/* Tabela de Participantes */}
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Lista de Participantes
            </Typography>
            <Divider style={{ marginBottom: '2rem' }} />

            {loadingParticipants ? (
              <CircularProgress />
            ) : (
              <ParticipantsTable participants={participants} />
            )}
          </Paper>
        </Grid>
      </Grid>

      <Modal open={isCreateGroupOpen} onClose={handleCloseCreateGroup}>
        <Box
          className={classes.mainPaper}
          style={{
            background: 'white',
            padding: '30px',
            margin: '4% auto',
            maxWidth: '600px',
            borderRadius: '8px',
            boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
            position: 'relative',
          }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <GroupAdd color="primary" style={{ fontSize: '40px', marginRight: '15px' }} />
            <Typography variant="h4" color="primary" style={{ fontWeight: 'bold' }}>
              Criar Grupo
            </Typography>
          </Box>
          <Divider style={{ marginBottom: '1.5rem' }} />
          <FormControl fullWidth style={{ marginBottom: '1rem' }}>
            <InputLabel color="primary">Conexão</InputLabel>
            <Select
              value={selectedConnection}
              onChange={(e) => setSelectedConnection(e.target.value)}
              disabled={loading}
            >
              {whatsApps.filter((contact) => {
                return contact.type === null;
              }).map((whatsApp) => (
                <MenuItem key={whatsApp.id} value={whatsApp.id}>
                  {whatsApp.name || whatsApp.number}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            InputLabelProps={{ shrink: true, style: { color: theme.palette.primary.mainReverseByWhite } }}
            label="Nome do Grupo"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: '1rem' }}
          />
          <TextField
            InputLabelProps={{ shrink: true, style: { color: theme.palette.primary.mainReverseByWhite } }}
            label="Quantidade de Grupos"
            type="number"
            value={groupCount}
            onChange={(e) => setGroupCount(e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: '1rem' }}
          />
          <FormControlLabel
            control={<Switch checked={useDropdown} onChange={() => setUseDropdown(!useDropdown)} color="primary" />}
            label="Definir Contato / Selecionar Contato"
            style={{ marginBottom: '1rem' }}
          />
          {useDropdown ? (
            <Autocomplete
              multiple
              disableCloseOnSelect
              options={contacts.filter(contact => !contact.isGroup)}
              getOptionLabel={(option) => option.name}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.id}
                    label={option.name}
                    {...getTagProps({ index })}
                    style={{
                      backgroundColor: `${theme.palette.primary.main}70`,
                      color: theme.palette.primary.contrastText,
                      border: `2px solid ${theme.palette.primary.main}`,
                      borderRadius: "8px"
                    }}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} label="Selecionar Contato" variant="outlined" />
              )}
              value={selectedContacts}
              onChange={(event, newValue) => setSelectedContacts(newValue)}
            />
          ) : (
            <TextField
              InputLabelProps={{ shrink: true, style: { color: theme.palette.primary.mainReverseByWhite } }}
              label="Número do Participante"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
              fullWidth
              variant="outlined"
            />
          )}
          <Divider style={{ margin: '1.5rem 0' }} />
          {isLoading && <LinearProgress style={{ marginBottom: '1.5rem' }} />}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                onClick={handleCloseCreateGroup}
                fullWidth
                color="primary"
                style={{
                  fontWeight: 'bold',
                  borderRadius: '10px',
                  padding: '10px 20px',
                  border: '2px solid',
                  transition: 'background 0.3s ease',
                }}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={handleCreateGroup}
                fullWidth
                disabled={isLoading}
                color="primary"
                style={{
                  fontWeight: 'bold',
                  borderRadius: '10px',
                  padding: '10px 20px',
                  transition: 'background 0.3s ease',
                }}
              >
                {isLoading ? 'Criando...' : 'Salvar'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateGroupComponent;
