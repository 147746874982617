import React, { useContext, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip, useTheme } from "@material-ui/core";
import { EmailsListContext } from "../../../context/EmailsListContext";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Add, AddRounded, DeleteOutline, EditOutlined, PersonAddOutlined } from "@material-ui/icons";
import useStyles from "./style";

export default function EmailListsPage() {

    const {
        emailLists,
        loading,
        openDeleteModal,
        setOpenDeleteModal,
        openCreateModal,
        setOpenCreateModal,
        newListName,
        setNewListName,
        updatedListName,
        setUpdatedListName,
        updateEmailList,
        handleDeleteConfirmation,
        deleteEmailList,
        handleCreateList,
        handleTabClick,
    } = useContext(EmailsListContext);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [currentListId, setCurrentListId] = useState(null);

    const handleEditClick = (listId, listName) => {
        setCurrentListId(listId);
        setUpdatedListName(listName);
        setOpenEditModal(true);
    };

    const classes = useStyles();
    const theme = useTheme();
    return (
        <> 
            <div style={{display: "flex", gap: "0.5rem", alignItems: "center", marginBottom: "1rem"}}>
                <h1 style={{fontFamily: "Poppins", fontWeight: "400", fontSize: "16px", color: theme.palette.primary.neutralTextColor}}>Listas de E-mails</h1>
                <IconButton variant="contained" color="primary" onClick={() => setOpenCreateModal(true)}>
                    <AddRounded />
                </IconButton>
            </div>

            <div className={classes.emailsListStyles}>
                {loading ? (
                    <p>Carregando...</p>
                ) : emailLists.length === 0 ? (
                    <p>Nenhuma lista encontrada.</p>
                ) : (
                    <TableContainer component={Paper} className="mt-4">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: "70%", fontFamily: "Poppins" }}>Nome</TableCell>
                                    <TableCell style={{ width: "30%", fontFamily: "Poppins" }}>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {emailLists.map((list) => (
                                    <TableRow key={list.id}>
                                        <TableCell style={{ fontFamily: "Poppins" }}>{list.name}</TableCell>
                                        <TableCell>
                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                <Tooltip title="Ver detalhes" arrow>
                                                    <IconButton onClick={() => handleTabClick(list.id)}>
                                                        <PersonAddOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Excluir" arrow>
                                                    <IconButton onClick={() => handleDeleteConfirmation(list.id)} color="secondary">
                                                        <DeleteOutline />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Editar" arrow>
                                                    <IconButton onClick={() => handleEditClick(list.id, list.name)} color="primary">
                                                        <EditOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>

            {/* Modal de Confirmação de Exclusão */}
            <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                <h3 style={{padding: "1rem", fontFamily: "Poppins", fontSize: "16px", color: theme.palette.primary.neutralTextColor, fontWeight: "400", borderBottom: `1px solid ${theme.palette.primary.neutralBorder}`}}>Confirmar Exclusão</h3>
                <DialogContent style={{padding: "1rem"}}>
                    <p>Tem certeza que deseja excluir esta lista?</p>
                </DialogContent>
                <DialogActions style={{borderTop: `1px solid ${theme.palette.primary.neutralBorder}`, padding: "1rem"}}>
                    <Button onClick={deleteEmailList} style={{background: theme.palette.primary.main, color: "white", textTransform: "capitalize", fontFamily: "Poppins"}}>
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Modal de Criação de Lista */}
            <Dialog open={openCreateModal} onClose={() => setOpenCreateModal(false)}>
                <h3 style={{padding: "1rem", fontFamily: "Poppins", fontSize: "16px", color: theme.palette.primary.neutralTextColor, fontWeight: "400", borderBottom: `1px solid ${theme.palette.primary.neutralBorder}`}}>Criar Nova Lista</h3>
                <DialogContent style={{padding: "1rem 1rem 2rem 1rem"}}>
                    <TextField
                        fullWidth
                        label="Nome da Lista"
                        value={newListName}
                        onChange={(e) => setNewListName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions style={{borderTop: `1px solid ${theme.palette.primary.neutralBorder}`, padding: "1rem"}}>
                    <Button onClick={handleCreateList} style={{background: theme.palette.primary.main, color: "white", textTransform: "capitalize", fontFamily: "Poppins"}}>
                        Criar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Modal de Edição de Lista */}
            <Dialog open={openEditModal} onClose={() => setOpenEditModal(false)}>
                <h3 style={{padding: "1rem", fontFamily: "Poppins", fontSize: "16px", color: theme.palette.primary.neutralTextColor, fontWeight: "400", borderBottom: `1px solid ${theme.palette.primary.neutralBorder}`}}>Editar Lista</h3>
                <DialogContent style={{padding: "1rem 1rem 2rem 1rem"}}>
                    <TextField
                        fullWidth
                        label="Nome da Lista"
                        value={updatedListName}
                        onChange={(e) => setUpdatedListName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions style={{borderTop: `1px solid ${theme.palette.primary.neutralBorder}`, padding: "1rem"}}>
                    <Button
                        onClick={() => {
                            updateEmailList(currentListId, updatedListName);
                            setOpenEditModal(false);
                        }}
                        style={{background: theme.palette.primary.main, color: "white", textTransform: "capitalize", fontFamily: "Poppins"}}
                    >
                        Atualizar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
