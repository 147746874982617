import { Box, Chip, TextField, useTheme } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { i18n } from "../../translate/i18n";


export function StatusFilterSearch({ onFiltered, setSelecteds, selecteds }) {

  const onChange = async (value) => {
    setSelecteds(value);
    onFiltered(value);
  };

  const status = [
    { status: 'open', name: `${i18n.t("tickets.search.filterConectionsOptions.open")}` },
    { status: 'closed', name: `${i18n.t("tickets.search.filterConectionsOptions.closed")}` },
    { status: 'pending', name: `${i18n.t("tickets.search.filterConectionsOptions.pending")}` },
    { status: 'group', name: 'Grupos' },

  ]
  const theme = useTheme();
  return (
    <Box>
      <Autocomplete
        multiple
        size="small"
        options={status}
        value={selecteds}
        onChange={(e, v, r) => onChange(v)}
        getOptionLabel={(option) => option.name}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              style={{
                background: `${theme.palette.primary.mainReverseByWhite}70`,
                fontFamily: "Poppins",
                textShadow: "none",
                color: theme.palette.primary.mainReverseByWhite,
                borderRadius: "4px",
                border: "none"
              }}
              label={option?.name}
              {...getTagProps({ index })}
              size="small"
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Filtro por Status"
          />
        )}
      />
    </Box>
  );
}
