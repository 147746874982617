import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { TagsContainer } from "../TagsContainer";
import { Paper } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	modalTitle: {
		fontFamily: "Poppins",
		fontWeight: "400",
		color: theme.palette.primary.neutralTextColor,
		padding: "1rem"
	},
	btnStyles: {
		borderRadius: "8px",
		textTransform: "capitalze",
		color: "white",
		margin: "8px 16px",
		background: theme.palette.primary.main,
		border: `none`,
		"&:hover": {
			border: `none`,
			background: theme.palette.primary.main,
		},
	},
}));

const ContactSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	email: Yup.string().email("Invalid email"),
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave, ticket, cantChangeContactType = false }) => {
	const { user } = useContext(AuthContext);
	const classes = useStyles();
	const isMounted = useRef(true);
	const { whatsApps } = useContext(WhatsAppsContext)
	const initialState = {
		name: "",
		number: "",
		email: "",
		disableBot: false,
	};

	const [contact, setContact] = useState(initialState);
	const [disableBot, setDisableBot] = useState(false);
	const hasOfficialConnection =whatsApps.some((connection) => connection.type === "official");

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		const fetchContact = async () => {
			if (initialValues) {
				setContact(prevState => {
					return { ...prevState, ...initialValues };
				});
			}

			if (!contactId) return;

			try {
				if(ticket?.contact) {
					setContact(ticket.contact);
					setDisableBot(ticket.contact.disableBot);
					return
				}
				const { data } = await api.get(`/contacts/${contactId}`);
				if (isMounted.current) {
					setContact(data);
					setDisableBot(data.disableBot);
				}
			} catch (err) {
				toastError(err);
			}
		};

		fetchContact();
	}, [contactId, open, initialValues]);
	
	const handleClose = () => {
		onClose();
	};

	const handleSaveContact = async values => {
		try {
			if (contactId) {
				await api.put(`/contacts/${contactId}`, { ...values, disableBot: disableBot });
				handleClose();
			} else {
				const { data } = await api.post("/contacts", { ...values, disableBot: disableBot });
				if (onSave) {
					onSave(data);
				}
				handleClose();
			}
			toast.success(i18n.t("contactModal.success"));
		} catch (err) {
			toastError(JSON.parse(err.request.response).error);
		}
	};

	const hideContactNumberActive = localStorage.getItem("hideContactNumberActive") === "true";

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} style={{ zIndex: "1300", maxWidth: "500px", margin: "0 auto" }} scroll="paper">
				<h3 className={classes.modalTitle} id="form-dialog-title">
					{contactId
						? `${i18n.t("contactModal.title.edit")}`
						: `${i18n.t("contactModal.title.add")} `}
				</h3>

				<Formik
					initialValues={contact}
					enableReinitialize={true}
					validationSchema={ContactSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveContact(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, errors, touched, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								{contact?.profilePicUrl && (
									<div style={{ textAlign: "center", marginTop: "1rem" }}>
										<img
											src={contact.profilePicUrl}
											alt={`${contact.name || "Contact"}'s profile`}
											style={{
												width: "100px",
												height: "100px",
												borderRadius: "50%",
												objectFit: "cover",
											}}
										/>
									</div>
								)}
								<Typography style={{fontFamily: "Poppins"}} variant="subtitle1" gutterBottom>
									{i18n.t("contactModal.form.mainInfo")}
								</Typography>
								<Field
									as={TextField}
									label={i18n.t("contactModal.form.name")}
									name="name"
									autoFocus
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									variant="outlined"
									margin="dense"
									fullWidth
									className={classes.textField}
								/>
								<Field
									as={TextField}
									select
									name="contactType"
									label="Tipo de Contato"
									SelectProps={{ native: true }}
									error={touched.contactType && Boolean(errors.contactType)}
									helperText={touched.contactType && errors.contactType}
									variant="outlined"
									fullWidth
									margin="dense"
									InputLabelProps={{ shrink: true }}
									value={values.contactType || ""}
									disabled={((!!contactId && contact?.contactType !== undefined) || cantChangeContactType)}
								>
									<option value="" disabled>
										Selecione uma opção
									</option>
									{hasOfficialConnection && <option value="official">
										Whatsapp Oficial
									</option>}
									<option value="baileys">
										Whatsapp QR Code
									</option>
								</Field>


								{!(hideContactNumberActive && user.profile === "user") && <Field
									as={TextField}
									label={i18n.t("contactModal.form.number")}
									name="number"
									fullWidth
									error={touched.number && Boolean(errors.number)}
									helperText={touched.number && errors.number}
									placeholder="5513912344321"
									variant="outlined"
									margin="dense"
								/>}
								<div>
									<Field
										as={TextField}
										label={i18n.t("contactModal.form.email")}
										name="email"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										placeholder="Email address"
										fullWidth
										margin="dense"
										variant="outlined"
									/>
								</div>

								<Typography
									style={{ marginBottom: 8, marginTop: 12, fontFamily: "Poppins" }}
									variant="subtitle1"
								>
									{i18n.t("contactModal.form.whatsapp")} {contact?.whatsapp ? contact?.whatsapp.name : ""}
								</Typography>
								<Typography
									style={{ marginBottom: 8, marginTop: 12, fontFamily: "Poppins" }}
									variant="subtitle1"
								>
									{i18n.t("contactModal.form.extraInfo")}
								</Typography>
								{ticket && <Paper style={{ boxShadow: "none", maxWidth: "600px", marginBottom: "1rem" }}>
									<TagsContainer ticket={ticket} titleBlue={false} />
								</Paper>}
								<FieldArray name="extraInfo">
									{({ push, remove }) => (
										<>
											{values.extraInfo &&
												values.extraInfo.length > 0 &&
												values.extraInfo.map((info, index) => (
													<div
														className={classes.extraAttr}
														key={`${index}-info`}
													>
														<Field
															as={TextField}
															label={i18n.t("contactModal.form.extraName")}
															name={`extraInfo[${index}].name`}
															variant="outlined"
															margin="dense"
															className={classes.textField}
														/>
														<Field
															as={TextField}
															label={i18n.t("contactModal.form.extraValue")}
															name={`extraInfo[${index}].value`}
															variant="outlined"
															margin="dense"
															className={classes.textField}
														/>
														<IconButton
															size="small"
															onClick={() => remove(index)}
														>
															<DeleteOutlineIcon />
														</IconButton>
													</div>
												))}
											<div className={classes.extraAttr}>
												<Button
													style={{ flex: 1, margin: "8px 0 0 0" }}
													className={classes.btnStyles}
													onClick={() => push({ name: "", value: "" })}
												>
													{`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
												</Button>
											</div>
										</>
									)}
								</FieldArray>
							</DialogContent>
							<DialogActions>
								<Button
									type="submit"
									disabled={isSubmitting}
									className={classes.btnStyles}
								>
									{contactId
										? `${i18n.t("contactModal.buttons.okEdit")}`
										: `${i18n.t("contactModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ContactModal;
