import React, { useContext, useState, useRef, useEffect } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Drawer, IconButton } from "@material-ui/core"
import {
  PhoneOutlined,
  CallEndOutlined,
  Close,
  UndoOutlined,
  VideocamOutlined,
  ReplayOutlined,
  HighlightOffOutlined,
  CheckOutlined,
  PersonOutline,
} from "@material-ui/icons"
import { i18n } from "../../translate/i18n"
import api from "../../services/api"
import toastError from "../../errors/toastError"
import { AuthContext } from "../../context/Auth/AuthContext"
import {
  Modal,
  Button,
  Select,
  MenuItem,
  useMediaQuery,
  Menu,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core"
import jsPDF from "jspdf"
import * as XLSX from "xlsx"
import "./style.css"
import { toast } from "react-toastify"
import FileDownloadIcon from "@material-ui/icons/GetApp"
import { useHistory } from "react-router-dom"
import { WavoipContext } from "../../context/WavoipContext/WhavoipContext"
import videoCamera from "../../assets/video-camera.svg"
import exportIcon from "../../assets/export-icon.svg"

import { MoreVert } from "@material-ui/icons"
import TicketOptionsMenu from "../TicketOptionsMenu"
import { TicketsChannelContext } from "../../context/TicketsChannelContext"
import { TicketsContext } from "../../context/Tickets/TicketsContext"

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    marginRight: 6,
    flex: "none",
    alignSelf: "center",
    display: "flex",
    marginLeft: "auto",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  userButton: {
    color: theme.palette.primary.mainReverseByWhite,
    background: "none",
    borderRadius: "4px",
    width: "24px",
    height: "24px",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
  },
  iconButton: {
    color: theme.palette.primary.mainReverseByWhite,
    borderRadius: "4px",
    width: "24px",
    height: "24px",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
  },
}))

const TicketActionButtonsCustom = ({
  ticket,
  handleDrawerOpen,
  handleDrawerClose,
  drawerOpen,
  contact,
  setMobileUserOpen,
  setMobileAdditionalInfoOpen,
}) => {
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const ticketOptionsMenuOpen = Boolean(anchorEl)
  const classes = useStyles()
  const { user } = useContext(AuthContext)
  const history = useHistory()
  const isCallsEnabled = localStorage.getItem("callEnabled") === "true"
  const [tag, setTag] = useState([])
  const [ticketToClose, setTicketToClose] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const isMounted = useRef(true)
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState("")
  const [selectedSubcategory, setSelectedSubcategory] = useState("")
  const isMobile = useMediaQuery("(max-width: 900px)")
  const [ channelType, setChannelType ] = useState(null);
  const { setCurrentTicket } = useContext(TicketsContext)
  const waData = useContext(WavoipContext)
  const {
    isCalling,
    callDuration,
    handleWavoipCall,
    setIsActive,
    setIsOpen,
    setPhoneNumber,
    setActiveTicket,
    exportMsgType,
  } = waData
  const isMenuOpen = Boolean(anchorEl)

  useEffect(() => {
    if (!openModal) {
      return
    }
    const fetchCategories = async () => {
      try {
        const companyId = user?.companyId
        if (!companyId) {
          throw new Error("CompanyId não encontrado.")
        }

        const { data } = await api.get("/api/categories/list", {
          params: { companyId },
        })

        setCategories(data)
      } catch (error) {
        console.error(
          "Erro ao carregar as categorias:",
          error.response || error.message
        )
        toastError("Erro ao carregar as categorias.")
      }
    }
    fetchCategories()
  }, [openModal])

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (!ticket.id) return

    setChannelType(ticket.whatsapp?.type)
  }, [ticket.id])

  const handleCloseTicket = async () => {
    if (loading) {
      return
    }
    setLoading(true)
    if (!selectedCategory || !selectedSubcategory) {
      toastError(
        "Por favor, selecione uma categoria e uma subcategoria antes de fechar o ticket."
      )
      return
    }

    try {
      await api.put(`/tickets/${ticketToClose}`, {
        status: "closed",
        userId: user?.id,
        queueId: ticket?.queue?.id,
        categoryId: selectedSubcategory,
      })
    } catch (err) {
      setLoading(false)
      toastError(err)
    }
    setOpenModal(false)
    setLoading(false)

    history.push(`/tickets/`)
  }

  const handleCloseTicketOptionsMenu = () => {
    setAnchorEl(null)
  }
  const handleSendMeet = async () => {
    try {
      const response = await api.post("/jitsi/create-meeting", {
        roomName: "sala-de-reuniao",
        userName: "Nome do Usuário",
      })

      const meetingLink = response.data.meetingLink

      try {
        if (channelType === "official") {
          await api.post(`/official-message/${ticket.id}`, {
            body: `Seu link de reunião: ${meetingLink}`,
          })
        } else if (channelType !== null) {
          await api.post(`/hub-message/${ticket.id}`, {
            body: `Seu link de reunião: ${meetingLink}`,
          })
        } else {
          await api.post(`/messages/${ticket.id}`, {
            body: `Seu link de reunião: ${meetingLink}`,
          })
        }

        toast.success("Link da reunião enviado com sucesso!")
      } catch (error) {
        toast.error("Erro ao enviar o link da reunião")
      }
    } catch (error) {
      toast.error("Erro ao criar a reunião Jitsi")
    }
  }
  const handleExportPDFMessage = async () => {
    if (exportMsgType === "enabled" && user.profile !== "admin") {
      toast.error("Apenas administradores podem exportar mensagens.")
      return
    }

    try {
      const response = await api.get(`/tickets/${ticket.id}/messages/export`, {
        responseType: "blob",
      })

      const data = await response.data.arrayBuffer()
      const workbook = XLSX.read(new Uint8Array(data), { type: "array" })
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]
      const jsonData = XLSX.utils.sheet_to_json(sheet)

      if (!jsonData || jsonData.length === 0) {
        toast.error("Nenhuma mensagem encontrada para exportação.")
        return
      }
      const doc = new jsPDF()

      doc.setFont("helvetica", "normal")

      doc.setFont("helvetica", "bold")
      doc.setFontSize(18)
      doc.text("Relatório de Mensagens", 20, 20)

      doc.setFont("helvetica", "normal")
      doc.setFontSize(14)

      doc.text(`Data: ${new Date().toLocaleDateString()}`, 20, 30)
      doc.text(`Usuário: ${user.name}`, 120, 30)

      doc.text(`Contato: ${ticket.contact.name}`, 20, 40)
      doc.text(`ID do Ticket: ${ticket.id}`, 120, 40)

      doc.setFontSize(10)

      let yPosition = 60

      jsonData.forEach((msg, index) => {
        const remetente = msg.Remetente || "Desconhecido"
        const mensagem = msg.Mensagem || "Sem mensagem"
        const dataHora = msg.Data || "Sem data"
        const isSender = remetente === user.name

        const xPosition = isSender ? 130 : 20

        const maxWidth = 180
        const lines = doc.splitTextToSize(
          `${index + 1}. ${remetente}: ${mensagem}  \n data:${dataHora}`,
          maxWidth
        )

        doc.setFillColor("#FFFFFF")
        doc.rect(xPosition, yPosition, maxWidth, lines.length * 10 + 10, "F")
        doc.text(lines, xPosition + 5, yPosition + 5)

        yPosition += lines.length * 6

        if (yPosition > 270) {
          doc.addPage()
          doc.text("Relatório de Mensagens (continuação)", 20, 20)
          yPosition = 40
        }
      })

      doc.save(`Relatorio_${ticket.contact.name}X${user.name}_${new Date().toLocaleDateString()}.pdf`)

      toast.success("PDF gerado com sucesso!")
    } catch (error) {
      console.error("Erro durante a exportação:", error)
      toast.error("Erro ao gerar o PDF.")
    }
  }

  const handleExportMessage = async () => {
    if (exportMsgType === "enabled" && user.profile !== "admin") {
      toast.error("Apenas administradores podem exportar mensagens.")
      return
    }

    try {
      const response = await api.get(`/tickets/${ticket.id}/messages/export`, {
        responseType: "blob",
      })

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `Ticket_${ticket.id}_Messages.xlsx`)
      document.body.appendChild(link)
      link.click()

      toast.success("Exportação de mensagens iniciada com sucesso!")
    } catch (error) {
      toastError(error)
    }
  }

  const handleUpdateTicketStatus = async (e, status, userId) => {
    try {
      setLoading(true)
      await api.put(`/tickets/${ticket.id}`, { status, userId })
      setCurrentTicket({...ticket, status})
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toastError(error)
    }
  }

  const handleUserIconClick = () => {
    if (isMobile) {
      setMobileAdditionalInfoOpen(true)
      return
    }
    handleDrawerOpen()
  }

  const theme = useTheme()
  const handleOpenCloseModal = (ticketId) => {
    setTicketToClose(ticketId) // Define o ticket que será fechado
    setOpenModal(true) // Abre o modal
  }

  const handleCloseTicketSemModal = async (id) => {
    if (tag.length && false) {
      console.log("TAG", tag)
    }
    setTag(ticket?.tags)
    setLoading(true)
    try {
      await api.put(`/tickets/${id}`, {
        status: "closed",
        userId: user?.id,
        queueId: ticket?.queue?.id,
        useIntegration: false,
        promptId: null,
        integrationId: null,
      })
    } catch (err) {
      setLoading(false)
      toastError(err)
    }
    if (isMounted.current) {
      setLoading(false)
    }
    history.push(`/tickets/`)
  }

  const ticketButtonsToTop =
    localStorage.getItem("ticketButtonsToTop") === "true"
  const hideMettingIconActive =
    localStorage.getItem("hideMettingIconActive") === "true"

  return (
    <div className={`${classes.actionButtons} cmp-chat-header`}>
      {ticket.whatsapp?.type === null &&
        ticket.contact.number &&
        isCallsEnabled &&
        !ticket?.isGroup && (
          <MenuItem
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: "auto",
              padding: "0px",
            }}
            onClick={() => {
              setIsOpen(true)
              setIsActive(true)
              setPhoneNumber("")
              setActiveTicket(ticket)
              sessionStorage.setItem("ticketIdDefined", ticket?.id || "")
              handleWavoipCall("", user, ticket)
            }}
          >
            <ListItemIcon style={{ marginRight: "8px" }}>
              {" "}
              {/* Ajuste o marginRight conforme necessário */}
              {isCalling ? (
                <CallEndOutlined
                  style={{ color: theme.palette.primary.main }}
                />
              ) : (
                <PhoneOutlined style={{ color: theme.palette.primary.main }} />
              )}
            </ListItemIcon>
            {!drawerOpen && !isMobile && (
              <ListItemText
                primary={isCalling ? "Desligar" : "Ligar"}
                primaryTypographyProps={{
                  style: {
                    fontFamily: "Poppins",
                    color: theme.palette.primary.main,
                  },
                }}
              />
            )}
          </MenuItem>
        )}

      <MenuItem
        style={{
          display: "flex",
          alignItems: "center",
          minWidth: "auto",
          padding: "0px",
        }}
        onClick={handleUserIconClick}
      >
        <ListItemIcon style={{ marginRight: "8px" }}>
          <PersonOutline style={{ color: theme.palette.primary.main }} />
        </ListItemIcon>
        {!drawerOpen && !isMobile && (
          <ListItemText
            primary="Ver dados"
            primaryTypographyProps={{
              style: {
                fontFamily: "Poppins",
                color: theme.palette.primary.main,
              },
            }}
          />
        )}
      </MenuItem>

      <IconButton style={{ padding: 0 }} onClick={handleMenuOpen}>
        <MoreVert style={{ color: theme.palette.primary.main }} />
      </IconButton>
      <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
        <IconButton
          style={{
            color: theme.mode === "light" ? "#FFFFFF" : "black",
            background: theme.palette.primary.main,
            width: "32px",
            height: "32px",
            padding: 0,
            borderRadius: "4px",
          }}
          onClick={() => history.push("/tickets")}
        >
          <Close />
        </IconButton>
      </div>
      {!isMobile && (
        <Menu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleMenuClose}
          style={{ zIndex: "1300" }}
          PaperProps={{
            style: {
              maxHeight: "300px",
              width: "250px",
              zIndex: "1300",
            },
          }}
        >
          {ticket.status === "open" && ticketButtonsToTop && (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation()
                return ticket.isGroup
                  ? handleCloseTicketSemModal(ticket.id)
                  : handleOpenCloseModal(ticket.id)
              }}
            >
              <ListItemIcon>
                <CheckOutlined />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    fontFamily: "Poppins",
                    color: theme.palette.primary.neutralTextColor,
                    fontSize: "12px",
                  },
                }}
                primary="Resolver ticket"
              />
            </MenuItem>
          )}

          {ticket.status === "closed" && (
            <MenuItem
              onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
            >
              <ListItemIcon style={{ minWidth: "auto" }}>
                <ReplayOutlined />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    fontFamily: "Poppins",
                    color: theme.palette.primary.neutralTextColor,
                    fontSize: "12px",
                  },
                }}
                primary={i18n.t("messagesList.header.buttons.reopen")}
              />
            </MenuItem>
          )}
          {exportMsgType === "disabled" && (
            <MenuItem
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
              onClick={handleExportMessage}
            >
              <ListItemIcon style={{ minWidth: "56px" }}>
                <img
                  style={{
                    minWidth: "24px",
                    filter:
                      theme.mode !== "light"
                        ? "grayscale(1) brightness(0) invert(1)"
                        : "none",
                  }}
                  src={exportIcon}
                  alt="Exportar"
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    fontFamily: "Poppins",
                    color: theme.palette.primary.neutralTextColor,
                    fontSize: "12px",
                  },
                }}
                primary="Exportar Mensagens"
              />
            </MenuItem>
          )}

          {exportMsgType === "disabled" && (
            <MenuItem
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
              onClick={handleExportPDFMessage}
            >
              <ListItemIcon style={{ minWidth: "56px" }}>
                <img
                  style={{
                    minWidth: "24px",
                    filter:
                      theme.mode !== "light"
                        ? "grayscale(1) brightness(0) invert(1)"
                        : "none",
                  }}
                  src={exportIcon}
                  alt="Exportar"
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    fontFamily: "Poppins",
                    color: theme.palette.primary.neutralTextColor,
                    fontSize: "12px",
                  },
                }}
                primary="Exportar Mensagens PDF"
              />
            </MenuItem>
          )}
          {ticket.status === "open" && (
            <>
              {!hideMettingIconActive && (
                <MenuItem onClick={handleSendMeet}>
                  <ListItemIcon style={{ minWidth: "56px" }}>
                    <img
                      style={{
                        minWidth: "24px",
                        filter:
                          theme.mode !== "light"
                            ? "grayscale(1) brightness(0) invert(1)"
                            : "none",
                      }}
                      src={videoCamera}
                      alt="Video Camera"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        fontFamily: "Poppins",
                        color: theme.palette.primary.neutralTextColor,
                        fontSize: "12px",
                      },
                    }}
                    primary="Enviar link da reunião"
                  />
                </MenuItem>
              )}

              <MenuItem
                onClick={(e) => handleUpdateTicketStatus(e, "pending", null)}
              >
                <ListItemIcon>
                  <UndoOutlined />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontFamily: "Poppins",
                      color: theme.palette.primary.neutralTextColor,
                      fontSize: "12px",
                    },
                  }}
                  primary={i18n.t("messagesList.header.buttons.return")}
                />
              </MenuItem>
              {ticket.status !== "closed" && ticketButtonsToTop && (
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation()
                    handleCloseTicketSemModal(ticket.id)
                  }}
                >
                  <ListItemIcon>
                    <HighlightOffOutlined />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        fontFamily: "Poppins",
                        color: theme.palette.primary.neutralTextColor,
                        fontSize: "12px",
                      },
                    }}
                    primary="Finalizar ticket"
                  />
                </MenuItem>
              )}
              <TicketOptionsMenu
                ticket={ticket}
                anchorEl={anchorEl}
                menuOpen={ticketOptionsMenuOpen}
                handleClose={handleCloseTicketOptionsMenu}
              />
            </>
          )}
        </Menu>
      )}
      {isMobile && (
        <Drawer
          anchor="right"
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleMenuClose}
          style={{ zIndex: "1300" }}
          PaperProps={{
            style: {
              maxHeight: "250px",
              width: "250px",
              zIndex: "1300",
              display: "flex",
              justifyContent: "center",
            },
          }}
        >
          {ticket.status === "open" && ticketButtonsToTop && (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation()
                return ticket.isGroup
                  ? handleCloseTicketSemModal(ticket.id)
                  : handleOpenCloseModal(ticket.id)
              }}
            >
              <ListItemIcon>
                <CheckOutlined />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    fontFamily: "Poppins",
                    color: theme.palette.primary.neutralTextColor,
                    fontSize: "12px",
                  },
                }}
                primary="Resolver ticket"
              />
            </MenuItem>
          )}

          {ticket.status === "closed" && (
            <MenuItem
              onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
            >
              <ListItemIcon style={{ minWidth: "auto" }}>
                <ReplayOutlined />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    fontFamily: "Poppins",
                    color: theme.palette.primary.neutralTextColor,
                    fontSize: "12px",
                  },
                }}
                primary={i18n.t("messagesList.header.buttons.reopen")}
              />
            </MenuItem>
          )}
          {ticket.status === "open" && (
            <>
              {exportMsgType === "disabled" &&
                (user.profile === "admin" || user.super) && (
                  <MenuItem
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                    onClick={handleExportMessage}
                  >
                    <ListItemIcon style={{ minWidth: "56px" }}>
                      <FileDownloadIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          fontFamily: "Poppins",
                          color: theme.palette.primary.neutralTextColor,
                          fontSize: "12px",
                        },
                      }}
                      primary="Exportar Mensagens"
                    />
                  </MenuItem>
                )}

              {!hideMettingIconActive && (
                <MenuItem onClick={handleSendMeet}>
                  <ListItemIcon>
                    <VideocamOutlined />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        fontFamily: "Poppins",
                        color: theme.palette.primary.neutralTextColor,
                        fontSize: "12px",
                      },
                    }}
                    primary="Enviar link da reunião"
                  />
                </MenuItem>
              )}
              <MenuItem
                onClick={(e) => handleUpdateTicketStatus(e, "pending", null)}
              >
                <ListItemIcon>
                  <UndoOutlined />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontFamily: "Poppins",
                      color: theme.palette.primary.neutralTextColor,
                      fontSize: "12px",
                    },
                  }}
                  primary={i18n.t("messagesList.header.buttons.return")}
                />
              </MenuItem>
              {ticket.status !== "closed" && ticketButtonsToTop && (
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation()
                    handleCloseTicketSemModal(ticket.id)
                  }}
                >
                  <ListItemIcon>
                    <HighlightOffOutlined />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        fontFamily: "Poppins",
                        color: theme.palette.primary.neutralTextColor,
                        fontSize: "12px",
                      },
                    }}
                    primary="Finalizar ticket"
                  />
                </MenuItem>
              )}
              <TicketOptionsMenu
                ticket={ticket}
                anchorEl={anchorEl}
                menuOpen={ticketOptionsMenuOpen}
                handleClose={handleCloseTicketOptionsMenu}
              />
            </>
          )}
        </Drawer>
      )}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "300px" : "500px",
            backgroundColor: "#f9f9f9",
            padding: "30px",
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            outline: "none",
          }}
        >
          <h2
            style={{
              marginBottom: "20px",
              color: "#333",
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Poppins",
              fontWeight: "400",
            }}
          >
            Categoria e Subcategoria
          </h2>

          {/* Seleção de Categoria Principal */}
          <div style={{ marginBottom: "20px" }}>
            <label style={{ fontSize: "1.1rem", color: "#555" }}>
              Categoria Principal
            </label>
            <Select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              fullWidth
              variant="outlined"
              style={{
                marginTop: "10px",
                borderRadius: "8px",
                backgroundColor: "#fff",
              }}
            >
              {categories
                .filter((category) => category.parent_id === null)
                .map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
            </Select>
          </div>

          {/* Seleção de Subcategoria (Baseada na Categoria Principal Selecionada) */}
          <div style={{ marginBottom: "20px" }}>
            <label style={{ fontSize: "1.1rem", color: "#555" }}>
              Subcategoria
            </label>
            <Select
              value={selectedSubcategory}
              onChange={(e) => setSelectedSubcategory(e.target.value)}
              fullWidth
              variant="outlined"
              disabled={!selectedCategory} // Desabilitar se nenhuma categoria principal for selecionada
              style={{
                marginTop: "10px",
                borderRadius: "8px",
                backgroundColor: selectedCategory ? "#fff" : "#f0f0f0",
              }}
            >
              {categories
                .find((category) => category.id === selectedCategory)
                ?.subcategories?.map((subcategory) => (
                  <MenuItem key={subcategory.id} value={subcategory.id}>
                    {subcategory.name}
                  </MenuItem>
                ))}
            </Select>
          </div>

          <div
            style={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => setOpenModal(false)}
              style={{
                marginRight: "10px",
                borderRadius: "8px",
                padding: "10px 20px",
                fontSize: "1rem",
                background: "#00000030",
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseTicket}
              style={{
                borderRadius: "8px",
                padding: "10px 20px",
                fontSize: "1rem",
              }}
              disabled={loading}
            >
              Fechar Ticket
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default TicketActionButtonsCustom
