import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@material-ui/core";
import EmailList from "./EmailsComponents/Components/EmailList";
import EmailDetail from "./EmailsComponents/Components/EmailDetail";
import EmailAccounts from "./EmailsComponents/Components/EmailAccounts";
import EmailSender from "../../components/EmailSender";
import themeBigLogo from "../../assets/envAssets/bigLogo";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { EmailsContext } from "../../context/EmailContext";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
    welcomeMsg: {
        backgroundColor: theme.palette.boxticket,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "100%",
        textAlign: "center",
        borderRadius: "8px",
        "@media (max-width: 600px)": {
            display: "none",
        },
    },
    reloadButton: {
        marginBottom: "16px",
        alignSelf: "flex-end",
        "&:hover": {
            background: "transparent"
        }
    },
}));

const EmailInbox = () => {
    const classes = useStyles();
    const {
        emailSettings,
        setEmailSettings,
        selectedSetting,
        setSelectedSetting,
        folders,
        setFolders,
        selectedFolder,
        setSelectedFolder,
        emails,
        setEmails,
        selectedEmail,
        setSelectedEmail,
        currentPage,
        setCurrentPage,
        loading,
        loadingFolders,
        cancelRequests,
        setLoading,
        setLoadingFolders,
        hasMore,
        setHasMore,
        abortController,
        searchTerm
    } = useContext(EmailsContext);
    const [openModal, setOpenModal] = useState(false);

    const pageSize = 30;

    const theme = useTheme();
    useEffect(() => {
        if(emailSettings?.length > 0) {
            return
        }
        const fetchEmailSettings = async () => {
            try {
                const response = await api.get("/email-settings", {
                    signal: abortController.signal,
                });
                setEmailSettings(response.data);
            } catch (error) {
                if (error.name !== "AbortError") {
                    console.error("Error fetching email settings:", error);
                }
                setEmailSettings([]);
            }
        };

        fetchEmailSettings();
        return cancelRequests;
    }, []);

    useEffect(() => {
        if (!selectedSetting) return;

        const fetchFolders = async () => {
            setLoadingFolders(true);
            try {
                const response = await api.get(
                    `/emails/folders?emailSettingId=${selectedSetting.id}`,
                    { signal: abortController.signal }
                );
                setFolders(response.data.folders || []);
            } catch (error) {
                if (error.name !== "AbortError") {
                    console.error("Error fetching folders:", error);
                }
                setFolders([]); // Define array vazio em caso de erro
            } finally {
                setLoadingFolders(false);
            }
        };

        fetchFolders();
        return cancelRequests;
    }, [selectedSetting]);

    useEffect(() => {
        const fetchEmails = async () => {
            setLoading(true);
            try {
                if(currentPage === 1) {
                    setEmails([]);
                    setHasMore(true);
                }
                if(hasMore === false) {
                    return
                }
                const response = await api.get(
                    `/emails/receive?emailSettingId=${selectedSetting.id}&folder=${selectedFolder}&page=${currentPage}&pageSize=${pageSize}&search=${searchTerm}`,
                    { signal: abortController.signal }
                );
                setHasMore(response.data.emails.hasMore)

                const newEmails = (response.data.emails.emails || []).map((email, index) => ({
                    ...email,
                    id: email.id || `${email.receivedAt}-${index}`,
                }));

                if (currentPage === 1) {
                    setEmails(newEmails);
                } else {
                    setEmails((prevEmails) => {
                        const emailIds = new Set(prevEmails.map((email) => email.id));
                        const uniqueEmails = newEmails.filter((email) => !emailIds.has(email.id));
                        return [...prevEmails, ...uniqueEmails];
                    });
                }
            } catch (error) {
                if (error.name !== "AbortError") {
                    console.error("Error fetching emails:", error);
                }
                setEmails([]); // Define array vazio em caso de erro
            } finally {

                setLoading(false);
            }
        };

        fetchEmails();
        return cancelRequests;
    }, [selectedSetting, selectedFolder, currentPage]);
    return (
        <div
            style={{
                display: "flex",
                gap: "16px",
                padding: "16px",
                height: "100%",
                maxHeight: "calc(100vh - 74px)",
            }}
        >
            <div
                style={{
                    flex: "1",
                    maxWidth: "300px",
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    gap: "16px",
                    background: theme.mode === "light" ? "white" : "#2C2B2B",
                    border:  `1px solid ${theme.palette.primary.neutralBorder}`,
                    borderRadius: "8px"
                }}
            >
                <EmailAccounts
                    emailSettings={emailSettings}
                    setCurrentPage={setCurrentPage}
                    setSelectedEmail={setSelectedEmail}
                    selectedSetting={selectedSetting}
                    setSelectedSetting={(setting) => {
                        setSelectedSetting(setting);
                        setCurrentPage(1);
                        setSelectedEmail(null);
                    }}
                    loadingFolders={loadingFolders}
                    loading={loading}
                    folders={folders}
                    setSelectedFolder={(folder) => {
                        setSelectedFolder(folder);
                        setCurrentPage(1);
                    }}
                    selectedFolder={selectedFolder}
                />
            </div>

            <div style={{ flex: "3", background: theme.palette.primary.neutralBackground, border: `1px solid ${theme.palette.primary.neutralBorder}`, borderRadius: "8px" }}>
                {selectedSetting && <EmailSender openModal={openModal} setOpenModal={setOpenModal} setCurrentPage={setCurrentPage} setSelectedSetting={setSelectedSetting} emailSettingId={selectedSetting?.id} selectedEmail={selectedEmail} />}

                {selectedSetting ? (
                    selectedEmail ? (
                        <EmailDetail
                            selectedEmail={selectedEmail}
                            setSelectedEmail={setSelectedEmail}
                            emailSettingId={selectedSetting?.id} 
                        />
                    ) : (
                        <EmailList
                            selectedSetting={selectedSetting}
                            emails={emails}
                            loadingFolders={loadingFolders}
                            setEmails={setEmails}
                            selectedFolder={selectedFolder}
                            loading={loading}
                            folders={folders}
                            currentPage={currentPage}
                            pageSize={pageSize}
                            setSelectedEmail={setSelectedEmail}
                            setCurrentPage={setCurrentPage}
                        />
                        
                    )
                ) : (
                    <Paper square variant="outlined" className={classes.welcomeMsg}>
                        <div>
                            <center>
                                <img
                                    style={{
                                        margin: "0 auto",
                                        width: "130%",
                                        maxHeight: "300px",
                                    }}
                                    src={themeBigLogo[process.env.REACT_APP_THEME_LOGO]}
                                    alt="logologin"
                                />
                            </center>
                        </div>
                    </Paper>
                )}
            </div>
        </div>
    );
};

export default EmailInbox;
