import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		[theme.breakpoints.down('sm')]: {
            display: 'none',
        },
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",

		borderRadius: "8px",
		background: theme.palette.primary.neutralColor,

		
		[theme.breakpoints.down("sm")]: {
			visibility: "unset !important",
			transform: "unset !important",
		},
	},
	header: {
		display: "flex",
		backgroundColor: theme.palette.primary.neutralColor, //DARK MODE PLW DESIGN//
		alignItems: "center",
		padding: "1rem",
		borderBottom: `1px solid ${theme.palette.primary.neutralBorder}`,
		minHeight: "48px",
		justifyContent: "space-between",
	},
	content: {
		display: "flex",
		backgroundColor: theme.palette.primary.neutralColor, //DARK MODE PLW DESIGN//
		flexDirection: "column",
		padding: "1rem 1rem 1rem 1rem",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	contactAvatar: {
		margin: 15,
		width: 100,
		height: 100,
	},

	contactHeader: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		border: "none",
		background: theme.palette.primary.neutralColor,
		"& > *": {
		},
	},

	contactDetails: {
		display: "flex",
		flexDirection: "column",
		border: "none",
		marginTop: "auto"
	},
	contactExtraInfo: {
		border: "none",
		display: "flex",
		alignItems: "center",
		gap: "1rem",
	},
	darkTexts: {
		color: theme.palette.tertiary
	},
	darkBorder: {
		border: `2px solid ${theme.palette.primary.third} !important`
	},
	extraInfoItem: {
		color: `${theme.palette.blackOrWhite} !important`
	},
	contactExtraInfoItem: {
		cursor: "pointer",
		color: `#534D4D70`, fontFamily: "Poppins", fontSize: "12px"
	},
	buttonStyles: {
		color: "#FFFFFF",
		background: theme.palette.primary.main,
		border: "none",
		width: "100%",
		fontFamily: "Poppins",
		textTransform: "capitalize",
		"&:hover": {
			color: "#FFFFFF",
			background: `${theme.palette.primary.main} !important`,
			border: "none",
			width: "100%",
		}
	},
}));

export default useStyles;