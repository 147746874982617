
const handleChange = (e, setFormData, formData) => {
    const { name, type, checked } = e.target;
    if (name === "pop3Enabled" || name === "imapEnabled") {
        if (name === "pop3Enabled" && checked) {
            setFormData((prevData) => ({
                ...prevData,
                pop3Enabled: true,
                imapEnabled: false,
            }));
        }

        if (name === "imapEnabled" && checked) {
            setFormData((prevData) => ({
                ...prevData,
                imapEnabled: true,
                pop3Enabled: false,
            }));
        }
    } else {
        setFormData({
            ...formData,
            [name]: type === "checkbox" || type === "switch" ? checked : e.target.value,
        });
    }
};

export default handleChange;
