import React, { createContext, useReducer, useRef, useState } from "react"
import api from "../../services/api"
import toastError from "../../errors/toastError"

const MessagesContext = createContext()

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload
    const newMessages = []
    const updatedState = [...state]

    messages.forEach((message) => {
      const messageIndex = updatedState.findIndex((m) => m.id === message.id)
      if (messageIndex !== -1) {
        updatedState[messageIndex] = message
      } else {
        newMessages.push(message)
      }
    })

    return [...newMessages, ...updatedState]
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload
    const updatedState = [...state]
    const messageIndex = updatedState.findIndex((m) => m.id === newMessage.id)

    if (messageIndex !== -1) {
      updatedState[messageIndex] = newMessage
    } else {
      updatedState.push(newMessage)
    }

    return [...updatedState]
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload
    const updatedState = [...state]
    const messageIndex = updatedState.findIndex(
      (m) => m.id === messageToUpdate.id
    )

    if (messageIndex !== -1) {
      updatedState[messageIndex] = messageToUpdate
    }

    return [...updatedState]
  }

  if (action.type === "RESET") {
    return []
  }

  return state
}

const MessagesContextProvider = ({ children }) => {
  const [messagesList, dispatch] = useReducer(reducer, [])
  const [hasMore, setHasMore] = useState(false)
  const [loading, setLoading] = useState(false)
  const isFetching = useRef(false)
  const lastTicketId = useRef(null)

  const fetchMessages = async (
    ticketId,
    pageNumber,
    markAsRead,
    currentTicketId,
    scrollToBottom,
    messagesListRef,
    retries = 3,
  ) => {
    if (!ticketId) return

    if (pageNumber === 1) {
      setHasMore(true)
    }
    if (lastTicketId.current !== ticketId && pageNumber !== 1) {
      return
    }

    lastTicketId.current = ticketId

    let attempt = 0

    const cacheKey = `messages_${ticketId}_page_${pageNumber}`;
    const now = Date.now();

    const cachedData = sessionStorage.getItem(cacheKey);
    if (cachedData) {
      const { messages, timestamp, params } = JSON.parse(cachedData);

      if (
        now - timestamp < 15 * 60 * 1000 &&
        params.pageNumber === pageNumber &&
        params.markAsRead === markAsRead
      ) {
        dispatch({ type: "LOAD_MESSAGES", payload: messages });
        setLoading(false);
        setHasMore(true);
        
        if (pageNumber === 1) {
          scrollToBottom()
        }
        isFetching.current = false;

        return;
      }
    }

    while (attempt <= retries) {
      try {
        const { data } = await api.get(`/messages/${ticketId}`, {
          params: { pageNumber, markAsRead: !markAsRead },
        })

        if (currentTicketId?.current === ticketId) {
          dispatch({ type: "LOAD_MESSAGES", payload: data.messages })
          setHasMore(data.hasMore)
          setLoading(false)
        }

        if (pageNumber === 1 && data.messages.length > 1) {
          scrollToBottom()
        }

        setLoading(false)

        sessionStorage.setItem(
          cacheKey,
          JSON.stringify({
            messages: data.messages,
            timestamp: now,
            params: { pageNumber, markAsRead },
          })
        );

        return
      } catch (err) {
        attempt++
        if (attempt > retries) {
          toastError(err)
        } else {
          await new Promise((resolve) => setTimeout(resolve, 3000))
        }
      } finally {
        setLoading(false)
        isFetching.current = false
      }
    }
    return
  }

  const clearTicketCache = (ticketId) => {
    Object.keys(sessionStorage).forEach((key) => {
      if (key.startsWith(`messages_${ticketId}_`)) {
        sessionStorage.removeItem(key)
      }
    })
  }

  const updateMessageInCache = (ticketId, message) => {
    if (!ticketId || !message) return
    setHasMore(true)
    clearTicketCache(ticketId)
  }

  window.addEventListener("beforeunload", () => {
    sessionStorage.clear()
  })

  return (
    <MessagesContext.Provider
      value={{
        messagesList,
        dispatch,
        hasMore,
        loading,
        fetchMessages,
        setLoading,
        setHasMore,
        updateMessageInCache,
        clearTicketCache,
        isFetching,
        lastTicketId,
      }}
    >
      {children}
    </MessagesContext.Provider>
  )
}

export { MessagesContext, MessagesContextProvider }
