import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    emailsListStyles: {
        height: "calc(100vh - 180px)",
        overflow: "auto",
        ...theme.scrollbarStyles
    }
}));

export default useStyles;