import React, { useState, useEffect, createContext } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const TabulacaoContext = createContext();

const TabulacaoContextProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCategories = async () => {
    if (categories.length > 0) {
      return categories;
    }
    try {
      setLoading(true);
      const companyId = localStorage.getItem("companyId");
      if (!companyId) {
        setLoading(false);
        return;
      }

      const { data } = await api.get("/api/categories/list", {
        params: { companyId },
      });

      setCategories(data);
    } catch (error) {
      console.error(
        "Erro ao carregar as categorias:",
        error.response || error.message
      );
      toastError("Erro ao carregar as categorias.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <TabulacaoContext.Provider
      value={{
        categories,
        setCategories,
        fetchCategories,
        tabLoading: loading,
      }}
    >
      {children}
    </TabulacaoContext.Provider>
  );
};

export { TabulacaoContext, TabulacaoContextProvider };