import React, { useState, useEffect, createContext, useRef, useContext } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../Auth/AuthContext";

const QueueContext = createContext();

const QueueContextProvider = ({ children }) => {
    const [allQueues, setAllQueues] = useState([]);
    const [queues, setQueues] = useState([]);
    const isMounted = useRef(true);
    const { user } = useContext(AuthContext);
    const [whatsapps, setWhatsapps] = useState([]);
    const [selectedWhatsapp, setSelectedWhatsapp] = useState("");
    const [selectedQueue, setSelectedQueue] = useState("");
    const [loading, setLoading] = useState(false);
    const [ticket, setTicket] = useState(null);
    const { companyId, whatsappId } = user;

    return (
        <QueueContext.Provider
            value={{
                allQueues,
                setAllQueues,
                queues,
                setQueues,
                whatsapps,
                setWhatsapps,
                selectedWhatsapp,
                setSelectedWhatsapp,
                selectedQueue,
                setSelectedQueue,
                loading,
                setLoading,
                ticket,
                setTicket,
                isMounted,
                companyId,
                whatsappId
            }}
        >
            {children}
        </QueueContext.Provider>
    );
};

export { QueueContext, QueueContextProvider };
