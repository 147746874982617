import React, { useState, useEffect, useContext, useRef } from "react"

import * as Yup from "yup"
import { Formik, Form, Field } from "formik"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import { green } from "@material-ui/core/colors"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import CircularProgress from "@material-ui/core/CircularProgress"

import { i18n } from "../../translate/i18n"

import api from "../../services/api"
import toastError from "../../errors/toastError"
import { FormControl, Grid, IconButton } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import moment from "moment"
import { AuthContext } from "../../context/Auth/AuthContext"
import { isArray, capitalize } from "lodash"
import DeleteOutline from "@material-ui/icons/DeleteOutline"
import AttachFile from "@material-ui/icons/AttachFile"
import { head } from "lodash"
import ConfirmationModal from "../ConfirmationModal"
import MessageVariablesPicker from "../MessageVariablesPicker"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    minWidth: 120,
  },
  modalTitle: {
    fontFamily: "Poppins",
    color: theme.palette.primary.neutralTextColor,
    fontWeight: "400",
    fontSize: "16px",
    padding: "1rem",
  },
  btnStyles: {
    border: `none`,
    borderRadius: "4px",
    color: "white",
    textTransform: "capitalize",
    background: theme.palette.primary.main,
    "&:hover": {
      border: `none`,
      background: theme.palette.primary.main,
    },
  },
}))

const ScheduleSchema = Yup.object().shape({
  body: Yup.string().min(5, "Mensagem muito curta").required("Obrigatório"),
  contactId: Yup.number().required("Obrigatório"),
  sendAt: Yup.string().required("Obrigatório"),
})

const ScheduleModal = ({
  open,
  onClose,
  scheduleId,
  contactId,
  cleanContact,
  reload,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { user } = useContext(AuthContext)

  const initialState = {
    body: "",
    contactId: "",
    sendAt: moment().add(1, "hour").format("YYYY-MM-DDTHH:mm"),
    sentAt: "",
  }

  const initialContact = {
    id: "",
    name: "",
  }

  const [schedule, setSchedule] = useState(initialState)
  const [currentContact, setCurrentContact] = useState(initialContact)
  const [contacts, setContacts] = useState([initialContact])
  const [attachment, setAttachment] = useState(null)
  const attachmentFile = useRef(null)
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const messageInputRef = useRef()

  useEffect(() => {
    if (contactId && contacts.length) {
      const contact = contacts.find((c) => c.id === contactId)
      if (contact) {
        setCurrentContact(contact)
      }
    }
  }, [contactId, contacts])

  useEffect(() => {
    const { companyId } = user
    if (open) {
      try {
        ;(async () => {
          const { data: contactList } = await api.get("/contacts/list", {
            params: { companyId: companyId },
          })
          let customList = contactList.map((c) => ({ id: c.id, name: c.name }))
          if (isArray(customList)) {
            setContacts([{ id: "", name: "" }, ...customList])
          }
          if (contactId) {
            setSchedule((prevState) => {
              return { ...prevState, contactId }
            })
          }

          if (!scheduleId) return

          const { data } = await api.get(`/schedules/${scheduleId}`)
          setSchedule((prevState) => {
            return {
              ...prevState,
              ...data,
              sendAt: moment(data.sendAt).format("YYYY-MM-DDTHH:mm"),
            }
          })
          setCurrentContact(data.contact)
        })()
      } catch (err) {
        toastError(err)
      }
    }
  }, [scheduleId, contactId, open, user])

  const handleClose = () => {
    onClose()
    setAttachment(null)
    setSchedule(initialState)
  }

  const handleAttachmentFile = (e) => {
    const file = head(e.target.files)
    if (file) {
      setAttachment(file)
    }
  }

  const handleSaveSchedule = async (values) => {
    const scheduleData = { ...values, userId: user.id }
    try {
      if (scheduleId) {
        await api.put(`/schedules/${scheduleId}`, scheduleData)
        if (attachment != null) {
          const formData = new FormData()
          formData.append("file", attachment)
          await api.post(`/schedules/${scheduleId}/media-upload`, formData)
        }
      } else {
        const { data } = await api.post("/schedules", scheduleData)
        if (attachment != null) {
          const formData = new FormData()
          formData.append("file", attachment)
          await api.post(`/schedules/${data.id}/media-upload`, formData)
        }
      }
      toast.success(i18n.t("scheduleModal.success"))
      if (typeof reload == "function") {
        reload()
      }
      if (contactId) {
        if (typeof cleanContact === "function") {
          cleanContact()
          history.push("/schedules")
        }
      }
    } catch (err) {
      toastError(err)
    }
    setCurrentContact(initialContact)
    setSchedule(initialState)
    handleClose()
  }
  const handleClickMsgVar = async (msgVar, setValueFunc) => {
    const el = messageInputRef.current
    const firstHalfText = el.value.substring(0, el.selectionStart)
    const secondHalfText = el.value.substring(el.selectionEnd)
    const newCursorPos = el.selectionStart + msgVar.length

    setValueFunc("body", `${firstHalfText}${msgVar}${secondHalfText}`)

    await new Promise((r) => setTimeout(r, 100))
    messageInputRef.current.setSelectionRange(newCursorPos, newCursorPos)
  }

  const deleteMedia = async () => {
    if (attachment) {
      setAttachment(null)
      attachmentFile.current.value = null
    }

    if (schedule.mediaPath) {
      await api.delete(`/schedules/${schedule.id}/media-upload`)
      setSchedule((prev) => ({
        ...prev,
        mediaPath: null,
      }))
      toast.success(i18n.t("scheduleModal.toasts.deleted"))
      if (typeof reload == "function") {
        reload()
      }
    }
  }
  const theme = useTheme()
  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={i18n.t("scheduleModal.confirmationModal.deleteTitle")}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={deleteMedia}
      >
        {i18n.t("scheduleModal.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <Dialog open={open} onClose={handleClose} fullWidth scroll="paper">
        <h2
          style={{
            background: theme.palette.primary.neutralColor,
            borderBottom: `1px solid ${theme.palette.primary.neutralBorder}`,
          }}
          className={classes.modalTitle}
          id="form-dialog-title"
        >
          {schedule.status === "ERRO"
            ? "Erro de Envio"
            : `Mensagem ${capitalize(schedule.status)}`}
        </h2>
        <div style={{ display: "none" }}>
          <input
            type="file"
            accept=".png,.jpg,.jpeg"
            ref={attachmentFile}
            onChange={(e) => handleAttachmentFile(e)}
          />
        </div>
        <Formik
          initialValues={schedule}
          enableReinitialize={true}
          validationSchema={ScheduleSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveSchedule(values)
              actions.setSubmitting(false)
            }, 400)
          }}
        >
          {({ touched, errors, isSubmitting, values, setFieldValue }) => (
            <Form style={{ background: theme.palette.primary.neutralColor }}>
              <DialogContent style={{ padding: "1rem" }}>
                <div className={classes.multFieldLine}>
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      fullWidth
                      value={currentContact}
                      options={
                        contacts.length === 0
                          ? [{ name: "Loading...", id: "loading" }]
                          : contacts
                      }
                      onChange={(e, contact) => {
                        const contactId = contact ? contact.id : ""
                        setSchedule({ ...schedule, contactId })
                        setCurrentContact(contact ? contact : initialContact)
                      }}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) =>
                        value.id === option.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Contato"
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <br />
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    rows={9}
                    multiline={true}
                    label={i18n.t("scheduleModal.form.body")}
                    name="body"
                    inputRef={messageInputRef}
                    error={touched.body && Boolean(errors.body)}
                    helperText={touched.body && errors.body}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <Grid item>
                  <MessageVariablesPicker
                    disabled={isSubmitting}
                    onClick={(value) => handleClickMsgVar(value, setFieldValue)}
                  />
                </Grid>
                <br />
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("scheduleModal.form.sendAt")}
                    type="datetime-local"
                    name="sendAt"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={touched.sendAt && Boolean(errors.sendAt)}
                    helperText={touched.sendAt && errors.sendAt}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                {(schedule.mediaPath || attachment) && (
                  <Grid xs={12} item>
                    <Button startIcon={<AttachFile />}>
                      {attachment ? attachment.name : schedule.mediaName}
                    </Button>
                    <IconButton
                      onClick={() => setConfirmationOpen(true)}
                      color="secondary"
                    >
                      <DeleteOutline color="secondary" />
                    </IconButton>
                  </Grid>
                )}
              </DialogContent>
              <DialogActions
                style={{
                  borderTop: `1px solid ${theme.palette.primary.neutralBorder}`,
                }}
              >
                {!attachment && !schedule.mediaPath && (
                  <Button
                    className={classes.btnStyles}
                    color="primary"
                    onClick={() => attachmentFile.current.click()}
                    disabled={isSubmitting}
                    variant="outlined"
                  >
                    {i18n.t("quickMessages.buttons.attach")}
                  </Button>
                )}
                <Button
                  className={classes.btnStyles}
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("scheduleModal.buttons.cancel")}
                </Button>
                {(schedule.sentAt === null || schedule.sentAt === "") && (
                  <Button
                    className={classes.btnStyles}
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                  >
                    {scheduleId
                      ? `${i18n.t("scheduleModal.buttons.okEdit")}`
                      : `${i18n.t("scheduleModal.buttons.okAdd")}`}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  )
}

export default ScheduleModal
