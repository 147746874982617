import React, { useContext, useState } from "react";
import {
  Chip,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Button,
  useTheme
} from "@material-ui/core";

import { useHistory, useParams } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useDate } from "../../hooks/useDate";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GroupIcon from "@material-ui/icons/Group";
import ConfirmationModal from "../../components/ConfirmationModal";
import api from "../../services/api";
import truncateText from "../../utils/truncateText";
import ToolTip from "../../components/ToolTips";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    height: "calc(100% - 58px)",
    overflow: "hidden",
    borderRadius: 0,
    backgroundColor: "transparent", //DARK MODE PLW DESIGN//
  },
  chatList: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    padding:"0",
    overflowY: "auto",
    ...theme.scrollbarStyles,
  },
  listItem: {
    cursor: "pointer",
    color: theme.palette.primary.mainReverseByWhite,
    fontFamily: "Poppins",
    fontWeight: "500",
    boxShadow: `0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)`,
    transition: ".3s",
    display: "flex",
    alignItems: "start",
    "& span": {
      color: theme.palette.primary.mainReverseByWhite,
      fontFamily: "Poppins",
      fontWeight: "500",
    },
    btnStyles: {
      minWidth: "25px",
      width: "25px",
      height: "25px",
      minHeight: "25px",
      padding: "0",
      border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
      borderRadius: "8px",
      color: theme.palette.primary.mainReverseByWhite,
      background: "transparent",
      "&:hover": {
        background: "transparent"
      }
    }
  },
}));

export default function ChatList({
  chats,
  handleSelectChat,
  handleDeleteChat,
  handleEditChat,
  setMobileChat
}) {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const { datetimeToClient } = useDate();
  const theme = useTheme();
  const [confirmationModal, setConfirmModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState({});

  const { id } = useParams();

  const goToMessages = async (chat) => {
    setMobileChat(true)
    if (unreadMessages(chat) > 0) {
      try {
        await api.post(`/chats/${chat.id}/read`, { userId: user.id });
      } catch (err) {}
    }

    history.push(`/chats/${chat.uuid}`);
    handleSelectChat(chat);

    return chat;
  };

  const handleDelete = () => {
    handleDeleteChat(selectedChat);
  };

  const unreadMessages = (chat) => {
    const currentUser = chat.users.find((u) => u.userId === user.id);
    return currentUser?.unreads;
  };

  const getPrimaryText = (chat) => {
    const mainText = chat.title;
    const unreads = unreadMessages(chat);
    return (
      <>
        {mainText}
        {unreads > 0 && (
          <Chip
            size="small"
            style={{ marginLeft: 5 }}
            label={unreads}
            color="secondary"
          />
        )}
      </>
    );
  };

  const getSecondaryText = (chat) => {
    return chat.lastMessage !== ""
      ? `${datetimeToClient(chat.updatedAt)}: ${chat.lastMessage}`
      : "Criado";
  };

  const getItemStyle = (chat) => {
    return {
      borderLeft: chat.uuid === id ? "6px solid #002d6e" : "6px solid gray",
      height: "100px",
      borderRadius: "8px",
      backgroundColor: theme.palette.dark,
      position: "relative"
    };
  };
  return (
    <>
      <ConfirmationModal
        title={"Excluir Conversa"}
        open={confirmationModal}
        onClose={setConfirmModalOpen}
        onConfirm={handleDelete}
      >
        Esta ação não pode ser revertida, confirmar?
      </ConfirmationModal>
      <div className={classes.mainContainer}>
        <div className={classes.chatList}>
          <List >
            {Array.isArray(chats) &&
              chats.length > 0 &&
              chats.map((chat, key) => (
                <ListItem
                  onClick={() => goToMessages(chat)}
                  key={key}
                  className={classes.listItem}
                  style={getItemStyle(chat)}
                  button
                >
                  <div style={{display: "flex", gap: "1rem", justifyContent: "space-between", flexDirection: "column"}}>
                    <div style={{display: "flex", gap: "1rem", alignItems: "center"}}>
                      <GroupIcon />
                      <ListItemText
                        primary={getPrimaryText(chat)}
                      />
                    </div>
                    <ToolTip title={getSecondaryText(chat)}>
                      <span>
                        {truncateText(getSecondaryText(chat).length > 0 ? getSecondaryText(chat) : "Criado")}
                      </span>
                    </ToolTip>
                  </div>
                  {user.profile === "admin" && (
                    <ListItemSecondaryAction style={{position: "absolute", right: "1rem", display: "flex", gap: "0.5rem"}}>
                      <Button
                        onClick={() => {
                          goToMessages(chat).then(() => {
                            handleEditChat(chat);
                          });
                        }}
                        color="primary"
                        className={classes.btnStyles}
                        variant="contained"
                        style={{
                          minWidth: "25px",
                          width: "25px",
                          height: "25px",
                          minHeight: "25px",
                          padding: "0",
                          fontSize :"12px",
                          border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
                          borderRadius: "8px",
                          color: theme.palette.primary.mainReverseByWhite,
                          background: "transparent",
                        }}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        onClick={() => {
                          setSelectedChat(chat);
                          setConfirmModalOpen(true);
                        }}
                        color="primary"
                        className={classes.btnStyles}
                        variant="contained"
                        style={{
                          minWidth: "25px",
                          width: "25px",
                          height: "25px",
                          minHeight: "25px",
                          padding: "0",
                          fontSize :"12px",
                          border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
                          borderRadius: "8px",
                          color: theme.palette.primary.mainReverseByWhite,
                          background: "transparent",
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
          </List>
        </div>
      </div>
    </>
  );
}
