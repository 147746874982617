import React, { useState, useEffect } from "react";
import { Switch, CircularProgress, Typography, makeStyles } from "@material-ui/core";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0",
        marginTop: "1rem"
    },
    title: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: "400",
        color: theme.palette.primary.neutralTextColor,
        display: "flex",
        fontSize: "14px",
        alignItems: "center",
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    switchContainer: {
        display: "flex",
        alignItems: "center",
    },
    statusText: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: "500",
    },
    switch: {
        "& .Mui-checked": {
            color: theme.palette.primary.main, // Define a cor principal para o estado "checked"
        },
        "& .Mui-checked + .MuiSwitch-track": {
            backgroundColor: theme.palette.primary.main, // A cor do fundo quando ativado
        },
    },
}));

const BotSwitch = ({ contactId, initialDisableBot, contact }) => {
    const [disableBot, setDisableBot] = useState(initialDisableBot);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        setDisableBot(initialDisableBot); // Atualiza quando o valor inicial muda
    }, [initialDisableBot]);

    const handleToggle = async () => {
        setDisableBot(!disableBot);
        setLoading(true);
        try {
            await api.put(`/contacts/${contactId}`, { ...contact, disableBot: !disableBot });
        } catch (err) {
            toastError(err);
            setDisableBot(disableBot); // Volta ao estado anterior se a requisição falhar
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={classes.container}>
            <h3 className={classes.title}>ChatBot</h3>
            <Switch
                className={classes.switch}
                size="small"
                checked={!disableBot}
                onChange={handleToggle}
                disabled={loading}
            />
        </div>
    );
};

export default BotSwitch;
