import React from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { i18n } from "../../translate/i18n";
import { Grid, TextField, Typography, Chip, useTheme } from "@material-ui/core";
import { UsersFilter } from "../../components/UsersFilter";
import { WhatsappsFilter } from "../../components/WhatsappsFilter";
import { StatusFilter } from "../../components/StatusFilter";
import QueueSelect from "../../components/QueueSelect";
import Autocomplete from "@material-ui/lab/Autocomplete";

const FilterComponent = ({
    classes,
    loading,
    setSearchParam,
    setSelectedContactId,
    setSelectedWhatsapp,
    setSelectedStatus,
    queueIds,
    setQueueIds,
    setUserIds,
    options,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    categoryIds,
    setCategoryIds,
    timeDifference,
    setTimeDifference,
    categories,
    handleFilter,
    tags,
    selectedTags,
    setSelectedTags,
    setUserSummaryOpen
}) => {
    const theme = useTheme();
    return (
        <>
            <Paper style={{ width: "100%" }} className={classes.filterContainer}>
                <Typography variant="h6" className={classes.headerText}>Filtros de Pesquisa</Typography>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={3}>
                        <Autocomplete
                            options={options}
                            loading={loading}
                            size="small"
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={i18n.t("newTicketModal.fieldLabel")}
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => setSearchParam(e.target.value)}
                                />
                            )}
                            onChange={(e, newValue) => setSelectedContactId(newValue?.id || null)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}><WhatsappsFilter onFiltered={setSelectedWhatsapp} /></Grid>
                    <Grid item xs={12} md={3}><StatusFilter onFiltered={setSelectedStatus} /></Grid>
                    <Grid item xs={12} md={3}><UsersFilter onFiltered={setUserIds} /></Grid>
                    <Grid item xs={12} md={3}>
                        <QueueSelect selectedQueueIds={queueIds} onChange={setQueueIds} fullWidth />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            label="Qualidade (s)"
                            type="number"
                            value={timeDifference}
                            onChange={(e) => setTimeDifference(e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Autocomplete
                            multiple
                            options={categories.flatMap(cat => [cat, ...(cat.subcategories || [])])}
                            getOptionLabel={(option) => option.name}
                            value={categories
                                .flatMap(cat => [cat, ...(cat.subcategories || [])])
                                .filter(cat => categoryIds.includes(cat.id))
                            }
                            onChange={(e, newValue) => {
                                const selectedIds = newValue.map(val => val.id);

                                setCategoryIds(selectedIds);
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={option.name}
                                        {...getTagProps({ index })}
                                        style={{
                                            backgroundColor: option.parent_id ? "#bfbfbf" : "#ffc107",
                                            color: "#fff",
                                            textShadow: "1px 1px 1px #000"
                                        }}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Tabulação"
                                    size="small"
                                    fullWidth
                                />
                            )}
                            renderOption={(option) => {
                                const isSubcategory = option.parent_id !== null;
                                return (
                                    <span style={{ paddingLeft: isSubcategory ? 20 : 0, fontWeight: isSubcategory ? 'normal' : 'bold' }}>
                                        {option.name}
                                    </span>
                                );
                            }}
                        />
                    </Grid>
                    {/* Campo normal (tags com kanban: 0) */}
                    <Grid item xs={12} md={3}>
                        <Autocomplete
                            multiple
                            size="small"
                            options={tags.filter((tag) => tag.kanban === 0)}
                            value={selectedTags.filter((tag) => tag.kanban === 0)}
                            onChange={(e, v) => {
                                // Atualiza somente o estado geral com as mudanças
                                const updatedTags = [
                                    ...v,
                                    ...selectedTags.filter((tag) => tag.kanban === 1),
                                ];
                                setSelectedTags(updatedTags);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" placeholder="Tags" />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        style={{
                                            backgroundColor: `${theme.palette.primary.mainReverseByWhite}70`,
                                            border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
                                            fontFamily: "Poppins",
                                            color: "white",
                                            textShadow: "1px 1px 1px #000",

                                            borderRadius: "8px",
                                        }}
                                        label={option.name}
                                        {...getTagProps({ index })}
                                        key={option.id}
                                    />
                                ))
                            }
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Autocomplete
                            multiple
                            size="small"
                            options={tags.filter((tag) => tag.kanban === 1)}
                            value={selectedTags.filter((tag) => tag.kanban === 1)}
                            onChange={(e, v) => {
                                const updatedTags = [
                                    ...selectedTags.filter((tag) => tag.kanban === 0),
                                    ...v,
                                ];
                                setSelectedTags(updatedTags);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" placeholder="Kanban Tags" />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        style={{
                                            backgroundColor: `${theme.palette.secondary.main}70`,
                                            border: `2px solid ${theme.palette.secondary.main}`,
                                            fontFamily: "Poppins",
                                            textShadow: "1px 1px 1px #000",
                                            color: "white",
                                            borderRadius: "8px",
                                        }}
                                        label={option.name}
                                        {...getTagProps({ index })}
                                        key={option.id}
                                    />
                                ))
                            }
                        />
                    </Grid>


                    <Grid item xs={12} md={3}>
                        <TextField
                            label="Data Inicial"
                            type="date"
                            value={dateFrom}
                            onChange={(e) => setDateFrom(e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            label="Data Final"
                            type="date"
                            value={dateTo}
                            onChange={(e) => setDateTo(e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3} className={classes.actionButtons}>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <Button variant="contained" color="primary" onClick={() => setUserSummaryOpen(true)}>
                                Resumo
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => handleFilter(1)}>
                                {i18n.t("reports.buttons.filter")}
                            </Button>
                        </div>
                    </Grid>

                </Grid>
            </Paper>
        </>
    );
};

export default FilterComponent;
