import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ContactModal from "../../../../components/ContactModal";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import NewTicketModal from "../../../../components/NewTicketModal";
import UserSelectModal from "../../../../components/ContactToUser";
import ContactImportWpModal from "../../../../components/ContactImportWpModal";

const Modals = ({
    userSelectModalOpen,
    setImportContactModalOpen,
    selectedContactIdForUser,
    handleCloseUserSelectModal,
    newTicketModalOpen,
    contactTicket,
    handleCloseOrOpenTicket,
    importContactModalOpen,
    hideNum,
    user,
    contactModalOpen,
    handleCloseContactModal,
    selectedContactId,
    confirmOpen,
    setConfirmOpen,
    deletingContact,
    handleDeleteContact,
    handleimportContact,
    selectedContact
}) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const handleConfirmDelete = async () => {
        if (!deletingContact) return;

        setIsDeleting(true);
        const toastId = toast.loading("Excluindo contato...");

        try {
            await handleDeleteContact(deletingContact.id);
            toast.update(toastId, {
                render: "Contato excluído com sucesso!",
                type: "success",
                isLoading: false,
                autoClose: 3000
            });
        } catch (error) {
            toast.update(toastId, {
                render: "Erro ao excluir contato!",
                type: "error",
                isLoading: false,
                autoClose: 3000
            });
        } finally {
            setIsDeleting(false);
            setConfirmOpen(false);
        }
    };

    return (
        <>
            {userSelectModalOpen && (
                <UserSelectModal
                    contactId={selectedContactIdForUser}
                    onClose={handleCloseUserSelectModal}
                />
            )}
            {newTicketModalOpen && (
                <NewTicketModal
                    modalOpen={newTicketModalOpen}
                    initialContact={contactTicket}
                    onClose={(ticket) => {
                        handleCloseOrOpenTicket(ticket);
                    }}
                />
            )}

            <ContactImportWpModal
                isOpen={importContactModalOpen}
                handleClose={() => setImportContactModalOpen(false)}
                selectedTags={null}
                hideNum={hideNum}
                userProfile={user.profile}
            />

            <ContactModal
                user={user}
                open={contactModalOpen}
                onClose={handleCloseContactModal}
                aria-labelledby="form-dialog-title"
                contactId={selectedContactId}
                ticket={selectedContact?.tickets?.length ? selectedContact?.tickets[0] : null}
            />

            <ConfirmationModal
                title={
                    deletingContact
                        ? `Tem certeza que deseja excluir ${deletingContact.name}?`
                        : "Importar contatos"
                }
                open={confirmOpen}
                onClose={setConfirmOpen}
                onConfirm={deletingContact ? handleConfirmDelete : handleimportContact}
            >
                {deletingContact
                    ? "Esta ação não pode ser desfeita. Tem certeza que deseja excluir este contato?"
                    : "Deseja importar contatos agora?"}
            </ConfirmationModal>
        </>
    );
};

export default Modals;
