import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "./style.css";
import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsListCustom";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Box, Button, Snackbar } from "@material-ui/core";
import api from "../../services/api";
import { TicketsListGroup } from "../TicketsListGroup";
import TicketTabsHeader from "../TicketTabsHeader";
import SocialMediaFilter from "../SocialMediaFilter";

import { DeleteOutline } from "@material-ui/icons";
import { SettingsContext } from "../../context/SettingsContext";
import useStyles from "./style";

const TicketsManagerTabs = ({ setMobileUserOpen, TicketsListCustom, setHideMessages }) => {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    const handleElementClick = (event) => {
      const target = event.target;
      if (!target.classList.contains('cmp-tickers__close-every-ticket')) {
        const closeEveryTicketElement = document.querySelector('.cmp-tickers__close-every-ticket');

        if (closeEveryTicketElement) {
          closeEveryTicketElement.classList.add('cmp-tickers__close-every-ticket--disabled');
        }
      }
    };

    document.addEventListener('click', handleElementClick);

    return () => {
      document.removeEventListener('click', handleElementClick);
    };
  }, []);
  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [filterModalOpen, setFilterModal] = useState(false);
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);
  const { profile } = user;
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const userQueueIds = user?.queues?.map((q) => q.id) || [];
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [contactType, setContactType] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selecteds, setSelecteds] = useState([]);
  const [foundTags, setFoundTags] = useState([]);
  const { fetchSettings, showGroupTab, settings } = useContext(SettingsContext);
  const theme = useTheme();
  useEffect(() => {
    if(!settings.length) {
      fetchSettings();
    }

    if (user.profile.toUpperCase() === "ADMIN" || user.profile.toUpperCase() === "SUPERVISOR") {
      setShowAllTickets(true);
    }
  }, []);

  useEffect(() => {
    if (tab === "search") {
      searchInputRef.current.focus();
    }
  }, [tab]);

  let searchTimeout;

  const handleSearch = (e) => {
    const searchedTerm = e.target.value;
    clearTimeout(searchTimeout);
    setSearchParam(searchedTerm);

    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      return;
    }

    searchTimeout = setTimeout(() => {
      setTab("search");
    }, 1000);
  };


  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
  };

  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const handleSelectedTags = (selecteds) => {
    const tags = selecteds.map((t) => t.id);
    setSelectedTags(tags);
    setTab("search");

  };
  const handleSelectedStatus = (selecteds) => {
    setSelectedStatus(selecteds);
    setTab("search");

  }

  const handleSelectedUsers = (selecteds) => {
    const users = selecteds.map((t) => t.id);
    setSelectedUsers(users);
    setTab("search");
  };

  const CloseAllTicket = async () => {
    try {
      await api.post("/tickets/closeAll", {
        status: tabOpen,
        selectedQueueIds,
      });

      handleSnackbarClose();

    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSocialClick = (type) => {
    setContactType(type);
  }
  return (
    <>
      <div className={classes.neutralBackground} style={{ paddingTop: "0.6rem", borderRadius: "8px" }}>
        <SocialMediaFilter handleSocialButtonClick={handleSocialClick} contactType={contactType} />
      </div>
      <Paper elevation={0} className={`${classes.ticketsWrapper} cmp-tickets__rigth-container`}>
        {newTicketModalOpen && <NewTicketModal
          modalOpen={newTicketModalOpen}
          onClose={(ticket) => {
            handleCloseOrOpenTicket(ticket);
          }}
        />}
        <TicketTabsHeader
          setSelectedQueueIds={setSelectedQueueIds}
          tab={tab}
          classes={classes}
          handleChangeTab={handleChangeTab}
          setFilterModal={setFilterModal}
          showGroupTab={showGroupTab}
          filterModalOpen={filterModalOpen}
          setNewTicketModalOpen={setNewTicketModalOpen}
          setTab={setTab}
          searchInputRef={searchInputRef}
          searchParam={searchParam}
          handleSearch={handleSearch}
          selectedQueueIds={selectedQueueIds} user={user}
          profile={profile}
          setSelecteds={setSelecteds}
          setSelectedUsers={setSelectedUsers}
          selectedUsers={selectedUsers}
          handleSelectedUsers={handleSelectedUsers}
          selecteds={selecteds}
          setSelectedStatus={setSelectedStatus}
          selectedStatus={selectedStatus}
          handleSelectedStatus={handleSelectedStatus}
          setFoundTags={setFoundTags}
          setSelectedTags={setSelectedTags}
          handleSelectedTags={handleSelectedTags}
          foundTags={foundTags} selectedTags={selectedTags}
        />

        <TabPanel value={tab} name="open" className={`${classes.ticketsWrapper} ${classes.firstContainer}`}>
          <Tabs
            value={tabOpen}
            onChange={handleChangeTabOpen}
            indicatorColor="primary"
            textColor="primary"
            className="cmp-tickets__header-buttons"
          >
            <Tab
              label={
                <Box display="flex" alignItems="center" gap={2}>
                  <span className={classes.statusStyles}>Abertos</span>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="primary.main"
                    color="white"
                    width="15px"
                    minWidth="15px"
                    height="15px"
                    fontSize="10px"
                    borderRadius="100%"
                  >
                    {openCount}
                  </Box>
                </Box>
              }
              value={"open"}
            />
            <Tab
              label={
                <Box display="flex" alignItems="center" gap={2}>
                  <span className={classes.statusStyles}>Pendentes</span>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="primary.main"
                    color="white"
                    borderRadius="100%"
                    width="15px"
                    height="15px"
                    fontSize="10px"
                  >
                    {pendingCount}
                  </Box>
                </Box>
              }
              value={"pending"}
            />
            <Tab
              label={
                <Box display="flex" alignItems="center" gap={2}>
                  <span className={classes.statusStyles}>Resolvidos</span>
                </Box>
              }
              value={"closed"}
            />
          </Tabs>

          <Paper className={`${classes.ticketsWrapper}`}>
            <TicketsList
              status="open"
              showAll={showAllTickets}
              setHideMessages={setHideMessages}
              selectedQueueIds={selectedQueueIds}
              setMobileUserOpen={setMobileUserOpen}
              TicketsListCustom={TicketsListCustom}
              contactType={contactType}
              updateCount={(val) => setOpenCount(val)}
              style={applyPanelStyle("open")}
            />
            <TicketsList
              status="pending"
              contactType={contactType}
              selectedQueueIds={selectedQueueIds}
              updateCount={(val) => setPendingCount(val)}
              style={applyPanelStyle("pending")}
            />
            <TicketsList
              status="closed" 
              showAll={true}
              tab={tabOpen}
              contactType={contactType}
              selectedQueueIds={selectedQueueIds}
              style={{ ...applyPanelStyle("closed"), width: "500px !important", heigth: "500px !important", display: tabOpen === "closed" ? "flex" : "none !important" }}
            />
          </Paper>
        </TabPanel>
        <TabPanel value={tab} name="group" className={`${classes.ticketsWrapper} ${classes.firstContainer}`}>
          <Tabs
            value={tabOpen}
            onChange={handleChangeTabOpen}
            indicatorColor="primary"
            textColor="primary"
            className="cmp-tickets__header-buttons"

          >
            <Tab
              label={
                <Box display="flex" alignItems="center" gap={2}>
                  <span className={classes.statusStyles}>Abertos</span>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="primary.main"
                    color="white"
                    width="15px"
                    minWidth="15px"
                    height="15px"
                    fontSize="10px"
                    borderRadius="100%"
                  >
                    {openCount}
                  </Box>
                </Box>
              }
              value={"open"}
            />
            <Tab
              label={
                <Box display="flex" alignItems="center" gap={2}>
                  <span className={classes.statusStyles}>Pendentes</span>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="primary.main"
                    color="white"
                    width="15px"
                    minWidth="15px"
                    height="15px"
                    fontSize="10px"
                    borderRadius="100%"
                  >
                    {pendingCount}
                  </Box>
                </Box>
              }
              value={"pending"}
            />
            <Tab
              label={
                <Box display="flex" alignItems="center" gap={2}>
                  <span className={classes.statusStyles}>Resolvidos</span>
                </Box>
              }
              value={"closed"}
            />
          </Tabs>

          <Paper className={`${classes.ticketsWrapper}`}>
            <TicketsListGroup
              status="open"
              showAll={true}
              setHideMessages={setHideMessages}
              selectedQueueIds={selectedQueueIds}
              updateCount={(val) => setOpenCount(val)}
              setMobileUserOpen={setMobileUserOpen}
              style={applyPanelStyle("open")}
            />
            <TicketsListGroup
              status="pending"
              selectedQueueIds={selectedQueueIds}
              updateCount={(val) => setPendingCount(val)}
              style={applyPanelStyle("pending")}
            />
            <TicketsList
              status="closed"
              tab={tabOpen}
              showAll={true}
              contactType={contactType}
              selectedQueueIds={selectedQueueIds}
              style={{ ...applyPanelStyle("closed"), width: "500px !important", heigth: "500px !important", display: tabOpen === "closed" ? "flex" : "none !important" }}
            />
          </Paper>
        </TabPanel>

        <TabPanel value={tab} name="closed" className={`${classes.ticketsWrapper} ${classes.firstContainer}`}>
          <TicketsList
            status="closed"
            showAll={true}
            contactType={contactType}
            selectedQueueIds={selectedQueueIds}
          />
        </TabPanel>

        <TabPanel value={tab} name="search" className={`${classes.ticketsWrapper} ${classes.firstContainer}`}>
          <TicketsList
            searchParam={searchParam}
            searching={searchParam.length > 0}
            showAll={true}
            tab={tab}
            setMobileUserOpen={setMobileUserOpen}
            tags={selectedTags}
            contactType={contactType}
            users={selectedUsers}
            selectedStatus={selectedStatus}
            selectedQueueIds={selectedQueueIds}
          />
        </TabPanel>
        <Paper
          style={{
            background: theme.palette.primary.darkGrayNeutralColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative"
          }}
          elevation={0}>
          <div className={`cmp-tickets__filter-modal `}>
            <Snackbar
              open={snackbarOpen}
              onClose={handleSnackbarClose}
              message={i18n.t("tickets.inbox.closedAllTickets")}
              ContentProps={{
                className: `${classes.snackbar}`,
              }}
              style={{left: "50%", bottom: "60px", position: "absolute"}}
              action={
                <div style={{borderTop: `1px solid ${theme.palette.primary.neutralBorder}`}}>
                  <Button
                    className={classes.yesButton}
                    size="small"
                    onClick={CloseAllTicket}
                  >
                    {i18n.t("tickets.inbox.yes")}
                  </Button>
                  <Button
                    className={classes.noButton}
                    size="small"
                    onClick={handleSnackbarClose}
                  >
                    {i18n.t("tickets.inbox.no")}
                  </Button>
                </div>
              }
            />
            <Button
              onClick={handleSnackbarOpen}
              style={{
                minWidth: "90px", fontSize: "12px",
                "@media (max-width: 950px)": {
                  fontSize: "0.5rem",
                },
                color: "#FFFFFF",
                border: "none",
                fontFamily: "Poppins",
                fontWeight: 400,
                textTransform: "capitalize",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.3rem",
                width: "100%",
                height: "37px"
              }}
            >
              <span>
                Fechar todos os tickets
              </span>
              <DeleteOutline style={{ fontSize: "16px" }} />
            </Button>

          </div>
        </Paper>
      </Paper>
    </>
  );
};

export default TicketsManagerTabs;
